@charset "UTF-8";
@import url(loading.css);
/* 主色调 */
/* border 颜色 */
/* active background */
/*主色调 active  background */
/* 红色 */
/* 鲜红色 */
/* 橙色 */
.pop h3:before, .comment_list_box .list li:before, .comment_list_one .list li:not(:nth-last-of-type(1)):before, .comment_list_pro .list li:not(:nth-last-of-type(1)):before, .shopping_cart .list li:before, .sel_address:before, .addres_add_ico:before, .affrim_order .pro_info:before, .affrim_order .data_s:before, .affrim_order .payment:before, .personal_index .module .set_list li:not(:nth-last-of-type(1)):before, .my_hz_detail table tr:not(:nth-last-of-type(1)) td:before, .my_invitation table tr:not(:nth-last-of-type(1)) td:before, .xj_sz_detail table tr:not(:nth-last-of-type(1)) td:before, .xj_wallet_details table tr:not(:nth-last-of-type(1)) td:before, .day_task .task_list .list li:before, .day_task .new_user_task .list li:before, .my_invitation .share_banner .info:before, .sq_xj_tixian .zfb_accunt:before, .xj_tixian_jilu .list li:not(:nth-last-of-type(1)):before, .three_rural_index .news_list:before, .witness_record li:before, .community_index .sq_list .list:before, .sharing_makes .list li:not(:nth-last-of-type(1)):before, .chain_school_sucai .list li:not(:nth-last-of-type(1)):before, .chain_school .list li:not(:nth-last-of-type(1)):before, .fantuxiu .list li:not(:nth-last-of-type(1)):before, .fantuxiu_details .list li:not(:nth-last-of-type(1)):before, .fantuxiu_details .list:before, .fantuxiu_details .user_p_list li:not(:nth-last-of-type(1)):before, .account_set .rule li:before, .notice_news .notice li:before, .notice_news .news_user li:before, .tixian_accunt li:not(:nth-last-of-type(1)):before, .group_inviate .info li:not(:nth-last-of-type(1)):before, .quk_pop_list li:before, .my_hz_wallet .module:before, .my_food_coupon .module:before, .my_invitation .module:before, .my_food_coupon .tongjitu:before, .my_food_coupon .share_wx:before, .my_xj_wallet .list li:before {
  left: 0;
  -webkit-transform: scale(1, 0.5);
  transform: scale(1, 0.5);
  content: '';
  display: block;
  width: 100%;
  height: 1px;
  background: #eee;
  position: absolute;
  z-index: 20; }

/* 添加一个 减少一个 */
.guige .nub, .shopping_cart .list li .info .add_peice, .affrim_order .pro_info .info .add_peice {
  overflow: hidden;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -moz-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between; }
  .guige .nub .t, .shopping_cart .list li .info .add_peice .t, .affrim_order .pro_info .info .add_peice .t {
    font-size: 14px;
    color: #333; }
  .guige .nub .peice, .shopping_cart .list li .info .add_peice .peice, .affrim_order .pro_info .info .add_peice .peice {
    font-size: 14px;
    color: #333;
    color: #f23440; }
    .guige .nub .peice s, .shopping_cart .list li .info .add_peice .peice s, .affrim_order .pro_info .info .add_peice .peice s {
      font-size: 12px;
      color: #999; }
  .guige .nub .btn, .shopping_cart .list li .info .add_peice .btn, .affrim_order .pro_info .info .add_peice .btn {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border: 1px solid #eee;
    float: left;
    margin-top: 10px; }
    .guige .nub .btn button:active, .shopping_cart .list li .info .add_peice .btn button:active, .affrim_order .pro_info .info .add_peice .btn button:active {
      background: #f4f4f4; }
    .guige .nub .btn button, .shopping_cart .list li .info .add_peice .btn button, .affrim_order .pro_info .info .add_peice .btn button,
    .guige .nub .btn em,
    .shopping_cart .list li .info .add_peice .btn em,
    .affrim_order .pro_info .info .add_peice .btn em {
      height: 30px;
      width: 30px;
      line-height: 30px;
      text-align: center; }

/* 下边线 */
.pop h3:before, .comment_list_box .list li:before, .comment_list_one .list li:not(:nth-last-of-type(1)):before, .comment_list_pro .list li:not(:nth-last-of-type(1)):before, .shopping_cart .list li:before, .sel_address:before, .addres_add_ico:before, .affrim_order .pro_info:before, .affrim_order .data_s:before, .affrim_order .payment:before, .personal_index .module .set_list li:not(:nth-last-of-type(1)):before, .my_hz_detail table tr:not(:nth-last-of-type(1)) td:before, .my_invitation table tr:not(:nth-last-of-type(1)) td:before, .xj_sz_detail table tr:not(:nth-last-of-type(1)) td:before, .xj_wallet_details table tr:not(:nth-last-of-type(1)) td:before, .day_task .task_list .list li:before, .day_task .new_user_task .list li:before, .my_invitation .share_banner .info:before, .sq_xj_tixian .zfb_accunt:before, .xj_tixian_jilu .list li:not(:nth-last-of-type(1)):before, .three_rural_index .news_list:before, .witness_record li:before, .community_index .sq_list .list:before, .sharing_makes .list li:not(:nth-last-of-type(1)):before, .chain_school_sucai .list li:not(:nth-last-of-type(1)):before, .chain_school .list li:not(:nth-last-of-type(1)):before, .fantuxiu .list li:not(:nth-last-of-type(1)):before, .fantuxiu_details .list li:not(:nth-last-of-type(1)):before, .fantuxiu_details .list:before, .fantuxiu_details .user_p_list li:not(:nth-last-of-type(1)):before, .account_set .rule li:before, .notice_news .notice li:before, .notice_news .news_user li:before, .tixian_accunt li:not(:nth-last-of-type(1)):before, .group_inviate .info li:not(:nth-last-of-type(1)):before, .quk_pop_list li:before {
  bottom: 0; }

/* 上边线 */
.my_hz_wallet .module:before, .my_food_coupon .module:before, .my_invitation .module:before, .my_food_coupon .tongjitu:before, .my_food_coupon .share_wx:before, .my_xj_wallet .list li:before {
  top: 0; }

.top_scroll li.sel em:before, .store_index .content .false_sale .timers li.sel em:before, .store_index .content .trial_product .timers li.sel em:before {
  background: #087c7a;
  content: "";
  display: block;
  width: 100%;
  height: 6px;
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: 0;
  opacity: 0.7; }

.store_index .content .trial_product .oImg .time, .store_index .content .trial_product .trial_invite .info .ct .list li .user .font .time, .trial_invite .info .ct .list li .user .store_index .content .trial_product .font .time, .product_details .banner_s .kill_timer {
  position: absolute;
  bottom: 0;
  z-index: 1;
  left: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.2);
  color: #fff;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -moz-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 5px 10px; }
  .store_index .content .trial_product .oImg .time .sy, .store_index .content .trial_product .trial_invite .info .ct .list li .user .font .time .sy, .trial_invite .info .ct .list li .user .store_index .content .trial_product .font .time .sy, .product_details .banner_s .kill_timer .sy {
    background: #fff;
    padding: 2px 10px;
    border-radius: 50px;
    font-size: 11px;
    color: #333; }
    .store_index .content .trial_product .oImg .time .sy span, .store_index .content .trial_product .trial_invite .info .ct .list li .user .font .time .sy span, .trial_invite .info .ct .list li .user .store_index .content .trial_product .font .time .sy span, .product_details .banner_s .kill_timer .sy span {
      color: #f23440;
      font-size: 14px;
      font-weight: 700; }
  .store_index .content .trial_product .oImg .time .rule, .store_index .content .trial_product .trial_invite .info .ct .list li .user .font .time .rule, .trial_invite .info .ct .list li .user .store_index .content .trial_product .font .time .rule, .product_details .banner_s .kill_timer .rule {
    background: #fff;
    color: #2bbf9d;
    padding: 2px 10px;
    border-radius: 50px;
    font-size: 12px; }
    .store_index .content .trial_product .oImg .time .rule:active, .store_index .content .trial_product .trial_invite .info .ct .list li .user .font .time .rule:active, .trial_invite .info .ct .list li .user .store_index .content .trial_product .font .time .rule:active, .product_details .banner_s .kill_timer .rule:active {
      background: #f4f4f4; }
    .store_index .content .trial_product .oImg .time .rule em, .store_index .content .trial_product .trial_invite .info .ct .list li .user .font .time .rule em, .trial_invite .info .ct .list li .user .store_index .content .trial_product .font .time .rule em, .product_details .banner_s .kill_timer .rule em {
      font-size: 14px;
      margin-right: 2px; }
  .store_index .content .trial_product .oImg .time .num, .store_index .content .trial_product .trial_invite .info .ct .list li .user .font .time .num, .trial_invite .info .ct .list li .user .store_index .content .trial_product .font .time .num, .product_details .banner_s .kill_timer .num {
    background: #fff;
    color: #666;
    padding: 2px 10px;
    border-radius: 50px;
    font-size: 12px; }
    .store_index .content .trial_product .oImg .time .num span, .store_index .content .trial_product .trial_invite .info .ct .list li .user .font .time .num span, .trial_invite .info .ct .list li .user .store_index .content .trial_product .font .time .num span, .product_details .banner_s .kill_timer .num span {
      color: #f23440; }
  .store_index .content .trial_product .oImg .time .day_time em, .store_index .content .trial_product .trial_invite .info .ct .list li .user .font .time .day_time em, .trial_invite .info .ct .list li .user .store_index .content .trial_product .font .time .day_time em, .product_details .banner_s .kill_timer .day_time em,
  .store_index .content .trial_product .oImg .time .day_time span,
  .store_index .content .trial_product .trial_invite .info .ct .list li .user .font .time .day_time span,
  .trial_invite .info .ct .list li .user .store_index .content .trial_product .font .time .day_time span,
  .product_details .banner_s .kill_timer .day_time span {
    display: inline-block;
    vertical-align: middle; }
  
  .store_index .content .trial_product .oImg .time .day_time span,
  .store_index .content .trial_product .trial_invite .info .ct .list li .user .font .time .day_time span,
  .trial_invite .info .ct .list li .user .store_index .content .trial_product .font .time .day_time span,
  .product_details .banner_s .kill_timer .day_time span {
    background: rgba(0, 0, 0, 0.5);
    padding: 3px;
    border-radius: 2px;
    font-size: 13px; }

.pop {
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 80vw;
  background: #fff;
  border-radius: 4px;
  z-index: 99;
  overflow: hidden; }
  .pop h3 {
    height: 40px;
    line-height: 40px;
    padding: 0 12px;
    position: relative;
    font-size: 14px; }
    .pop h3 button {
      position: absolute;
      top: 0;
      right: 0;
      width: 40px;
      height: 40px;
      z-index: 1;
      color: #999; }
      .pop h3 button:active {
        background: #f4f4f4; }
  .pop .ct {
    padding: 18px  12px;
    font-size: 14px;
    text-align: justify;
    color: #666; }
    .pop .ct .code {
      padding: 15px; }
  .pop .btn {
    position: relative;
    overflow: hidden; }
    .pop .btn button {
      width: 50%;
      height: 40px;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      color: #666; }
    .pop .btn .cancel {
      background: #f4f4f4;
      float: left; }
      .pop .btn .cancel:active {
        background: #eee; }
    .pop .btn .sure {
      background: #2bbf9d;
      float: right;
      color: #fff; }
      .pop .btn .sure:active {
        background: #27af90; }
  .pop .btn_k {
    padding: 0 12px 12px 12px; }
    .pop .btn_k button {
      height: 40px;
      border-radius: 50px;
      background: #2bbf9d;
      color: #fff;
      width: 100%; }
      .pop .btn_k button:active {
        background: #27af90; }

header {
  position: fixed;
  top: 0;
  height: 44px;
  width: 100%;
  left: 0;
  background: #fff;
  line-height: 44px;
  z-index: 88; }
  header .back, header .r_btn, header .hz_search {
    z-index: 1;
    color: #666;
    font-size: 20px;
    border-radius: 2px;
    position: absolute; }
    header .back:active, header .r_btn:active, header .hz_search:active {
      background: #f4f4f4; }
  header .back {
    left: 0;
    width: 44px;
    height: 44px;
    top: 0; }
  header .r_btn, header .hz_search {
    right: 12px;
    font-size: 13px;
    padding: 3px 5px;
    top: 50%;
    -webkit-transform: translate(0, -50%);
    transform: translate(0, -50%); }
  header .hz_search {
    font-size: 22px; }
  header p {
    padding-left: 50px;
    font-size: 16px;
    color: #666;
    font-weight: 700; }
  header .fb {
    position: absolute;
    top: 50%;
    right: 12px;
    -webkit-transform: translate(0, -50%);
    transform: translate(0, -50%);
    z-index: 1;
    background: #eaf9f6;
    height: 30px;
    padding: 0 15px;
    border: 1px solid #2bbf9d;
    color: #2bbf9d;
    border-radius: 2px; }
    header .fb:active {
      background: #f4f4f4; }
  header .sp_btn {
    position: absolute;
    top: 50%;
    right: 12px;
    -webkit-transform: translate(0, -50%);
    transform: translate(0, -50%);
    z-index: 1;
    padding: 5px 10px;
    font-size: 20px;
    color: #999;
    border-radius: 2px; }
    header .sp_btn:active {
      background: #f4f4f4; }

.navigation, .navigation_wx {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 88;
  border-top: 1px solid #eee;
  background: #fff;
  font-size: 13px; }
  .navigation li, .navigation_wx li {
    color: #666;
    width: 20%;
    height: 50px;
    float: left;
    text-align: center; }
    .navigation li .font, .navigation_wx li .font {
      font-size: 20px;
      margin-top: 5px; }
    .navigation li.sel, .navigation_wx li.sel {
      color: #2bbf9d; }

.navigation_wx li {
  width: 25%; }

.top_scroll {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 88;
  background: #fff;
  height: 44px;
  width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch; }
  .top_scroll li {
    display: inline-block;
    font-size: 15px;
    height: 36px;
    line-height: 36px;
    padding: 0 10px;
    border-radius: 2px;
    color: #666;
    margin-top: 4px; }
    .top_scroll li:active {
      background: #f4f4f4; }
    .top_scroll li.sel em {
      position: relative;
      font-size: 18px;
      color: #333; }

.reading_share_index .search_box {
  overflow: hidden;
  padding: 5px 12px;
  position: relative; }
  .reading_share_index .search_box input, .reading_share_index .search_box button {
    height: 36px;
    border-radius: 4px; }
  .reading_share_index .search_box input {
    background: #f4f4f4;
    float: left;
    width: calc(100% - 90px);
    text-indent: 30px; }
  .reading_share_index .search_box button {
    width: 80px;
    float: right;
    background: #2bbf9d;
    color: #fff; }
    .reading_share_index .search_box button:active {
      background: #27af90; }
  .reading_share_index .search_box .ico {
    position: absolute;
    top: 50%;
    left: 20px;
    -webkit-transform: translate(0, -50%);
    transform: translate(0, -50%);
    z-index: 1;
    color: #999; }

.reading_share_index .list_article {
  padding: 12px; }
  .reading_share_index .list_article li {
    border-radius: 5px;
    overflow: hidden;
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    margin-bottom: 20px; }
    .reading_share_index .list_article li:nth-last-of-type(1) {
      margin-bottom: 0; }
    .reading_share_index .list_article li:active {
      -webkit-box-shadow: 0 0 0px rgba(0, 0, 0, 0.2);
      box-shadow: 0 0 0px rgba(0, 0, 0, 0.2); }
    .reading_share_index .list_article li .info {
      padding: 10px;
      letter-spacing: 0.8px; }
      .reading_share_index .list_article li .info .name {
        font-size: 14px;
        color: #000;
        font-weight: 700;
        -webkit-line-clamp: 2;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical; }
      .reading_share_index .list_article li .info .time {
        margin: 3px 0;
        color: #999;
        font-size: 12px; }
      .reading_share_index .list_article li .info .brief {
        color: #666;
        font-size: 13px;
        -webkit-line-clamp: 1;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical; }

.read_details {
  padding: 12px; }
  .read_details .name {
    font-size: 16px;
    font-weight: 700; }
  .read_details .timer {
    color: #999;
    margin: 10px 0; }
  .read_details .content p {
    margin: 10px 0;
    line-height: 26px;
    font-size: 15px;
    letter-spacing: 0.5px;
    text-align: justify;
    color: #666; }
  .read_details .read_num_praise {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -moz-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 15px 0;
    color: #999; }
    .read_details .read_num_praise .praise {
      padding: 5px 5px;
      border-radius: 2px; }
      .read_details .read_num_praise .praise.sel {
        color: #2bbf9d; }
      .read_details .read_num_praise .praise:active {
        background: #f4f4f4; }
      .read_details .read_num_praise .praise .font {
        font-size: 20px; }
  .read_details .ad {
    border-bottom: 1px solid #eee;
    border-top: 1px solid #eee;
    padding-bottom: 15px; }
    .read_details .ad .name {
      padding: 10px 0;
      color: #333; }
    .read_details .ad ._ico {
      margin-top: 5px; }
      .read_details .ad ._ico em {
        border: 1px solid #eee;
        color: #999;
        font-size: 12px;
        display: inline-block; }
  .read_details .comment_btn_box, .read_details .comment_put_box {
    width: 100%;
    background: #fff;
    font-size: 0;
    text-align: center;
    position: fixed;
    bottom: 0;
    left: 0;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box; }
  .read_details .comment_btn_box {
    z-index: 88; }
    .read_details .comment_btn_box button {
      font-size: 14px;
      height: 50px; }
      .read_details .comment_btn_box button .font {
        vertical-align: middle; }
      .read_details .comment_btn_box button.write {
        width: 40%; }
        .read_details .comment_btn_box button.write:active {
          background: #f4f4f4; }
        .read_details .comment_btn_box button.write .font {
          color: #2bbf9d; }
      .read_details .comment_btn_box button.share {
        width: 60%;
        color: #fff;
        background: #2bbf9d; }
        .read_details .comment_btn_box button.share:active {
          background: #27af90; }
  .read_details .comment_put_box {
    z-index: 89;
    height: 50px;
    padding: 7px 12px;
    display: none; }
    .read_details .comment_put_box input,
    .read_details .comment_put_box button {
      float: left;
      border-radius: 4px;
      height: 36px; }
    .read_details .comment_put_box input {
      background: #f4f4f4;
      width: calc(100% - 90px);
      margin-right: 10px;
      text-indent: 5px; }
    .read_details .comment_put_box button {
      width: 80px;
      background: #2bbf9d;
      color: #fff; }
      .read_details .comment_put_box button:active {
        background: #27af90; }

.comment_list_box h3 {
  margin: 15px 0 0; }

.comment_list_box .list li {
  overflow: hidden;
  padding: 20px 0;
  position: relative; }
  .comment_list_box .list li .user_head {
    width: 40px;
    height: 40px;
    float: left;
    border-radius: 50%;
    overflow: hidden; }
  .comment_list_box .list li .info {
    float: right;
    width: calc(100% - 50px); }
    .comment_list_box .list li .info .nick_name {
      font-size: 12px;
      color: #999;
      margin-bottom: 5px; }
    .comment_list_box .list li .info .brief {
      color: #666; }

.comment_list_box .more_comment_btn_box {
  text-align: center;
  padding-top: 5px; }
  .comment_list_box .more_comment_btn_box button {
    font-size: 14px;
    color: #999;
    padding: 10px 0;
    width: 100%;
    border-radius: 4px; }
    .comment_list_box .more_comment_btn_box button:active {
      background: #f4f4f4; }

.store_index {
  overflow: hidden; }
  .store_index .header {
    height: 50px;
    position: fixed;
    top: 0;
    width: 100%;
    left: 0;
    z-index: 88;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding: 7px 12px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    background: #fff; }
    .store_index .header h3, .store_index .header .search, .store_index .header .buy_car {
      line-height: 36px; }
    .store_index .header h3 {
      font-size: 18px;
      font-weight: 700; }
    .store_index .header .search {
      height: 36px;
      -webkit-box-flex: 1;
      -moz-box-flex: 1;
      -ms-flex: 1;
      flex: 1;
      background: #f4f4f4;
      border-radius: 4px;
      margin: 0 10px;
      position: relative; }
      .store_index .header .search input {
        width: 100%;
        height: 100%;
        text-indent: 32px;
        padding-top: 1px; }
      .store_index .header .search em {
        color: #999;
        margin: 0 5px;
        position: absolute;
        top: 50%;
        left: 5px;
        -webkit-transform: translate(0, -50%);
        transform: translate(0, -50%);
        z-index: 1; }
    .store_index .header .buy_car {
      width: 34px;
      height: 34px;
      background: -webkit-gradient(linear, left top, left bottom, from(#2ebfa0), to(#28bcc8));
      background: linear-gradient(#2ebfa0, #28bcc8);
      border-radius: 50%;
      position: relative;
      color: #fff;
      font-size: 18px; }
      .store_index .header .buy_car:active {
        background: #27af90; }
      .store_index .header .buy_car em {
        position: absolute;
        top: -5px;
        right: -8px;
        z-index: 1;
        font-size: 10px;
        display: block;
        background: #e5383e;
        height: 14px;
        line-height: 14px;
        padding: 0 5px;
        border-radius: 10px; }
  .store_index .category_icons {
    overflow: hidden;
    padding: 0 12px; }
    .store_index .category_icons li {
      width: 20%;
      float: left;
      text-align: center;
      font-size: 12px;
      color: #666;
      padding: 5px 0;
      border-radius: 2px; }
      .store_index .category_icons li:active {
        background: #f4f4f4; }
      .store_index .category_icons li p {
        height: 20px;
        line-height: 20px; }
      .store_index .category_icons li .ico {
        width: 8vw;
        margin: 0 auto 5px;
        height: 8vw; }
  .store_index .content {
    padding: 0 12px; }
    .store_index .content > *:after {
      content: "";
      display: block;
      clear: both; }
    .store_index .content .show_ad_3, .store_index .content .false_sale, .store_index .content .trial_product, .store_index .content .auction {
      margin-bottom: 15px; }
    .store_index .content .show_ad_3 {
      overflow: hidden;
      padding: 12px 0 0; }
      .store_index .content .show_ad_3 .item_01,
      .store_index .content .show_ad_3 .item_02 {
        width: calc(50% - 5px);
        height: 52vw;
        overflow: hidden; }
      .store_index .content .show_ad_3 .item_01 {
        float: left;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box; }
        .store_index .content .show_ad_3 .item_01:active {
          border: 1px solid #2bbf9d; }
      .store_index .content .show_ad_3 .item_02 {
        float: right; }
        .store_index .content .show_ad_3 .item_02 dt {
          height: calc(26vw - 5px);
          margin-bottom: 10px;
          -webkit-box-sizing: border-box;
          -moz-box-sizing: border-box;
          box-sizing: border-box;
          overflow: hidden; }
          .store_index .content .show_ad_3 .item_02 dt:active {
            border: 1px solid #2bbf9d; }
    .store_index .content h2.tit {
      height: 44px;
      line-height: 44px;
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -moz-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: justify;
      -moz-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      font-weight: 700;
      font-size: 16px; }
      .store_index .content h2.tit button {
        font-size: 13px;
        color: #999;
        padding: 5px; }
        .store_index .content h2.tit button:active {
          background: #f4f4f4;
          border-radius: 2px; }
    .store_index .content .false_sale .timers, .store_index .content .trial_product .timers {
      height: 40px;
      line-height: 40px;
      overflow-x: scroll;
      overflow-y: hidden;
      white-space: nowrap;
      display: -webkit-box;
      -webkit-overflow-scrolling: touch;
      margin-bottom: 5px; }
      .store_index .content .false_sale .timers li, .store_index .content .trial_product .timers li {
        display: inline-block;
        vertical-align: middle;
        padding: 0 10px;
        font-size: 15px;
        color: #666;
        border-radius: 2px; }
        .store_index .content .false_sale .timers li:active, .store_index .content .trial_product .timers li:active {
          background: #f4f4f4; }
        .store_index .content .false_sale .timers li.sel em, .store_index .content .trial_product .timers li.sel em {
          position: relative;
          color: #333; }
    .store_index .content .false_sale .product, .store_index .content .trial_product .product {
      -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
      border-radius: 4px;
      overflow: hidden; }
      .store_index .content .false_sale .product .info, .store_index .content .trial_product .product .info {
        padding: 10px; }
        .store_index .content .false_sale .product .info .name b, .store_index .content .trial_product .product .info .name b {
          font-weight: 700; }
        .store_index .content .false_sale .product .info .name em, .store_index .content .trial_product .product .info .name em {
          background: #2bbf9d;
          color: #fff;
          padding: 3px 5px;
          border-radius: 2px;
          font-size: 12px; }
        .store_index .content .false_sale .product .info .price, .store_index .content .trial_product .product .info .price {
          display: -webkit-box;
          display: -moz-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -moz-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          -webkit-box-pack: justify;
          -moz-box-pack: justify;
          -ms-flex-pack: justify;
          justify-content: space-between;
          margin-top: 10px; }
          .store_index .content .false_sale .product .info .price .l b, .store_index .content .trial_product .product .info .price .l b {
            font-size: 16px;
            color: #666;
            font-weight: 700; }
          .store_index .content .false_sale .product .info .price .l .font, .store_index .content .trial_product .product .info .price .l .font {
            color: #2bbf9d; }
            .store_index .content .false_sale .product .info .price .l .font span, .store_index .content .trial_product .product .info .price .l .font span {
              font-weight: 700; }
          .store_index .content .false_sale .product .info .price .l s, .store_index .content .trial_product .product .info .price .l s {
            font-size: 12px;
            color: #999; }
          .store_index .content .false_sale .product .info .price .r button, .store_index .content .trial_product .product .info .price .r button {
            background: #666;
            color: #fff;
            height: 30px;
            padding: 0 10px;
            border-radius: 4px;
            font-size: 12px; }
            .store_index .content .false_sale .product .info .price .r button:active, .store_index .content .trial_product .product .info .price .r button:active {
              opacity: 0.8; }
          .store_index .content .false_sale .product .info .price .r .red, .store_index .content .trial_product .product .info .price .r .red {
            background: #f23440; }
          .store_index .content .false_sale .product .info .price .r .sel, .store_index .content .trial_product .product .info .price .r .sel {
            background: #2bbf9d; }
    .store_index .content .auction .list li {
      width: calc(50% - 5px);
      float: left;
      -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
      border-radius: 4px; }
      .store_index .content .auction .list li .oImg, .store_index .content .auction .trial_invite .info .ct .list li .user .font, .trial_invite .info .ct .store_index .content .auction .list li .user .font {
        padding: 10px;
        padding-bottom: 0;
        position: relative; }
        .store_index .content .auction .list li .oImg .icon, .store_index .content .auction .trial_invite .info .ct .list li .user .font .icon, .trial_invite .info .ct .store_index .content .auction .list li .user .font .icon {
          position: absolute;
          top: 10px;
          right: 10px;
          z-index: 1;
          width: 60px; }
      .store_index .content .auction .list li .info {
        padding: 10px; }
        .store_index .content .auction .list li .info .name {
          font-size: 14px;
          font-weight: 700;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap; }
        .store_index .content .auction .list li .info .price {
          color: #666;
          font-size: 12px;
          margin-top: 2px; }
          .store_index .content .auction .list li .info .price .font {
            color: #2bbf9d;
            font-size: 13px; }
    .store_index .content .auction .list li:nth-of-type(1) {
      margin-right: 10px; }
    .store_index .content .trial_product .oImg, .store_index .content .trial_product .trial_invite .info .ct .list li .user .font, .trial_invite .info .ct .list li .user .store_index .content .trial_product .font {
      position: relative; }
    .brand_zone .tit_banner, .product_category .tit_banner {
      margin-bottom: 10px; }
    .brand_zone .list, .product_category .list {
      overflow: hidden; }
      .brand_zone .list li, .product_category .list li {
        width: calc(50% - 5px);
        float: left;
        margin-bottom: 20px; }
        .brand_zone .list li .oImg, .product_category .list li .oImg, .brand_zone .trial_invite .info .ct .list li .user .font, .trial_invite .info .ct .brand_zone .list li .user .font, .product_category .trial_invite .info .ct .list li .user .font, .trial_invite .info .ct .product_category .list li .user .font {
          height: calc(50vw - 17px);
          position: relative;
          -webkit-box-sizing: border-box;
          -moz-box-sizing: border-box;
          box-sizing: border-box;
          padding: 3px; }
          .brand_zone .list li .oImg:active:before, .product_category .list li .oImg:active:before, .brand_zone .trial_invite .info .ct .list li .user .font:active:before, .trial_invite .info .ct .brand_zone .list li .user .font:active:before, .product_category .trial_invite .info .ct .list li .user .font:active:before, .trial_invite .info .ct .product_category .list li .user .font:active:before {
            border: 1px solid #2bbf9d; }
          .brand_zone .list li .oImg:before, .product_category .list li .oImg:before, .brand_zone .trial_invite .info .ct .list li .user .font:before, .trial_invite .info .ct .brand_zone .list li .user .font:before, .product_category .trial_invite .info .ct .list li .user .font:before, .trial_invite .info .ct .product_category .list li .user .font:before {
            -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
            content: "";
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;
            border: 1px solid #eee;
            position: absolute;
            top: 0;
            left: 0;
            width: 200%;
            height: 200%;
            -webkit-transform: scale(0.5, 0.5);
            transform: scale(0.5, 0.5);
            z-index: 1; }
        .brand_zone .list li:nth-of-type(2n), .product_category .list li:nth-of-type(2n) {
          float: right; }
        .brand_zone .list li .tag, .product_category .list li .tag {
          height: 20px;
          margin-top: 2px; }
          .brand_zone .list li .tag em, .product_category .list li .tag em {
            background: #2bbf9d;
            color: #fff;
            font-size: 12px;
            padding: 2px 5px;
            border-radius: 2px; }
        .brand_zone .list li .name, .product_category .list li .name {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          font-size: 14px;
          font-weight: 700;
          margin-top: 8px; }
        .brand_zone .list li .price em, .product_category .list li .price em {
          color: #f23440;
          font-size: 13px;
          font-weight: 700; }
        .brand_zone .list li .price s, .product_category .list li .price s {
          color: #999;
          font-size: 12px;
          margin-left: 4px; }
    .product_category {
      padding: 0 12px;
      overflow: hidden; }

.swiper_banner .swiper-pagination-bullet, .product_details .banner_s .swiper-pagination-bullet {
  width: 14px;
  height: 4px;
  border-radius: 0;
  margin: 0 2px !important; }

.swiper_banner .swiper-pagination-bullet-active, .product_details .banner_s .swiper-pagination-bullet-active {
  background: #2bbf9d; }

.swiper_banner {
  padding: 5px 0; }
  .swiper_banner .swiper-slide {
    padding: 0 12px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box; }
    .swiper_banner .swiper-slide p {
      border-radius: 4px;
      overflow: hidden; }

.product_details .banner_s {
  position: relative; }
  .des_head_btns {
    position: fixed;
    z-index: 77;
    top: 10px;
    height: 34px;
    padding: 0 12px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    width: 100%; }
    .des_head_btns .back_btn, .des_head_btns .share_btn {
      width: 34px;
      height: 34px;
      background: rgba(0, 0, 0, 0.4);
      color: #fff;
      border-radius: 50%;
      font-size: 18px; }
      .des_head_btns .back_btn:active, .des_head_btns .share_btn:active {
        background: rgba(0, 0, 0, 0.5); }
    .des_head_btns .back_btn {
      float: left; }
    .des_head_btns .share_btn {
      float: right; }

.product_details .info_data {
  padding: 10px 12px 0;
  overflow: hidden; }
  .product_details .info_data .group_show {
    overflow: hidden;
    margin: 10px 0; }
  .product_details .info_data .name_tit {
    font-weight: 700; }
    .product_details .info_data .name_tit em {
      background: #2bbf9d;
      color: #fff;
      font-size: 14px;
      vertical-align: 2px;
      margin-right: 4px;
      padding: 2px 5px;
      border-radius: 2px; }
  .product_details .info_data .price {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -moz-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-bottom: 15px; }
    .product_details .info_data .price .l {
      color: #999; }
      .product_details .info_data .price .l b {
        padding: 0 4px; }
      .product_details .info_data .price .l .pri {
        color: #f23440;
        font-size: 16px;
        font-weight: 700; }
      .product_details .info_data .price .l .font {
        color: #2bbf9d; }
      .product_details .info_data .price .l s {
        font-size: 12px; }
    .product_details .info_data .price .r em, .product_details .info_data .price .r span {
      color: #fff;
      padding: 3px 5px;
      border-radius: 2px;
      font-size: 12px; }
    .product_details .info_data .price .r em {
      background: #2bbf9d; }
    .product_details .info_data .price .r span {
      background: #f23440; }
  .product_details .info_data .gather_ptx {
    overflow: hidden;
    padding: 0 0 10px;
    color: #666; }
    .product_details .info_data .gather_ptx em {
      padding: 2px 5px;
      border-radius: 2px;
      color: #fff;
      background: #2bbf9d;
      font-size: 12px;
      margin-right: 2px; }
  .product_details .info_data .kill_info {
    padding-bottom: 10px;
    overflow: hidden;
    font-size: 13px;
    margin-top: 3px; }
    .product_details .info_data .kill_info .font {
      font-size: 14px; }
    .product_details .info_data .kill_info p {
      color: #666;
      line-height: 16px;
      letter-spacing: -0.5px; }
    .product_details .info_data .kill_info .kill_price {
      margin: 5px 0;
      font-size: 16px;
      color: #333;
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -moz-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: justify;
      -moz-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between; }
      .product_details .info_data .kill_info .kill_price .font {
        color: #2bbf9d; }
        .product_details .info_data .kill_info .kill_price .font span {
          font-weight: 700; }
    .product_details .info_data .kill_info .jp_pirce {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -moz-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: justify;
      -moz-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      font-size: 13px;
      margin-top: 8px; }
      .product_details .info_data .kill_info .jp_pirce .l span {
        color: #2bbf9d; }
        .product_details .info_data .kill_info .jp_pirce .l span b {
          font-weight: 700; }
      .product_details .info_data .kill_info .jp_pirce em span {
        color: #f23440; }
    .product_details .info_data .kill_info .sipai .font {
      color: #2bbf9d; }
    .product_details .info_data .kill_info .jc_price_d {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -moz-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: justify;
      -moz-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between; }
    .product_details .info_data .kill_info .surplus {
      margin: 10px 0; }
      .product_details .info_data .kill_info .surplus span {
        font-size: 16px;
        color: #f23440;
        font-weight: 700; }
    .product_details .info_data .kill_info .jicai_price {
      margin: 10px 0;
      font-size: 12px; }
      .product_details .info_data .kill_info .jicai_price span {
        font-size: 12px;
        color: #666; }
    .product_details .info_data .kill_info .rule {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -moz-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: justify;
      -moz-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between; }
      .product_details .info_data .kill_info .rule span {
        font-size: 16px;
        color: #f23440; }
      .product_details .info_data .kill_info .rule .font {
        font-size: 12px;
        padding: 2px 2px;
        color: #2bbf9d; }
        .product_details .info_data .kill_info .rule .font:active {
          background: #f4f4f4;
          border-radius: 2px; }
  .product_details .info_data .rule_trial {
    background: #f9f9f9;
    padding: 10px;
    border-radius: 4px;
    margin-bottom: 15px; }
    .product_details .info_data .rule_trial .rule {
      text-align: right; }
      .product_details .info_data .rule_trial .rule button {
        color: #2bbf9d;
        font-size: 13px;
        padding: 5px 5px;
        border-radius: 2px; }
        .product_details .info_data .rule_trial .rule button:active {
          background: #f4f4f4; }

.product_details .name_tit_more {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -moz-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  font-size: 17px; }
  .product_details .name_tit_more em {
    font-weight: 700; }
  .product_details .name_tit_more b {
    font-size: 14px;
    color: #999;
    font-size: 12px; }
    .product_details .name_tit_more b .n {
      color: #2bbf9d;
      font-size: 14px; }
    .product_details .name_tit_more b .m {
      color: #f23440;
      font-size: 13px; }
    .product_details .name_tit_more b .font {
      font-size: 12px;
      padding: 2px 5px 2px 2px;
      border-radius: 2px; }
      .product_details .name_tit_more b .font:active {
        background: #f4f4f4; }

.product_details .pop_put, .product_details .gather_put {
  position: fixed;
  z-index: 88;
  width: 100%;
  height: 50px;
  background: #fff;
  bottom: 0;
  left: 0; }
  .product_details .pop_put .l, .product_details .gather_put .l {
    width: 50%;
    float: left;
    font-size: 0; }
    .product_details .pop_put .l button, .product_details .gather_put .l button {
      height: 50px;
      width: 33.3333333%;
      color: #666;
      position: relative; }
      .product_details .pop_put .l button:active, .product_details .gather_put .l button:active {
        background: #f4f4f4; }
      .product_details .pop_put .l button.sel, .product_details .gather_put .l button.sel {
        color: #2bbf9d; }
      .product_details .pop_put .l button em, .product_details .gather_put .l button em {
        position: absolute;
        top: 2px;
        right: 5px;
        background: #f23440;
        color: #fff;
        padding: 2px;
        border-radius: 50%;
        font-size: 11px;
        width: 14px;
        height: 14px;
        z-index: 1; }
      .product_details .pop_put .l button p, .product_details .gather_put .l button p {
        font-size: 12px; }
      .product_details .pop_put .l button .font, .product_details .gather_put .l button .font {
        font-size: 18px; }
  .product_details .pop_put .r, .product_details .gather_put .r {
    width: 50%;
    float: right;
    font-size: 0; }
    .product_details .pop_put .r button, .product_details .gather_put .r button {
      height: 50px;
      width: 50%;
      font-size: 13px;
      color: #fff; }
    .product_details .pop_put .r .buy_cart, .product_details .gather_put .r .buy_cart {
      background: #333; }
      .product_details .pop_put .r .buy_cart:active, .product_details .gather_put .r .buy_cart:active {
        background: #505050; }
    .product_details .pop_put .r .yao, .product_details .gather_put .r .yao {
      background: #f23440; }
      .product_details .pop_put .r .yao:active, .product_details .gather_put .r .yao:active {
        background: #dc313b; }
    .product_details .pop_put .r .go_buy, .product_details .gather_put .r .go_buy {
      background: #2bbf9d; }
      .product_details .pop_put .r .go_buy:active, .product_details .gather_put .r .go_buy:active {
        background: #27af90; }

.product_details .gather_put .l {
  width: 40%; }
  .product_details .gather_put .l button {
    width: 50%; }

.product_details .gather_put .r {
  width: 60%; }

.product_details .content_details.pt {
  border-top: 1px solid #eee; }

.product_details .content_details .name_tit_more {
  padding: 10px 12px 0; }

.product_details .content_details .content {
  margin-top: 10px; }

.product_details .content_details .auction_price {
  margin: 20px 0;
  width: 100%;
  text-align: center; }
  .product_details .content_details .auction_price tbody td:not(:nth-of-type(1)) {
    padding: 8px 0;
    width: 28%;
    color: #666;
    font-size: 12px; }
  .product_details .content_details .auction_price tbody td:nth-of-type(4) {
    color: #2bbf9d;
    font-size: 14px;
    font-weight: 700; }
  .product_details .content_details .auction_price thead td {
    font-size: 14px;
    color: #333;
    font-weight: 700; }

.product_details .auc_btn_p, .product_details .kill_btn_p, .product_details .trial_btn_p {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 77;
  width: 100%; }
  .product_details .auc_btn_p button, .product_details .kill_btn_p button, .product_details .trial_btn_p button {
    height: 50px;
    width: 100%;
    color: #fff;
    font-size: 16px;
    background: #666; }
    .product_details .auc_btn_p button:active, .product_details .kill_btn_p button:active, .product_details .trial_btn_p button:active {
      background: #27af90; }

.product_details .auc_btn_p .sel, .product_details .kill_btn_p .sel {
  background: #2bbf9d; }

.product_details .auc_btn_p .success, .product_details .kill_btn_p .success {
  background: #f23440; }

.product_details .trial_btn_p button {
  width: 50%;
  float: left; }

.product_details .trial_btn_p .l {
  background: #2bbf9d; }

.product_details .trial_btn_p .r {
  background: #f23440; }
  .product_details .trial_btn_p .r:active {
    background: #de303b; }

.product_details .jicai_pop_btn {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 66;
  width: 100%;
  overflow: hidden; }
  .product_details .jicai_pop_btn button {
    width: 50%;
    float: left;
    height: 50px;
    color: #fff;
    background: #666;
    font-size: 16px; }
  .product_details .jicai_pop_btn .suppot {
    background: #f23440; }
    .product_details .jicai_pop_btn .suppot:active {
      background: #d8303a; }
  .product_details .jicai_pop_btn .sel {
    background: #2bbf9d; }
    .product_details .jicai_pop_btn .sel:active {
      background: #27af90; }
  .product_details .jicai_pop_btn .wa {
    width: 100%; }

.pro_comment_list.pt {
  padding-top: 10px;
  border-top: 1px solid #eee; }

.suyuan_record.pt {
  padding-top: 10px; }

.suyuan_record.pt2 {
  border-top: 1px solid #eee; }

.suyuan_record .list {
  position: relative;
  overflow: hidden; }
  .suyuan_record .list li {
    padding: 10px 12px 10px 20px;
    background: #fff;
    margin-bottom: 12px; }
    .suyuan_record .list li video {
      height: 100%; }
    .suyuan_record .list li .time {
      font-size: 12px;
      position: relative;
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: justify;
      -moz-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      -webkit-box-align: center;
      -moz-box-align: center;
      -ms-flex-align: center;
      align-items: center; }
      .suyuan_record .list li .time button {
        background: #2bbf9d;
        color: #fff;
        padding: 3px 6px;
        border-radius: 50px;
        font-size: 14px; }
    .suyuan_record .list li .name {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -moz-box-align: center;
      -ms-flex-align: center;
      align-items: center; }
      .suyuan_record .list li .name em {
        padding: 4px 12px;
        border-radius: 15px;
        background: #f2f2f2;
        color: #666;
        margin-right: 10px;
        font-size: 12px;
        white-space: nowrap; }
      .suyuan_record .list li .name em.sel {
        background: #2bbf9d;
        color: #fff; }
      .suyuan_record .list li .name span {
        font-weight: 700;
        font-size: 14px; }
    .suyuan_record .list li .time:after {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      -webkit-transform: translate(0, -50%);
      transform: translate(0, -50%);
      left: -13px;
      z-index: 4;
      background: #2bbf9d; }
    .suyuan_record .list li .imgs {
      overflow-x: scroll;
      overflow-y: hidden;
      white-space: nowrap;
      -webkit-oveflow-scrolling: touch;
      margin-top: 5px; }
      .suyuan_record .list li .imgs dt {
        height: 150px;
        display: inline-block;
        margin-right: 5px;
        overflow: hidden;
        border-radius: 4px;
        position: relative; }
        .suyuan_record .list li .imgs dt button {
          position: absolute;
          top: 50%;
          left: 50%;
          -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
          z-index: 44; }
        .suyuan_record .list li .imgs dt .video {
          color: #fff;
          font-size: 44px;
          width: 50px;
          height: 50px; }
        .suyuan_record .list li .imgs dt .zhibo {
          background: #2bbf9d;
          color: #fff;
          padding: 5px 10px;
          border-radius: 50px; }
        .suyuan_record .list li .imgs dt img {
          width: auto;
          height: 100%; }
    .suyuan_record .list li .icons {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -moz-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      width: 100%;
      -webkit-box-pack: justify;
      -moz-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      color: #666;
      margin-top: 10px; }
      .suyuan_record .list li .icons .n span {
        color: #2bbf9d; }
      .suyuan_record .list li .icons dt {
        font-size: 12px; }
      .suyuan_record .list li .icons .btn {
        padding: 5px 10px; }
  .suyuan_record .list .list_txt {
    font-size: 14px;
    color: #333; }
    .suyuan_record .list .list_txt dt {
      padding: 2px 0;
      font-weight: 700; }

.suyuan_record .list:after {
  height: 90%;
  width: 1px;
  content: '';
  position: absolute;
  top: 30px;
  left: 10px;
  background: #2bbf9d; }

.no_data {
  text-align: center;
  color: #666;
  padding: 15px 0; }
  .no_data .oImg, .no_data .trial_invite .info .ct .list li .user .font, .trial_invite .info .ct .list li .user .no_data .font {
    width: 80px;
    margin: 0 auto 15px; }

.streaming {
  padding: 52px 0 10px; }
  .streaming .list {
    padding: 0 12px; }
    .streaming .list li {
      border-radius: 5px;
      position: relative;
      margin-top: 10px;
      background: #fff; }
      .streaming .list li .bjs {
        border-radius: 5px;
        overflow: hidden;
        position: relative; }
      .streaming .list li .bjs:after {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 44;
        background: rgba(0, 0, 0, 0.4); }
      .streaming .list li .info {
        position: absolute;
        left: 50%;
        top: 50%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        z-index: 55;
        color: #fff; }
        .streaming .list li .info button {
          background: rgba(0, 0, 0, 0.6);
          width: 50px;
          height: 50px;
          border-radius: 50%;
          text-align: center;
          line-height: 50px;
          font-size: 22px;
          display: block;
          margin: 0 auto 10px; }

.video_play {
  position: relative; }
  .video_play .navTit {
    color: #fff;
    text-align: center;
    height: 44px;
    line-height: 44px;
    font-size: 16px;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 99;
    position: fixed; }
    .video_play .navTit button {
      width: 44px;
      height: 44px;
      position: absolute;
      top: 0;
      z-index: 5;
      text-align: center;
      line-height: 44px; }
    .video_play .navTit .back {
      left: 0px; }
  .video_play .ptx {
    position: absolute;
    padding: 12px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    font-size: 12px;
    color: #fff;
    z-index: 55;
    left: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.6); }

.comment_list_one, .comment_list_pro {
  background: #fff; }
  .comment_list_one .tit_name, .comment_list_pro .tit_name {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -moz-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 0 12px;
    height: 40px;
    line-height: 40px;
    background: #fff; }
    .comment_list_one .tit_name .n, .comment_list_pro .tit_name .n {
      color: #f23440; }
    .comment_list_one .tit_name .iconfont, .comment_list_pro .tit_name .iconfont {
      font-size: 12px; }
  .comment_list_one .list li, .comment_list_pro .list li {
    background: #fff;
    padding: 10px 0 20px;
    position: relative; }
    .comment_list_one .list li .imgs, .comment_list_pro .list li .imgs {
      overflow: hidden;
      padding: 10px 0 0 0; }
      .comment_list_one .list li .imgs dt, .comment_list_pro .list li .imgs dt {
        height: 60px;
        width: 60px;
        margin-right: 10px;
        float: left; }
    .comment_list_one .list li .user_info, .comment_list_pro .list li .user_info {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -moz-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      padding: 10px 0; }
      .comment_list_one .list li .user_info .oImg, .comment_list_pro .list li .user_info .oImg, .comment_list_one .trial_invite .info .ct .list li .user_info .user .font, .trial_invite .info .ct .comment_list_one .list li .user_info .user .font, .comment_list_one .trial_invite .info .ct .list li .user .user_info .font, .trial_invite .info .ct .comment_list_one .list li .user .user_info .font, .comment_list_pro .trial_invite .info .ct .list li .user_info .user .font, .trial_invite .info .ct .comment_list_pro .list li .user_info .user .font, .comment_list_pro .trial_invite .info .ct .list li .user .user_info .font, .trial_invite .info .ct .comment_list_pro .list li .user .user_info .font {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        overflow: hidden; }
      .comment_list_one .list li .user_info .info, .comment_list_pro .list li .user_info .info {
        margin-left: 10px;
        -webkit-box-flex: 1;
        -moz-box-flex: 1;
        -ms-flex: 1;
        flex: 1; }
        .comment_list_one .list li .user_info .info .name, .comment_list_pro .list li .user_info .info .name {
          display: -webkit-box;
          display: -moz-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -moz-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          -webkit-box-pack: justify;
          -moz-box-pack: justify;
          -ms-flex-pack: justify;
          justify-content: space-between; }
          .comment_list_one .list li .user_info .info .name em, .comment_list_pro .list li .user_info .info .name em {
            font-size: 14px;
            font-weight: 700;
            margin-bottom: 5px; }
          .comment_list_one .list li .user_info .info .name .font, .comment_list_pro .list li .user_info .info .name .font {
            font-size: 12px;
            color: #ff9f0a; }
        .comment_list_one .list li .user_info .info .m, .comment_list_pro .list li .user_info .info .m {
          font-size: 12px;
          color: #666; }
    .comment_list_one .list li .content .ptx, .comment_list_pro .list li .content .ptx {
      color: #666;
      font-size: 12px; }
    .comment_list_one .list li .content .nc, .comment_list_pro .list li .content .nc {
      background: #f2f2f2;
      padding: 10px;
      position: relative;
      margin-top: 15px;
      border-radius: 4px; }
      .comment_list_one .list li .content .nc .px, .comment_list_pro .list li .content .nc .px {
        color: #666; }
      .comment_list_one .list li .content .nc .t, .comment_list_pro .list li .content .nc .t {
        font-size: 12px;
        color: #666;
        margin-top: 4px; }
    .comment_list_one .list li .content .nc.btn, .comment_list_pro .list li .content .nc.btn {
      padding-right: 60px; }
      .comment_list_one .list li .content .nc.btn .del, .comment_list_pro .list li .content .nc.btn .del {
        font-size: 12px;
        color: #666;
        border-radius: 2px;
        border: 1px solid #ccc;
        padding: 4px 6px;
        position: absolute;
        top: 50%;
        right: 12px;
        -webkit-transform: translate(0, -50%);
        transform: translate(0, -50%);
        z-index: 4; }
    .comment_list_one .list li .content .nc:after, .comment_list_pro .list li .content .nc:after {
      display: block;
      content: "";
      position: absolute;
      top: -16px;
      left: 8px;
      z-index: 4;
      width: 0;
      height: 0;
      border-width: 8px;
      border-style: solid;
      border-color: transparent transparent #f2f2f2 transparent; }
    .comment_list_one .list li .huifu, .comment_list_pro .list li .huifu {
      padding: 12px;
      text-align: right; }
      .comment_list_one .list li .huifu button, .comment_list_pro .list li .huifu button {
        background: #f2f2f2;
        color: #666;
        padding: 8px;
        font-size: 12px;
        border-radius: 5px; }

.comment_list_pro .list {
  padding: 0 12px; }

.guige {
  position: fixed;
  width: 100%;
  z-index: 99;
  background: #fff;
  bottom: 0; }
  .guige .close_box {
    height: 30px;
    overflow: hidden; }
    .guige .close_box button {
      float: right;
      height: 30px;
      width: 30px;
      font-size: 14px;
      color: #999;
      margin-right: 6px; }
  .guige .list {
    overflow: hidden; }
    .guige .list li {
      height: 30px;
      line-height: 30px;
      font-size: 12px;
      padding: 0 15px;
      float: left;
      margin-right: 10px;
      border-radius: 2px;
      background: #f8f8f8;
      margin-top: 10px;
      color: #666;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box; }
      .guige .list li:active {
        background: #f4f4f4; }
    .guige .list li.sel {
      color: #2bbf9d;
      border: 1px solid #2bbf9d; }
  .guige .bj li {
    background: #f2f2f2;
    border: 1px solid #ccc; }
  .guige .bj .sel {
    background: #e2f6f4; }
  .guige .pro {
    padding: 12px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    border-bottom: 1px solid #eee; }
    .guige .pro .oImg, .guige .pro .trial_invite .info .ct .list li .user .font, .trial_invite .info .ct .list li .user .guige .pro .font {
      width: 25vw;
      height: 25vw;
      overflow: hidden; }
    .guige .pro .info {
      margin-left: 10px;
      -webkit-box-flex: 1;
      -moz-box-flex: 1;
      -ms-flex: 1;
      flex: 1; }
      .guige .pro .info .close {
        overflow: hidden; }
        .guige .pro .info .close button {
          float: right;
          padding: 10px;
          font-size: 14px;
          color: #999;
          border-radius: 2px; }
          .guige .pro .info .close button:active {
            background: #f4f4f4; }
      .guige .pro .info .price {
        margin-top: 5px;
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -moz-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: justify;
        -moz-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between; }
        .guige .pro .info .price em {
          color: #f23440;
          font-size: 16px; }
          .guige .pro .info .price em .hz {
            color: #2bbf9d; }
          .guige .pro .info .price em s {
            font-size: 12px;
            color: #999; }
        .guige .pro .info .price b, .guige .pro .info .price i {
          padding: 2px 5px;
          color: #fff;
          font-size: 12px;
          border-radius: 2px; }
        .guige .pro .info .price b {
          background: #2bbf9d; }
        .guige .pro .info .price i {
          background: #f23440; }
  .guige .zs {
    padding: 18px 12px;
    border-top: 1px solid #eee; }
    .guige .zs em, .guige .zs span, .guige .zs b {
      display: inline-block;
      vertical-align: middle;
      font-size: 12px;
      color: #666;
      font-weight: 700; }
    .guige .zs em {
      width: 8px;
      height: 8px;
      background: #2bbf9d;
      border-radius: 50%; }
    .guige .zs b {
      margin: 0 5px; }
    .guige .zs span {
      color: #2bbf9d;
      font-size: 14px; }
  .guige .zhichi button {
    background: #2bbf9d;
    color: #fff;
    height: 40px;
    width: 100%; }
  .guige .ys,
  .guige .chima,
  .guige .nub {
    padding: 10px 12px; }
  .guige .btns button {
    height: 44px;
    width: 50%;
    float: left;
    color: #fff;
    background: #2bbf9d; }
    .guige .btns button:active {
      background: #27af90; }
  .guige .btns .l {
    background: #333; }
    .guige .btns .l:active {
      background: #383838; }
  .guige .btns .wa {
    width: 100%; }

.shopping_cart .list li {
  padding: 15px 12px 15px 10px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative; }
  .shopping_cart .list li > .font {
    width: 28px;
    height: 28px;
    color: #999;
    font-size: 22px;
    border-radius: 2px; }
    .shopping_cart .list li > .font.sel {
      color: #2bbf9d; }
    .shopping_cart .list li > .font:active {
      background: #f4f4f4; }
  .shopping_cart .list li .oImg, .shopping_cart .trial_invite .info .ct .list li .user .font, .trial_invite .info .ct .shopping_cart .list li .user .font {
    width: 26vw;
    height: 26vw;
    margin: 0 10px; }
  .shopping_cart .list li .info {
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -ms-flex: 1;
    flex: 1; }
    .shopping_cart .list li .info .close {
      text-align: right; }
      .shopping_cart .list li .info .close button {
        color: #999;
        padding: 5px;
        border-radius: 2px;
        font-size: 14px; }
        .shopping_cart .list li .info .close button:active {
          background: #f4f4f4; }
    .shopping_cart .list li .info .name {
      font-weight: 700; }
    .shopping_cart .list li .info .para {
      font-size: 12px;
      color: #999;
      margin: 3px 0; }

.shopping_cart .btns_d_p {
  position: fixed;
  width: 100%;
  left: 0;
  bottom: 0;
  z-index: 77;
  background: #fff;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -moz-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding-left: 12px; }
  .shopping_cart .btns_d_p .select {
    padding: 4px 8px;
    border-radius: 2px;
    color: #666; }
    .shopping_cart .btns_d_p .select .font {
      font-size: 20px;
      margin-right: 5px;
      vertical-align: -2px; }
    .shopping_cart .btns_d_p .select:active {
      background: #f4f4f4; }
    .shopping_cart .btns_d_p .select.sel .font {
      color: #2bbf9d; }
  .shopping_cart .btns_d_p p.hz {
    font-size: 10px;
    color: #999;
    margin-top: 2px; }
    .shopping_cart .btns_d_p p.hz span {
      color: #f23440;
      font-size: 10px;
      font-weight: 400; }
  .shopping_cart .btns_d_p p span {
    color: #f23440;
    font-size: 13px;
    font-weight: 700; }
  .shopping_cart .btns_d_p .settle_btn {
    width: 34vw;
    height: 50px;
    background: #2bbf9d;
    color: #fff; }
    .shopping_cart .btns_d_p .settle_btn:active {
      background: #27af90; }

.sel_address {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 15px 12px;
  position: relative; }
  .sel_address:active {
    background: #f4f4f4; }
  .sel_address .font {
    font-size: 20px; }
  .sel_address em.font {
    color: #2bbf9d; }
  .sel_address span.font {
    color: #999; }
  .sel_address .info {
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    margin: 0 10px; }
    .sel_address .info .name_phone {
      font-weight: 700;
      margin-bottom: 5px; }
    .sel_address .info .ress {
      font-size: 13px;
      color: #666; }

.addres_add_ico {
  text-align: center;
  height: 50px;
  line-height: 50px;
  position: relative; }
  .addres_add_ico:active {
    background: #f4f4f4; }

.affrim_order .pro_info {
  padding: 15px 12px 15px 10px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative; }
  .affrim_order .pro_info > .font {
    width: 28px;
    height: 28px;
    color: #999;
    font-size: 22px;
    border-radius: 2px; }
    .affrim_order .pro_info > .font.sel {
      color: #2bbf9d; }
    .affrim_order .pro_info > .font:active {
      background: #f4f4f4; }
  .affrim_order .pro_info .oImg, .affrim_order .pro_info .trial_invite .info .ct .list li .user .font, .trial_invite .info .ct .list li .user .affrim_order .pro_info .font {
    width: 26vw;
    height: 26vw;
    margin: 0 10px; }
  .affrim_order .pro_info .info {
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -ms-flex: 1;
    flex: 1; }
    .affrim_order .pro_info .info .close {
      text-align: right; }
      .affrim_order .pro_info .info .close button {
        color: #999;
        padding: 5px;
        border-radius: 2px;
        font-size: 14px; }
        .affrim_order .pro_info .info .close button:active {
          background: #f4f4f4; }
    .affrim_order .pro_info .info .name {
      font-weight: 700; }
    .affrim_order .pro_info .info .para {
      font-size: 12px;
      color: #999;
      margin: 3px 0; }

.affrim_order .data_s {
  overflow: hidden;
  position: relative; }
  .affrim_order .data_s dt {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -moz-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 10px 12px 10px 12px;
    position: relative;
    font-size: 14px; }
    .affrim_order .data_s dt.zj {
      font-weight: 700; }
      .affrim_order .data_s dt.zj b {
        font-weight: 700;
        color: #333;
        font-size: 13px; }
    .affrim_order .data_s dt.ac:active {
      background: #f4f4f4; }
    .affrim_order .data_s dt em {
      max-width: 50%; }
      .affrim_order .data_s dt em span.font {
        color: #2bbf9d; }
      .affrim_order .data_s dt em b {
        color: #f23440;
        font-size: 15px;
        margin: 0 2px; }
      .affrim_order .data_s dt em .sel {
        color: #2bbf9d; }
      .affrim_order .data_s dt em i {
        color: #999;
        position: absolute;
        top: 50%;
        right: 12px;
        -webkit-transform: translate(0, -50%);
        transform: translate(0, -50%);
        z-index: 1; }

.affrim_order .payment {
  overflow: hidden;
  position: relative; }
  .affrim_order .payment .name {
    font-weight: 700;
    padding: 10px 12px; }
  .affrim_order .payment .list dt {
    padding: 15px 12px;
    position: relative;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center; }
    .affrim_order .payment .list dt em {
      margin-left: 5px; }
    .affrim_order .payment .list dt span.font {
      font-size: 26px;
      vertical-align: middle; }
    .affrim_order .payment .list dt .xj_ico {
      color: #f23440; }
    .affrim_order .payment .list dt .wx_ico {
      color: #0ad30b; }
    .affrim_order .payment .list dt .zfb_ico {
      color: #0db5ff; }
    .affrim_order .payment .list dt .xj_nu {
      color: #f23440; }
    .affrim_order .payment .list dt:active {
      background: #f4f4f4; }
    .affrim_order .payment .list dt.sel .ico {
      position: absolute;
      top: 50%;
      right: 12px;
      -webkit-transform: translate(0, -50%);
      transform: translate(0, -50%);
      color: #2bbf9d;
      font-size: 24px; }

.affrim_order .go_pay {
  position: fixed;
  bottom: 0;
  height: 50px;
  background: #fff;
  left: 0;
  z-index: 77;
  width: 100%;
  text-align: right;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -moz-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding-left: 12px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }
  .affrim_order .go_pay em {
    margin-right: 10px;
    font-size: 14px; }
    .affrim_order .go_pay em span {
      padding: 0 5px;
      font-size: 13px;
      font-weight: 700;
      color: #f23440; }
  .affrim_order .go_pay button {
    background: #2bbf9d;
    color: #fff;
    height: 50px;
    width: 40vw; }
    .affrim_order .go_pay button:active {
      background: #27af90; }

.affrim_order .pay_hz {
  padding: 20px 12px; }
  .affrim_order .pay_hz button {
    background: #2bbf9d;
    color: #fff;
    border-radius: 4px;
    width: 100%;
    height: 50px;
    font-size: 16px; }
    .affrim_order .pay_hz button:active {
      background: #27af90; }

.auction_price_p {
  width: calc(100vw - 40px);
  background: #fff;
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 99;
  border-radius: 4px;
  overflow: hidden; }
  .auction_price_p h3 {
    height: 44px;
    line-height: 44px;
    padding: 0 12px;
    border-bottom: 1px solid #eee;
    margin-bottom: 10px; }
    .auction_price_p h3 .font {
      color: #2bbf9d; }
  .auction_price_p p {
    padding: 5px 12px;
    color: #666; }
  .auction_price_p .put_auc {
    padding: 15px 12px;
    position: relative; }
    .auction_price_p .put_auc input {
      height: 50px;
      width: 100%;
      background: #f4f4f4;
      border-radius: 2px;
      text-indent: 50px;
      font-size: 16px; }
    .auction_price_p .put_auc .font {
      color: #2bbf9d;
      position: absolute;
      top: 50%;
      left: 30px;
      -webkit-transform: translate(0, -50%);
      transform: translate(0, -50%);
      z-index: 1;
      font-size: 22px; }
  .auction_price_p .btn {
    font-size: 0;
    height: 50px; }
    .auction_price_p .btn button {
      height: 50px;
      width: 50%;
      color: #fff;
      float: left;
      font-size: 16px; }
      .auction_price_p .btn button.cancel {
        background: #eee;
        color: #999; }
        .auction_price_p .btn button.cancel:active {
          background: #f4f4f4; }
      .auction_price_p .btn button.sure {
        background: #2bbf9d; }
        .auction_price_p .btn button.sure:active {
          background: #27af90; }

.kill_past_list .list, .purchasing_order .list, .auction_order .list, .trial_order .list, .trial_list .list {
  padding: 10px 12px; }
  .kill_past_list .list li, .purchasing_order .list li, .auction_order .list li, .trial_order .list li, .trial_list .list li {
    overflow: hidden;
    color: #666;
    padding: 10px 10px 10px 10px;
    background: #fff;
    margin-bottom: 10px;
    border-radius: 4px;
    -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1); }
    .kill_past_list .list li .order_code, .purchasing_order .list li .order_code, .auction_order .list li .order_code, .trial_order .list li .order_code, .trial_list .list li .order_code {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -moz-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: justify;
      -moz-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      padding: 0px 0 5px 0;
      font-size: 12px;
      color: #333; }
      .kill_past_list .list li .order_code b, .purchasing_order .list li .order_code b, .auction_order .list li .order_code b, .trial_order .list li .order_code b, .trial_list .list li .order_code b {
        color: #999; }
    .kill_past_list .list li .oImg, .purchasing_order .list li .oImg, .auction_order .list li .oImg, .trial_order .list li .oImg, .trial_list .list li .oImg, .kill_past_list .trial_invite .info .ct .list li .user .font, .trial_invite .info .ct .kill_past_list .list li .user .font, .purchasing_order .trial_invite .info .ct .list li .user .font, .trial_invite .info .ct .purchasing_order .list li .user .font, .auction_order .trial_invite .info .ct .list li .user .font, .trial_invite .info .ct .auction_order .list li .user .font, .trial_order .trial_invite .info .ct .list li .user .font, .trial_invite .info .ct .trial_order .list li .user .font, .trial_list .trial_invite .info .ct .list li .user .font, .trial_invite .info .ct .trial_list .list li .user .font {
      width: 110px;
      height: 110px;
      float: left;
      display: block;
      overflow: hidden;
      position: relative; }
      .kill_past_list .list li .oImg .ico, .purchasing_order .list li .oImg .ico, .auction_order .list li .oImg .ico, .trial_order .list li .oImg .ico, .trial_list .list li .oImg .ico, .kill_past_list .trial_invite .info .ct .list li .user .font .ico, .trial_invite .info .ct .kill_past_list .list li .user .font .ico, .purchasing_order .trial_invite .info .ct .list li .user .font .ico, .trial_invite .info .ct .purchasing_order .list li .user .font .ico, .auction_order .trial_invite .info .ct .list li .user .font .ico, .trial_invite .info .ct .auction_order .list li .user .font .ico, .trial_order .trial_invite .info .ct .list li .user .font .ico, .trial_invite .info .ct .trial_order .list li .user .font .ico, .trial_list .trial_invite .info .ct .list li .user .font .ico, .trial_invite .info .ct .trial_list .list li .user .font .ico {
        position: absolute;
        top: 0;
        left: 0;
        width: 40px;
        z-index: 5; }
    .kill_past_list .list li .info, .purchasing_order .list li .info, .auction_order .list li .info, .trial_order .list li .info, .trial_list .list li .info {
      width: calc(100% - 120px);
      float: right; }
      .kill_past_list .list li .info h3, .purchasing_order .list li .info h3, .auction_order .list li .info h3, .trial_order .list li .info h3, .trial_list .list li .info h3 {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: #333;
        font-size: 14px; }
      .kill_past_list .list li .info h4, .purchasing_order .list li .info h4, .auction_order .list li .info h4, .trial_order .list li .info h4, .trial_list .list li .info h4 {
        font-weight: 700; }
      .kill_past_list .list li .info .pir, .purchasing_order .list li .info .pir, .auction_order .list li .info .pir, .trial_order .list li .info .pir, .trial_list .list li .info .pir {
        color: #2bbf9d;
        margin: 5px 0;
        font-size: 12px; }
      .kill_past_list .list li .info .priSc, .purchasing_order .list li .info .priSc, .auction_order .list li .info .priSc, .trial_order .list li .info .priSc, .trial_list .list li .info .priSc {
        font-size: 12px; }
      .kill_past_list .list li .info .prama, .purchasing_order .list li .info .prama, .auction_order .list li .info .prama, .trial_order .list li .info .prama, .trial_list .list li .info .prama {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -moz-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: justify;
        -moz-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        font-size: 12px;
        color: #999;
        margin: 5px 0; }
      .kill_past_list .list li .info .btns, .purchasing_order .list li .info .btns, .auction_order .list li .info .btns, .trial_order .list li .info .btns, .trial_list .list li .info .btns {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -moz-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: justify;
        -moz-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between; }
        .kill_past_list .list li .info .btns .sh_pri, .purchasing_order .list li .info .btns .sh_pri, .auction_order .list li .info .btns .sh_pri, .trial_order .list li .info .btns .sh_pri, .trial_list .list li .info .btns .sh_pri {
          color: #f23440;
          font-size: 14px; }
          .kill_past_list .list li .info .btns .sh_pri s, .purchasing_order .list li .info .btns .sh_pri s, .auction_order .list li .info .btns .sh_pri s, .trial_order .list li .info .btns .sh_pri s, .trial_list .list li .info .btns .sh_pri s {
            color: #999;
            font-size: 12px; }
        .kill_past_list .list li .info .btns em, .purchasing_order .list li .info .btns em, .auction_order .list li .info .btns em, .trial_order .list li .info .btns em, .trial_list .list li .info .btns em {
          font-size: 12px; }
        .kill_past_list .list li .info .btns .go_p, .purchasing_order .list li .info .btns .go_p, .auction_order .list li .info .btns .go_p, .trial_order .list li .info .btns .go_p, .trial_list .list li .info .btns .go_p {
          font-size: 12px; }
          .kill_past_list .list li .info .btns .go_p span, .purchasing_order .list li .info .btns .go_p span, .auction_order .list li .info .btns .go_p span, .trial_order .list li .info .btns .go_p span, .trial_list .list li .info .btns .go_p span {
            font-size: 15px; }
            .kill_past_list .list li .info .btns .go_p span.sel, .purchasing_order .list li .info .btns .go_p span.sel, .auction_order .list li .info .btns .go_p span.sel, .trial_order .list li .info .btns .go_p span.sel, .trial_list .list li .info .btns .go_p span.sel {
              color: #2bbf9d; }
        .kill_past_list .list li .info .btns .goOn, .purchasing_order .list li .info .btns .goOn, .auction_order .list li .info .btns .goOn, .trial_order .list li .info .btns .goOn, .trial_list .list li .info .btns .goOn {
          background: #666;
          color: #fff;
          height: 26px;
          padding: 0 8px;
          border-radius: 2px;
          line-height: 26px;
          text-align: center;
          font-size: 11px; }
        .kill_past_list .list li .info .btns .goOn.wuliu, .purchasing_order .list li .info .btns .goOn.wuliu, .auction_order .list li .info .btns .goOn.wuliu, .trial_order .list li .info .btns .goOn.wuliu, .trial_list .list li .info .btns .goOn.wuliu {
          background: #fff;
          border: 1px solid #2bbf9d;
          color: #2bbf9d; }
        .kill_past_list .list li .info .btns .goOn.sel, .purchasing_order .list li .info .btns .goOn.sel, .auction_order .list li .info .btns .goOn.sel, .trial_order .list li .info .btns .goOn.sel, .trial_list .list li .info .btns .goOn.sel {
          background: #2bbf9d; }
          .kill_past_list .list li .info .btns .goOn.sel:active, .purchasing_order .list li .info .btns .goOn.sel:active, .auction_order .list li .info .btns .goOn.sel:active, .trial_order .list li .info .btns .goOn.sel:active, .trial_list .list li .info .btns .goOn.sel:active {
            background: #27af90; }
        .kill_past_list .list li .info .btns .goOn.success, .purchasing_order .list li .info .btns .goOn.success, .auction_order .list li .info .btns .goOn.success, .trial_order .list li .info .btns .goOn.success, .trial_list .list li .info .btns .goOn.success {
          background: #f30606; }
        .kill_past_list .list li .info .btns .goOn.will, .purchasing_order .list li .info .btns .goOn.will, .auction_order .list li .info .btns .goOn.will, .trial_order .list li .info .btns .goOn.will, .trial_list .list li .info .btns .goOn.will {
          background: #f23440; }
      .kill_past_list .list li .info .timer, .purchasing_order .list li .info .timer, .auction_order .list li .info .timer, .trial_order .list li .info .timer, .trial_list .list li .info .timer {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -moz-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        margin: 5px 0; }
        .kill_past_list .list li .info .timer em, .purchasing_order .list li .info .timer em, .auction_order .list li .info .timer em, .trial_order .list li .info .timer em, .trial_list .list li .info .timer em {
          font-size: 12px;
          margin-right: 4px; }
        .kill_past_list .list li .info .timer dt, .purchasing_order .list li .info .timer dt, .auction_order .list li .info .timer dt, .trial_order .list li .info .timer dt, .trial_list .list li .info .timer dt {
          background: #666666;
          color: #fff;
          padding: 2px 3px;
          font-size: 10px;
          margin: 0 2px;
          border-radius: 2px; }

.no_more {
  text-align: center;
  padding: 25px 0;
  color: #999;
  position: relative;
  background: #fff; }
  .no_more:before {
    height: 1px;
    width: calc(100% - 24px);
    position: absolute;
    top: 50%;
    left: 12px;
    background: #eee;
    content: "";
    display: block;
    z-index: 4; }
  .no_more em {
    background: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    z-index: 5;
    padding: 2px 20px; }

.trial_affrim .user_xz {
  padding: 20px 12px; }
  .trial_affrim .user_xz .ct {
    background: #f5f5f5;
    padding: 10px;
    border-radius: 4px; }
    .trial_affrim .user_xz .ct h3 {
      margin-bottom: 10px; }
    .trial_affrim .user_xz .ct p {
      text-align: justify;
      letter-spacing: 1px;
      padding: 2px 0; }

.trial_affrim .trial_btn {
  padding: 0 12px; }
  .trial_affrim .trial_btn button {
    background: #2bbf9d;
    color: #fff;
    height: 50px;
    border-radius: 2px;
    width: 100%;
    font-size: 15px; }
    .trial_affrim .trial_btn button:active {
      background: #27af90; }

.user_header {
  position: fixed;
  z-index: 88;
  height: 44px;
  line-height: 44px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  color: #666;
  width: 100%;
  background: #fff;
  top: 0;
  left: 0; }
  .user_header p {
    font-weight: 700;
    font-size: 16px;
    margin-left: 12px; }
  .user_header .email_news {
    height: 28px;
    padding: 0 6px;
    border-radius: 2px;
    position: absolute;
    top: 50%;
    right: 12px;
    -webkit-transform: translate(0, -50%);
    transform: translate(0, -50%);
    z-index: 1;
    font-size: 26px; }
    .user_header .email_news em {
      background: #f23440;
      color: #fff;
      font-size: 11px;
      position: absolute;
      top: 0;
      right: 4px;
      z-index: 1;
      height: 8px;
      width: 8px;
      border-radius: 50%; }
    .user_header .email_news:active {
      background: #f4f4f4; }

.personal_index {
  padding: 20px 12px; }
  .personal_index .userInfo {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -moz-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between; }
    .personal_index .userInfo .l {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -moz-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: justify;
      -moz-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between; }
      .personal_index .userInfo .l .oImg, .personal_index .userInfo .l .trial_invite .info .ct .list li .user .font, .trial_invite .info .ct .list li .user .personal_index .userInfo .l .font {
        width: 15vw;
        height: 15vw;
        border-radius: 50%;
        overflow: hidden; }
      .personal_index .userInfo .l .name_id {
        margin-left: 10px; }
        .personal_index .userInfo .l .name_id .name {
          font-size: 16px;
          font-weight: 700;
          margin-bottom: 5px; }
        .personal_index .userInfo .l .name_id .id {
          font-size: 14px;
          color: #666; }
    .personal_index .userInfo .r button {
      background: #2bbf9d;
      height: 34px;
      padding: 0 10px;
      border-radius: 30px;
      color: #fff; }
      .personal_index .userInfo .r button:active {
        background: #27af90; }
  .personal_index .order_icos {
    background: #f5f5f5;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    text-align: center;
    font-size: 12px;
    color: #666;
    padding: 10px 0;
    margin: 20px 0; }
    .personal_index .order_icos li {
      padding: 10px;
      border-radius: 2px;
      position: relative; }
      .personal_index .order_icos li .font {
        font-size: 22px; }
      .personal_index .order_icos li:active {
        background: #eaeaea; }
      .personal_index .order_icos li .hove_num {
        width: 16px;
        height: 16px;
        line-height: 16px;
        text-align: center;
        background: #f23440;
        color: #fff;
        position: absolute;
        top: 0;
        right: 5px;
        border-radius: 50%; }
  .personal_index .module h3 {
    font-weight: 700;
    font-size: 16px;
    margin-bottom: 10px; }
  .personal_index .module .list {
    overflow: hidden; }
    .personal_index .module .list li {
      border-radius: 2px;
      width: 25%;
      float: left;
      text-align: center;
      padding: 15px 0; }
      .personal_index .module .list li p {
        font-size: 13px;
        position: relative; }
      .personal_index .module .list li .font {
        font-size: 24px;
        margin-bottom: 6px; }
      .personal_index .module .list li:active {
        background: #f4f4f4; }
      .personal_index .module .list li .ico {
        position: absolute;
        top: 0;
        right: 25%;
        height: 8px;
        width: 8px;
        border-radius: 50%;
        background: #f23440; }
    .personal_index .module .list.zc li .font {
      color: #2bbf9d; }
  .personal_index .module .set_list li {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -moz-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    height: 50px;
    line-height: 50px;
    position: relative; }
    .personal_index .module .set_list li span.font {
      color: #999; }
    .personal_index .module .set_list li:active {
      background: #f4f4f4; }
  .personal_index .water_purifier {
    background: -webkit-gradient(linear, left top, right top, from(#28bcc6), to(#28bea1));
    background: linear-gradient(to right, #28bcc6, #28bea1);
    padding: 10px;
    border-radius: 2px;
    color: #fff;
    margin: 20px 0; }
    .personal_index .water_purifier .num_btn {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -moz-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: justify;
      -moz-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      margin-top: 10px; }
      .personal_index .water_purifier .num_btn .num {
        font-size: 12px; }
      .personal_index .water_purifier .num_btn .btn button {
        padding: 4px 8px;
        font-size: 13px;
        border-radius: 2px; }
        .personal_index .water_purifier .num_btn .btn button:active {
          background: #27af90; }
  .personal_index .user_daili {
    padding: 10px 0; }

.my_hz_wallet, .my_food_coupon, .my_invitation {
  padding: 20px 12px; }
  .my_hz_wallet .hz_info_ka, .my_food_coupon .hz_info_ka, .my_invitation .hz_info_ka {
    background: -webkit-gradient(linear, left top, left bottom, from(#28bcc6), to(#28bea1));
    background: linear-gradient(to bottom, #28bcc6, #28bea1);
    color: #fff;
    border-radius: 4px;
    padding: 20px 15px;
    position: relative; }
    .my_hz_wallet .hz_info_ka .name, .my_food_coupon .hz_info_ka .name, .my_invitation .hz_info_ka .name {
      font-size: 17px; }
      .my_hz_wallet .hz_info_ka .name b, .my_food_coupon .hz_info_ka .name b, .my_invitation .hz_info_ka .name b {
        font-size: 12px; }
    .my_hz_wallet .hz_info_ka .num, .my_food_coupon .hz_info_ka .num, .my_invitation .hz_info_ka .num {
      font-size: 24px;
      font-weight: 700;
      margin: 15px 0 20px;
      letter-spacing: 2px; }
  .my_hz_wallet table, .my_food_coupon table, .my_invitation table {
    width: 100%;
    text-align: center; }
  .my_hz_wallet .aq, .my_food_coupon .aq, .my_invitation .aq {
    position: absolute;
    top: 5px;
    right: 5px;
    font-size: 13px;
    padding: 5px 5px;
    border-radius: 2px; }
    .my_hz_wallet .aq span, .my_food_coupon .aq span, .my_invitation .aq span {
      text-decoration: underline; }
    .my_hz_wallet .aq:active, .my_food_coupon .aq:active, .my_invitation .aq:active {
      background: #27af90; }
  .my_hz_wallet .btn_2, .my_food_coupon .btn_2, .my_invitation .btn_2 {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    padding: 20px 0;
    position: relative; }
    .my_hz_wallet .btn_2 button, .my_food_coupon .btn_2 button, .my_invitation .btn_2 button {
      height: 34px;
      padding: 0 20px;
      color: #fff;
      border-radius: 2px; }
      .my_hz_wallet .btn_2 button.l, .my_food_coupon .btn_2 button.l, .my_invitation .btn_2 button.l {
        background: #2bbf9d; }
        .my_hz_wallet .btn_2 button.l:active, .my_food_coupon .btn_2 button.l:active, .my_invitation .btn_2 button.l:active {
          background: #27af90; }
      .my_hz_wallet .btn_2 button.r, .my_food_coupon .btn_2 button.r, .my_invitation .btn_2 button.r {
        background: #f23440; }
        .my_hz_wallet .btn_2 button.r:active, .my_food_coupon .btn_2 button.r:active, .my_invitation .btn_2 button.r:active {
          background: #e1313c; }
      .my_hz_wallet .btn_2 button.sh, .my_food_coupon .btn_2 button.sh, .my_invitation .btn_2 button.sh {
        background: #2bbf9d; }
        .my_hz_wallet .btn_2 button.sh:active, .my_food_coupon .btn_2 button.sh:active, .my_invitation .btn_2 button.sh:active {
          background: #27af90; }
  .my_hz_wallet .module, .my_food_coupon .module, .my_invitation .module {
    overflow: hidden;
    position: relative;
    padding: 10px 0 20px; }
    .my_hz_wallet .module h3, .my_food_coupon .module h3, .my_invitation .module h3 {
      font-weight: 700;
      padding: 10px 0; }
    .my_hz_wallet .module .ct p, .my_food_coupon .module .ct p, .my_invitation .module .ct p {
      font-size: 13px;
      text-align: justify;
      letter-spacing: 0.5px;
      line-height: 18px;
      padding: 2px 0;
      color: #666; }

.my_hz_detail, .my_invitation, .xj_sz_detail, .xj_wallet_details {
  padding: 12px; }
  .my_hz_detail .num, .my_invitation .num, .xj_sz_detail .num, .xj_wallet_details .num {
    background: -webkit-gradient(linear, left top, left bottom, from(#28bcc6), to(#28bea1));
    background: linear-gradient(to bottom, #28bcc6, #28bea1);
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    color: #fff;
    padding: 20px 0;
    text-align: center;
    border-radius: 4px;
    font-size: 16px;
    position: relative; }
    .my_hz_detail .num:before, .my_invitation .num:before, .xj_sz_detail .num:before, .xj_wallet_details .num:before {
      height: 50%;
      width: 1px;
      background: rgba(255, 255, 255, 0.5);
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      z-index: 1;
      content: "";
      display: block; }
    .my_hz_detail .num .z, .my_invitation .num .z, .xj_sz_detail .num .z, .xj_wallet_details .num .z {
      margin-top: 10px; }
  .my_hz_detail table, .my_invitation table, .xj_sz_detail table, .xj_wallet_details table {
    width: 100%;
    text-align: center;
    margin: 15px 0; }
    .my_hz_detail table thead, .my_invitation table thead, .xj_sz_detail table thead, .xj_wallet_details table thead {
      font-size: 14px;
      font-weight: 700; }
    .my_hz_detail table td, .my_invitation table td, .xj_sz_detail table td, .xj_wallet_details table td {
      padding: 15px 0;
      position: relative;
      color: #666; }
      .my_hz_detail table td.add, .my_invitation table td.add, .xj_sz_detail table td.add, .xj_wallet_details table td.add {
        color: #2bbf9d; }
      .my_hz_detail table td.rm, .my_invitation table td.rm, .xj_sz_detail table td.rm, .xj_wallet_details table td.rm {
        color: #f23440; }
      .my_hz_detail table td.steate, .my_invitation table td.steate, .xj_sz_detail table td.steate, .xj_wallet_details table td.steate {
        color: #2bbf9d; }

.my_wallet_search {
  overflow: hidden; }
  .my_wallet_search .head {
    position: relative; }
    .my_wallet_search .head:after {
      clear: both;
      display: block;
      content: ""; }
    .my_wallet_search .head .cwTit {
      color: #fff;
      text-align: center;
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      z-index: 1; }
      .my_wallet_search .head .cwTit h4 {
        font-size: 18px;
        margin-bottom: 10px; }
      .my_wallet_search .head .cwTit em {
        font-size: 22px;
        letter-spacing: 1px; }
    .my_wallet_search .head .searchBox {
      -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
      height: 50px;
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: flex;
      background: #fff;
      width: calc(100% - 24px);
      border-radius: 5px;
      margin: 0 auto;
      overflow: hidden;
      position: absolute;
      left: 12px;
      bottom: -25px; }
      .my_wallet_search .head .searchBox input {
        -webkit-box-flex: 1;
        -moz-box-flex: 1;
        -ms-flex: 1;
        flex: 1;
        height: 50px;
        padding: 0 12px; }
      .my_wallet_search .head .searchBox button {
        background-size: 100% 100%;
        height: 50px;
        width: 80px;
        color: #fff;
        background: #2bbf9d; }
  .my_wallet_search .list {
    width: calc(100% - 24px);
    margin: 40px auto 10px; }
    .my_wallet_search .list li {
      border-radius: 5px;
      margin-top: 12px;
      background: #fff;
      padding: 0 12px;
      -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.2); }
      .my_wallet_search .list li .txt_02 dt {
        padding: 15px 0;
        border-bottom: 1px solid #eee;
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -moz-box-align: center;
        -ms-flex-align: center;
        align-items: center; }
        .my_wallet_search .list li .txt_02 dt em {
          font-size: 13px;
          margin-right: 10px; }
        .my_wallet_search .list li .txt_02 dt p {
          word-break: break-all;
          color: #999;
          font-size: 12px;
          margin-top: 4px;
          -webkit-box-flex: 1;
          -moz-box-flex: 1;
          -ms-flex: 1;
          flex: 1; }
      .my_wallet_search .list li .txt {
        width: 100%;
        border-bottom: 1px solid #eee;
        overflow: hidden;
        padding: 15px 0;
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -moz-box-align: center;
        -ms-flex-align: center;
        align-items: center; }
        .my_wallet_search .list li .txt dt {
          -webkit-box-flex: 1;
          -moz-box-flex: 1;
          -ms-flex: 1;
          flex: 1; }
          .my_wallet_search .list li .txt dt h4 {
            font-size: 13px;
            margin-bottom: 5px;
            color: #2e2e2e; }
          .my_wallet_search .list li .txt dt p {
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            color: #959595;
            font-size: 11px;
            word-break: break-all; }
        .my_wallet_search .list li .txt dt:nth-of-type(1) {
          float: left; }
        .my_wallet_search .list li .txt dt:nth-of-type(2) {
          float: right; }
      .my_wallet_search .list li .chainTimer {
        display: block;
        clear: both;
        height: 50px;
        line-height: 50px;
        color: #ccc;
        font-size: 12px;
        position: relative; }

.moreSearch {
  position: fixed;
  z-index: 110;
  right: 5px;
  top: 44px; }
  .moreSearch .icos {
    overflow: hidden; }
    .moreSearch .icos img {
      float: right;
      width: 20px;
      margin-right: 10px; }
  .moreSearch li {
    background: #fff;
    font-size: 14px;
    padding: 10px 15px;
    min-width: 120px;
    border-bottom: 1px solid #eee; }
    .moreSearch li i.iconfont {
      color: #2bbf9d;
      font-size: 18px;
      margin-right: 10px; }
  .moreSearch li:active {
    background: #f4f4f4; }
  .moreSearch li:nth-of-type(1) {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px; }
  .moreSearch li:nth-last-of-type(1) {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    border-bottom: 0; }

.my_wallet_details {
  padding: 12px; }
  .my_wallet_details h3 {
    font-size: 16px;
    font-weight: 700; }
  .my_wallet_details p {
    padding: 5px 0;
    color: #666; }
  .my_wallet_details .wallet_add {
    border-bottom: 1px solid #eee;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -moz-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 10px 0;
    color: #999; }
    .my_wallet_details .wallet_add:active {
      background: #f4f4f4; }
    .my_wallet_details .wallet_add .link {
      color: #2bbf9d;
      word-break: break-all;
      margin-right: 30px; }
  .my_wallet_details .btn {
    text-align: center;
    padding: 30px 0 10px; }
    .my_wallet_details .btn button {
      background: #2bbf9d;
      color: #fff;
      min-width: 140px;
      padding: 0 20px;
      border-radius: 50px;
      height: 40px; }
      .my_wallet_details .btn button:active {
        background: #27af90; }

.day_task {
  padding: 12px; }
  .day_task .head {
    border-radius: 4px;
    overflow: hidden; }
  .day_task .task_list, .day_task .new_user_task {
    background: #fff;
    padding: 20px 12px 0; }
    .day_task .task_list .t, .day_task .new_user_task .t {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: justify;
      -moz-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      -webkit-box-align: center;
      -moz-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      font-size: 14px;
      margin-bottom: 10px; }
      .day_task .task_list .t .name, .day_task .new_user_task .t .name {
        font-weight: 700;
        font-size: 18px; }
      .day_task .task_list .t .n, .day_task .new_user_task .t .n {
        color: #666; }
        .day_task .task_list .t .n span, .day_task .new_user_task .t .n span {
          color: #2bbf9d; }
    .day_task .task_list .list li, .day_task .new_user_task .list li {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: justify;
      -moz-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      -webkit-box-align: center;
      -moz-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      padding: 15px 0;
      position: relative; }
      .day_task .task_list .list li .l, .day_task .new_user_task .list li .l {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
        -moz-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        -webkit-box-align: center;
        -moz-box-align: center;
        -ms-flex-align: center;
        align-items: center; }
        .day_task .task_list .list li .l .font, .day_task .new_user_task .list li .l .font {
          font-size: 20px;
          margin-right: 10px; }
        .day_task .task_list .list li .l .info .name, .day_task .new_user_task .list li .l .info .name {
          font-size: 14px;
          font-weight: 700; }
        .day_task .task_list .list li .l .info .ptx, .day_task .new_user_task .list li .l .info .ptx {
          font-size: 12px;
          color: #999;
          margin-top: 2px; }
      .day_task .task_list .list li .btns, .day_task .new_user_task .list li .btns {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
        -moz-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        -webkit-box-align: center;
        -moz-box-align: center;
        -ms-flex-align: center;
        align-items: center; }
        .day_task .task_list .list li .btns button, .day_task .new_user_task .list li .btns button {
          border: 1px solid #2bbf9d;
          font-size: 12px;
          color: #2bbf9d;
          height: 30px;
          margin-left: 10px;
          width: 64px;
          border-radius: 2px;
          position: relative;
          overflow: hidden; }
          .day_task .task_list .list li .btns button em, .day_task .new_user_task .list li .btns button em {
            display: block;
            height: 30px;
            text-align: center;
            line-height: 30px;
            background: #eee;
            position: absolute;
            left: 0;
            top: 0;
            z-index: 1; }
          .day_task .task_list .list li .btns button b, .day_task .new_user_task .list li .btns button b {
            position: absolute;
            z-index: 2;
            top: 50%;
            left: 50%;
            -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%); }
        .day_task .task_list .list li .btns button.gor, .day_task .new_user_task .list li .btns button.gor {
          background: #999;
          color: #fff;
          border: 1px solid #999; }
        .day_task .task_list .list li .btns button.green, .day_task .new_user_task .list li .btns button.green {
          background: #2bbf9d;
          color: #fff;
          border: 1px solid #2bbf9d; }
          .day_task .task_list .list li .btns button.green:active, .day_task .new_user_task .list li .btns button.green:active {
            background: #27af90; }
    .day_task .task_list .list li:nth-last-of-type(1), .day_task .new_user_task .list li:nth-last-of-type(1) {
      border: 0; }
  .day_task .new_user_task .ptx {
    color: #2bbf9d !important; }

.my_orders {
  padding: 12px; }
  .my_orders .list li {
    margin-bottom: 15px;
    background: #fff;
    -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    border-radius: 10px; }
    .my_orders .list li .info {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: justify;
      -moz-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      padding: 10px; }
      .my_orders .list li .info .oImg, .my_orders .trial_invite .info .ct .list li .info .user .font, .trial_invite .info .ct .my_orders .list li .info .user .font, .my_orders .trial_invite .info .ct .list li .user .info .font, .trial_invite .info .ct .my_orders .list li .user .info .font {
        width: 20%;
        margin-right: 10px;
        position: relative; }
        .my_orders .list li .info .oImg .ly, .my_orders .trial_invite .info .ct .list li .info .user .font .ly, .trial_invite .info .ct .my_orders .list li .info .user .font .ly, .my_orders .trial_invite .info .ct .list li .user .info .font .ly, .trial_invite .info .ct .my_orders .list li .user .info .font .ly {
          position: absolute;
          top: 0;
          left: 0;
          z-index: 1;
          width: 100%;
          height: 100%; }
        .my_orders .list li .info .oImg .zc_ico, .my_orders .trial_invite .info .ct .list li .info .user .font .zc_ico, .trial_invite .info .ct .my_orders .list li .info .user .font .zc_ico, .my_orders .trial_invite .info .ct .list li .user .info .font .zc_ico, .trial_invite .info .ct .my_orders .list li .user .info .font .zc_ico {
          width: 40px;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 1; }
      .my_orders .list li .info .data {
        -webkit-box-flex: 1;
        -moz-box-flex: 1;
        -ms-flex: 1;
        flex: 1;
        width: 76%; }
        .my_orders .list li .info .data .name {
          font-weight: 700;
          margin-bottom: 4px; }
        .my_orders .list li .info .data .txt {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          color: #666;
          font-size: 12px; }
        .my_orders .list li .info .data .price {
          display: -webkit-box;
          display: -moz-box;
          display: -ms-flexbox;
          display: flex;
          margin-top: 3px;
          -webkit-box-pack: justify;
          -moz-box-pack: justify;
          -ms-flex-pack: justify;
          justify-content: space-between; }
          .my_orders .list li .info .data .price .sx {
            font-size: 12px;
            color: #999; }
          .my_orders .list li .info .data .price em {
            color: #f23440;
            font-weight: 700; }
            .my_orders .list li .info .data .price em .font {
              font-size: 14px;
              font-weight: 400;
              color: #2bbf9d; }
            .my_orders .list li .info .data .price em s {
              font-size: 11px;
              color: #999;
              font-weight: 400; }
          .my_orders .list li .info .data .price .lp {
            background: #f23440;
            color: #fff;
            font-size: 12px;
            padding: 2px 5px;
            border-radius: 2px; }
    .my_orders .list li .address_info {
      position: relative;
      border-top: 1px solid #eee;
      border-bottom: 1px solid #eee; }
      .my_orders .list li .address_info .have {
        background: #fff;
        padding: 15px 12px;
        position: relative;
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex; }
        .my_orders .list li .address_info .have i {
          width: 24px;
          line-height: 24px;
          color: #2bbf9d; }
        .my_orders .list li .address_info .have .name {
          width: calc(100% - 30px); }
      .my_orders .list li .address_info .have.modify {
        padding-right: 40px; }
      .my_orders .list li .address_info .add {
        text-align: center;
        line-height: 44px;
        background: #fff;
        height: 44px;
        text-align: center; }
    .my_orders .list li h3 {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: justify;
      -moz-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      padding: 15px 10px 5px;
      font-size: 12px; }
      .my_orders .list li h3 em {
        white-space: nowrap; }
    .my_orders .list li .new_more {
      padding: 10px 12px; }
      .my_orders .list li .new_more .zj em {
        color: #333;
        font-weight: 700; }
        .my_orders .list li .new_more .zj em.r {
          color: #333; }
      .my_orders .list li .new_more.sjzhfu .font {
        font-size: 14px; }
      .my_orders .list li .new_more.sjzhfu .prim {
        color: #f23440;
        font-weight: 700; }
      .my_orders .list li .new_more dt {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
        -moz-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        -webkit-box-align: center;
        -moz-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        padding: 5px 0; }
        .my_orders .list li .new_more dt em span.font {
          color: #2bbf9d; }
        .my_orders .list li .new_more dt em.r {
          color: #f23440; }
    .my_orders .list li .fu_more {
      text-align: right;
      padding: 0 12px; }
      .my_orders .list li .fu_more dt {
        padding: 5px 0; }
      .my_orders .list li .fu_more .price span {
        color: #f23440; }
      .my_orders .list li .fu_more .price .font {
        color: #2bbf9d;
        font-size: 14px; }
      .my_orders .list li .fu_more .btn {
        padding: 15px 0; }
        .my_orders .list li .fu_more .btn button {
          height: 34px;
          border: 1px solid #eee;
          border-radius: 4px;
          min-width: 80px;
          margin-left: 10px;
          font-size: 12px; }
          .my_orders .list li .fu_more .btn button:active {
            background: #f4f4f4; }
        .my_orders .list li .fu_more .btn button.sel {
          background: #2bbf9d;
          color: #fff; }
          .my_orders .list li .fu_more .btn button.sel:active {
            background: #27af90; }

.my_food_coupon {
  padding: 0 12px; }
  .my_food_coupon .tongjitu {
    overflow: hidden;
    position: relative; }
    .my_food_coupon .tongjitu h3 {
      margin-top: 10px;
      font-weight: 700;
      font-size: 16px;
      padding-left: 15px;
      position: relative;
      text-align: left; }
      .my_food_coupon .tongjitu h3:before {
        content: "";
        display: block;
        height: 16px;
        width: 3px;
        position: absolute;
        top: 50%;
        left: 0;
        z-index: 1;
        -webkit-transform: translate(0, -50%);
        transform: translate(0, -50%);
        background: #2bbf9d; }
      .my_food_coupon .tongjitu h3 .qy {
        position: absolute;
        top: 50%;
        -webkit-transform: translate(0, -50%);
        transform: translate(0, -50%);
        z-index: 1;
        right: 12px;
        font-size: 12px;
        color: #999; }
        .my_food_coupon .tongjitu h3 .qy span {
          padding-left: 5px;
          color: #2bbf9d;
          font-weight: 700; }
    .my_food_coupon .tongjitu .lbas {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-pack: distribute;
      justify-content: space-around;
      -webkit-box-align: center;
      -moz-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      font-size: 12px;
      color: #666;
      width: 84%;
      margin: 20px auto; }
      .my_food_coupon .tongjitu .lbas dt em, .my_food_coupon .tongjitu .lbas dt b {
        display: inline-block;
        vertical-align: middle; }
      .my_food_coupon .tongjitu .lbas dt .y, .my_food_coupon .tongjitu .lbas dt .z, .my_food_coupon .tongjitu .lbas dt .j {
        width: 10px;
        height: 10px; }
      .my_food_coupon .tongjitu .lbas dt .y {
        background: #f76502; }
      .my_food_coupon .tongjitu .lbas dt .z {
        background: #2bbf9d; }
      .my_food_coupon .tongjitu .lbas dt .j {
        background: #f2f2f2; }
    .my_food_coupon .tongjitu .lzrs {
      height: 270px;
      width: 100%;
      margin: 15px 0 45px;
      position: relative; }
      .my_food_coupon .tongjitu .lzrs .ls,
      .my_food_coupon .tongjitu .lzrs .zs,
      .my_food_coupon .tongjitu .lzrs .rs {
        float: left; }
      .my_food_coupon .tongjitu .lzrs .ls {
        width: 4px;
        border-right: 1px solid #eee;
        position: relative;
        margin-top: -1px;
        padding-left: 30px; }
        .my_food_coupon .tongjitu .lzrs .ls .nub {
          position: absolute;
          top: -5px;
          left: 0;
          height: 100%; }
          .my_food_coupon .tongjitu .lzrs .ls .nub em {
            display: block;
            text-align: center;
            text-align: top; }
          .my_food_coupon .tongjitu .lzrs .ls .nub .m1 {
            height: 90px; }
          .my_food_coupon .tongjitu .lzrs .ls .nub .m2 {
            height: 90px; }
          .my_food_coupon .tongjitu .lzrs .ls .nub .m3 {
            height: 60px; }
          .my_food_coupon .tongjitu .lzrs .ls .nub .m4 {
            height: 30px; }
        .my_food_coupon .tongjitu .lzrs .ls span {
          display: block;
          margin-bottom: 29px;
          height: 1px;
          width: 4px;
          background: #ccc; }
      .my_food_coupon .tongjitu .lzrs .zs {
        width: calc(100% - 50px);
        height: 100%;
        margin-left: 5px;
        border-bottom: 1px solid #eee; }
        .my_food_coupon .tongjitu .lzrs .zs dt {
          height: 29px;
          border-top: 1px solid #eee; }
      .my_food_coupon .tongjitu .lzrs .rs {
        position: absolute;
        width: calc(100% - 40px);
        height: 100%;
        top: 0;
        z-index: 5;
        left: 35px;
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        align-tems: center;
        -ms-flex-pack: distribute;
        justify-content: space-around;
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg); }
        .my_food_coupon .tongjitu .lzrs .rs .yx {
          top: -36px; }
          .my_food_coupon .tongjitu .lzrs .rs .yx em {
            display: block;
            white-space: nowrap; }
        .my_food_coupon .tongjitu .lzrs .rs .yx {
          text-align: center;
          font-size: 10px;
          position: absolute;
          -webkit-transform: translate(-50%, 0) rotate(180deg);
          transform: translate(-50%, 0) rotate(180deg);
          left: 50%; }
        .my_food_coupon .tongjitu .lzrs .rs dt {
          height: 100%;
          width: 20px;
          position: relative; }
        .my_food_coupon .tongjitu .lzrs .rs .k {
          position: relative; }
          .my_food_coupon .tongjitu .lzrs .rs .k .m {
            height: 100%;
            width: 100%;
            border-bottom-left-radius: 40px;
            border-bottom-right-radius: 40px;
            overflow: hidden;
            background: #f2f2f2; }
          .my_food_coupon .tongjitu .lzrs .rs .k p {
            position: absolute;
            bottom: -20px;
            left: 50%;
            -webkit-transform: translate(-50%, 0) rotate(180deg);
            transform: translate(-50%, 0) rotate(180deg);
            font-size: 11px;
            color: #999; }
          .my_food_coupon .tongjitu .lzrs .rs .k .sp {
            color: #05322d;
            font-weight: 700; }
          .my_food_coupon .tongjitu .lzrs .rs .k em {
            display: block;
            width: 100%; }
          .my_food_coupon .tongjitu .lzrs .rs .k .ys {
            background: #f76502; }
          .my_food_coupon .tongjitu .lzrs .rs .k .sel {
            background: -webkit-gradient(linear, left top, left bottom, from(#2bbf9d), to(rgba(255, 255, 255, 0)));
            background: linear-gradient(#2bbf9d, rgba(255, 255, 255, 0)); }
        .my_food_coupon .tongjitu .lzrs .rs .k1 {
          height: 15%;
          width: 100%; }
        .my_food_coupon .tongjitu .lzrs .rs .k2 {
          height: 25%;
          width: 100%; }
        .my_food_coupon .tongjitu .lzrs .rs .k3 {
          height: 30%;
          width: 100%; }
        .my_food_coupon .tongjitu .lzrs .rs .k4 {
          height: 36%;
          width: 100%; }
        .my_food_coupon .tongjitu .lzrs .rs .k5 {
          height: 50%;
          width: 100%; }
        .my_food_coupon .tongjitu .lzrs .rs .k6 {
          height: 70%;
          width: 100%; }
        .my_food_coupon .tongjitu .lzrs .rs .k7 {
          height: 92%;
          width: 100%; }
    .my_food_coupon .tongjitu .qyjs {
      font-size: 12px;
      color: #2bbf9d;
      text-align: center;
      padding: 15px 0;
      border-bottom: dotted 1px #2bbf9d; }
      .my_food_coupon .tongjitu .qyjs em {
        height: 7px;
        width: 7px;
        margin-right: 5px;
        border-radius: 50%;
        background: #2bbf9d; }
      .my_food_coupon .tongjitu .qyjs em, .my_food_coupon .tongjitu .qyjs b {
        display: inline-block;
        vertical-align: middle; }
    .my_food_coupon .tongjitu .qyjs2 {
      font-size: 12px;
      color: #2bbf9d;
      text-align: center;
      padding: 15px 0; }
      .my_food_coupon .tongjitu .qyjs2 em {
        height: 7px;
        width: 7px;
        margin-right: 5px;
        border-radius: 50%;
        background: #2bbf9d; }
      .my_food_coupon .tongjitu .qyjs2 em, .my_food_coupon .tongjitu .qyjs2 b {
        display: inline-block;
        vertical-align: middle; }
    .my_food_coupon .tongjitu .btns {
      padding: 15px 0;
      display: block;
      text-align: center;
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -moz-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -ms-flex-pack: distribute;
      justify-content: space-around; }
      .my_food_coupon .tongjitu .btns button {
        padding: 8px 20px;
        border-radius: 15px;
        font-size: 12px;
        color: #fff; }
      .my_food_coupon .tongjitu .btns button.lb {
        background: -webkit-gradient(linear, left top, left bottom, from(#f95730), to(#f76405));
        background: linear-gradient(to bottom, #f95730, #f76405);
        /* 标准的语法 */ }
      .my_food_coupon .tongjitu .btns button.rb {
        background: -webkit-gradient(linear, left top, left bottom, from(#2abf9f), to(#28bcc6));
        background: linear-gradient(to bottom, #2abf9f, #28bcc6);
        /* 标准的语法 */ }
    .btns_22 {
      padding: 15px 0;
      display: block;
      text-align: center;
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -moz-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -ms-flex-pack: distribute;
      justify-content: space-around;
      border-top: 1px solid #eee;
      margin-top: 25px; }
      .btns_22 button {
        padding: 8px 20px;
        border-radius: 15px;
        font-size: 12px;
        color: #fff; }
      .btns_22 button.lb {
        background: -webkit-gradient(linear, left top, left bottom, from(#f95730), to(#f76405));
        background: linear-gradient(to bottom, #f95730, #f76405);
        /* 标准的语法 */ }
      .btns_22 button.rb {
        background: -webkit-gradient(linear, left top, left bottom, from(#2abf9f), to(#28bcc6));
        background: linear-gradient(to bottom, #2abf9f, #28bcc6);
        /* 标准的语法 */ }
  .my_food_coupon .share_wx {
    text-align: center;
    overflow: hidden;
    position: relative;
    padding: 15px 0 10px; }
    .my_food_coupon .share_wx p {
      font-size: 12px;
      color: #666;
      text-align: justify;
      padding: 5px 0; }
    .my_food_coupon .share_wx .wx {
      padding-top: 10px; }
      .my_food_coupon .share_wx .wx .wx_01,
      .my_food_coupon .share_wx .wx .wx_02,
      .my_food_coupon .share_wx .wx .wx_03 {
        text-align: center; }
      .my_food_coupon .share_wx .wx .code {
        width: 140px;
        margin: 0 auto; }
      .my_food_coupon .share_wx .wx .wx_02 {
        font-size: 15px;
        font-weight: 700;
        color: #333;
        margin-top: 10px; }
      .my_food_coupon .share_wx .wx .btn {
        text-align: center;
        padding: 10px 0; }
        .my_food_coupon .share_wx .wx .btn button {
          background: #2bbf9d;
          color: #fff;
          height: 34px;
          border-radius: 4px;
          padding: 0 15px;
          font-size: 12px; }
          .my_food_coupon .share_wx .wx .btn button:active {
            background: #27af90; }

.my_invitation .share_banner .tit {
  padding: 15px 0;
  font-size: 16px; }

.my_invitation .share_banner .yqm_btn {
  margin-top: 10px;
  text-align: center; }
  .my_invitation .share_banner .yqm_btn button {
    background: #f4f4f4;
    font-size: 14px;
    padding: 5px 15px;
    border-radius: 50px;
    color: #666; }
    .my_invitation .share_banner .yqm_btn button span {
      vertical-align: -1px; }
    .my_invitation .share_banner .yqm_btn button:active {
      opacity: 0.8; }

.my_invitation .share_banner .tx {
  padding: 15px 12px;
  background: #b8b8b8;
  color: #fff; }
  .my_invitation .share_banner .tx span {
    padding-right: 4px; }

.my_invitation .share_banner .info {
  padding: 12px;
  background: #fff;
  position: relative; }
  .my_invitation .share_banner .info .ct {
    background: #f5f5f5;
    padding: 15px;
    border-radius: 2px;
    text-align: justify;
    color: #666;
    font-size: 13px;
    letter-spacing: 1px;
    line-height: 22px; }
  .my_invitation .share_banner .info .yqm {
    font-size: 16px;
    font-weight: 700;
    text-align: center; }

.my_invitation .share_banner .imgs_box {
  background: #fff;
  padding: 0 12px 20px 12px;
  margin-top: 10px; }
  .my_invitation .share_banner .imgs_box .imgs {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -moz-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between; }
    .my_invitation .share_banner .imgs_box .imgs li {
      width: 28vw;
      position: relative; }
      .my_invitation .share_banner .imgs_box .imgs li .oImg, .my_invitation .share_banner .imgs_box .imgs .trial_invite .info .ct .list li .user .font, .trial_invite .info .ct .list .my_invitation .share_banner .imgs_box .imgs li .user .font {
        height: 28vw;
        border-radius: 4px;
        overflow: hidden; }
      .my_invitation .share_banner .imgs_box .imgs li .btn button {
        width: 34px;
        height: 34px;
        margin: 0 auto;
        display: block;
        font-size: 20px;
        color: #666; }
    .my_invitation .share_banner .imgs_box .imgs li.sel .btn button {
      color: #2bbf9d; }

.icon_share {
  background: #fff;
  margin-top: 10px;
  padding: 12px; }
  .icon_share .btns {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: distribute;
    justify-content: space-around; }
    .icon_share .btns p {
      color: #666;
      margin-top: 8px; }
    .icon_share .btns .b {
      background: #f2f2f2;
      display: block;
      width: 44px;
      height: 44px;
      border-radius: 50%;
      margin: 0 auto; }
      .icon_share .btns .b svg {
        width: 24px;
        height: 24px;
        margin-top: 10px; }

.show_pic_big {
  position: fixed;
  top: 50%;
  left: 0;
  width: 100%;
  z-index: 101;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%); }

.my_xj_wallet {
  padding: 12px; }
  .my_xj_wallet .head {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -moz-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 35px 20px;
    border-radius: 4px;
    color: #fff;
    background: -webkit-gradient(linear, left top, right top, from(#28bcc6), to(#2abfa1));
    background: linear-gradient(to right, #28bcc6, #2abfa1);
    position: relative; }
    .my_xj_wallet .head .icon {
      width: 24vw;
      position: absolute;
      top: 50%;
      right: 0;
      -webkit-transform: translate(0, -50%);
      transform: translate(0, -50%);
      z-index: 1; }
    .my_xj_wallet .head .r button {
      background: #f5f5f5;
      color: #333;
      height: 34px;
      padding: 0 15px;
      border-radius: 50px; }
      .my_xj_wallet .head .r button .font {
        color: #999; }
    .my_xj_wallet .head .l .name {
      font-size: 18px; }
      .my_xj_wallet .head .l .name .font {
        color: #fcac0b;
        -webkit-mask: -webkit-gradient(linear, right top, left top, from(#fcac0b), to(#fde07a));
        -webkit-mask: linear-gradient(to left, #fcac0b, #fde07a);
        font-size: 22px; }
    .my_xj_wallet .head .l .mun {
      font-size: 22px;
      font-weight: 700;
      margin-top: 10px; }
  .my_xj_wallet .list {
    margin-top: 20px; }
    .my_xj_wallet .list li {
      padding: 20px 0;
      position: relative;
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -moz-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: justify;
      -moz-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      border-radius: 4px;
      color: #666; }
      .my_xj_wallet .list li .l {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -moz-box-align: center;
        -ms-flex-align: center;
        align-items: center; }
        .my_xj_wallet .list li .l .font {
          font-size: 26px;
          vertical-align: middle;
          margin-right: 10px;
          height: 30px;
          width: 30px;
          line-height: 30px;
          text-align: center; }
      .my_xj_wallet .list li .r .font {
        color: #999; }
      .my_xj_wallet .list li:active {
        background: #f4f4f4; }

.sq_xj_tixian .accunt_icos {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -moz-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
  padding: 20px 12px;
  background: #fff; }
  .sq_xj_tixian .accunt_icos dt {
    width: calc(50% - 6px);
    padding: 20px 0;
    border-radius: 4px;
    -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1); }
    .sq_xj_tixian .accunt_icos dt:active {
      background: #f4f4f4; }
  .sq_xj_tixian .accunt_icos svg {
    width: 50px;
    height: 50px; }
  .sq_xj_tixian .accunt_icos button {
    height: 34px;
    padding: 0 20px;
    border-radius: 2px;
    color: #666;
    font-size: 20px; }
    .sq_xj_tixian .accunt_icos button.sel {
      color: #2bbf9d; }

.sq_xj_tixian .head {
  padding: 12px;
  background: #fff; }
  .sq_xj_tixian .head .card {
    border: 1px solid #eee;
    border-radius: 8px;
    padding: 30px 12px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -moz-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    background: #fff;
    -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    position: relative;
    z-index: 1; }
    .sq_xj_tixian .head .card .oImg, .sq_xj_tixian .head .card .trial_invite .info .ct .list li .user .font, .trial_invite .info .ct .list li .user .sq_xj_tixian .head .card .font {
      width: 50px;
      height: 50px; }
    .sq_xj_tixian .head .card .info {
      margin-left: 10px;
      -webkit-box-flex: 1;
      -moz-box-flex: 1;
      -ms-flex: 1;
      flex: 1; }
      .sq_xj_tixian .head .card .info .yh {
        font-weight: 700;
        font-size: 14px;
        margin-bottom: 5px; }
      .sq_xj_tixian .head .card .info .name {
        color: #333; }
      .sq_xj_tixian .head .card .info .nub {
        margin-top: 5px;
        font-size: 20px;
        color: #666; }
    .sq_xj_tixian .head .card .del {
      position: absolute;
      top: 12px;
      right: 12px;
      padding: 5px;
      font-size: 20px;
      color: #666; }
  .sq_xj_tixian .head .add_bank {
    margin-top: 25px;
    border-bottom: 1px solid #eee;
    font-size: 14px;
    color: #666;
    padding: 10px 0; }

.sq_xj_tixian .yue {
  border-bottom: 1px solid #eee;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 10px 12px;
  background: #fff;
  -webkit-box-pack: justify;
  -moz-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  color: #666; }
  .sq_xj_tixian .yue .r {
    color: #f23440; }

.sq_xj_tixian .list {
  background: #fff; }
  .sq_xj_tixian .list li {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -moz-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 0 12px;
    border-bottom: 1px solid #eee; }
    .sq_xj_tixian .list li .m {
      -webkit-box-flex: 1;
      -moz-box-flex: 1;
      -ms-flex: 1;
      flex: 1; }
      .sq_xj_tixian .list li .m input {
        width: 100%;
        height: 44px; }
      .sq_xj_tixian .list li .m input.t {
        text-align: right; }
    .sq_xj_tixian .list li .btn button {
      border-left: 1px solid #eee;
      padding: 0 20px; }
    .sq_xj_tixian .list li .btn button.sel {
      color: #2bbf9d; }

.sq_xj_tixian .zfb_accunt {
  padding: 12px;
  position: relative;
  background: #fff; }
  .sq_xj_tixian .zfb_accunt li {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 5px 0;
    font-size: 16px; }
    .sq_xj_tixian .zfb_accunt li em {
      margin-right: 10px; }

.xj_tixian_jilu .list {
  background: #fff; }
  .xj_tixian_jilu .list li {
    padding: 10px 12px;
    position: relative; }
    .xj_tixian_jilu .list li h3 {
      font-size: 16px;
      font-weight: 700;
      margin-bottom: 10px; }
    .xj_tixian_jilu .list li .info {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -moz-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: justify;
      -moz-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between; }
      .xj_tixian_jilu .list li .info .l {
        color: #666; }
      .xj_tixian_jilu .list li .info .r button {
        color: #fff;
        border-radius: 2px;
        margin-top: 6px;
        width: 60px;
        background: #666;
        padding: 3px 5px; }
      .xj_tixian_jilu .list li .info .r .green {
        background: #2bbf9d; }
      .xj_tixian_jilu .list li .info .r .red {
        background: #f23440; }
    .xj_tixian_jilu .list li .time_state {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -moz-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: justify;
      -moz-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      color: #999;
      font-size: 12px; }
      .xj_tixian_jilu .list li .time_state .stete {
        background: #666;
        color: #fff;
        padding: 3px 6px;
        border-radius: 2px;
        width: 40px;
        font-size: 12px;
        text-align: center; }
        .xj_tixian_jilu .list li .time_state .stete.sel {
          background: #2bbf9d; }
        .xj_tixian_jilu .list li .time_state .stete.r {
          background: #f23440; }
    .xj_tixian_jilu .list li .account_info {
      font-size: 15px;
      margin: 5px 0; }
      .xj_tixian_jilu .list li .account_info dt {
        margin: 0 0  5px 0; }
        .xj_tixian_jilu .list li .account_info dt b {
          color: #f23440;
          font-weight: 700;
          font-size: 18px; }
    .xj_tixian_jilu .list li .nt {
      font-size: 13px;
      color: #f23440; }

.add_card_info {
  padding: 12px 0; }
  .add_card_info .card_n {
    background: #fff; }
    .add_card_info .card_n input {
      height: 50px;
      background: #fff;
      padding: 0 12px; }
  .add_card_info .list {
    margin-top: 12px;
    background: #fff; }
    .add_card_info .list li {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -moz-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      height: 50px;
      line-height: 50px;
      padding: 0 12px;
      border-bottom: 1px solid #eee;
      position: relative; }
      .add_card_info .list li select {
        height: 50px;
        -webkit-box-flex: 1;
        -moz-box-flex: 1;
        -ms-flex: 1;
        flex: 1; }
      .add_card_info .list li .t {
        margin-right: 10px; }
      .add_card_info .list li .iconfont {
        position: absolute;
        top: 50%;
        right: 12px;
        -webkit-transform: translate(0, -50%);
        transform: translate(0, -50%);
        z-index: 1; }
  .add_card_info .notice_z {
    color: #f23440;
    padding: 5px 12px;
    font-size: 12px; }

.btn_sure {
  padding: 20px 12px; }
  .btn_sure button {
    width: 100%;
    height: 44px;
    font-size: 16px;
    color: #fff;
    background: #2bbf9d;
    border-radius: 4px; }
    .btn_sure button:active {
      background: #27af90; }
    .btn_sure button.red {
      background: #f23440; }
      .btn_sure button.red:active {
        background: #e80808; }

.auction_order .state {
  clear: both;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -moz-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 5px 0;
  color: #333; }
  .auction_order .state .z span {
    color: #2bbf9d; }
  .auction_order .state .r button {
    height: 26px;
    padding: 0 8px;
    border-radius: 2px; }
    .auction_order .state .r button.wuliu {
      border: 1px solid #2bbf9d;
      color: #2bbf9d; }
      .auction_order .state .r button.wuliu:active {
        background: #f4f4f4; }
    .auction_order .state .r button.sel {
      background: #2bbf9d;
      color: #fff; }
      .auction_order .state .r button.sel:active {
        background: #27af90; }

.trial_order .try_state {
  clear: both;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -moz-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 5px 0; }
  .trial_order .try_state .l .state span {
    font-weight: 700;
    margin-right: 5px; }
  .trial_order .try_state .l .tm {
    font-size: 12px;
    color: #999;
    margin-top: 5px; }
  .trial_order .try_state .r button {
    height: 26px;
    padding: 0 8px;
    border-radius: 2px; }
    .trial_order .try_state .r button.yao {
      border: 1px solid #2bbf9d;
      color: #2bbf9d;
      background: #d3f2ec; }
      .trial_order .try_state .r button.yao:active {
        background: #f4f4f4; }
    .trial_order .try_state .r button.sxk {
      border: 1px solid #2bbf9d;
      color: #2bbf9d; }
      .trial_order .try_state .r button.sxk:active {
        background: #f4f4f4; }
    .trial_order .try_state .r button.sel {
      background: #2bbf9d;
      color: #fff; }
      .trial_order .try_state .r button.sel:active {
        background: #27af90; }

.xj_wallet_exchange .xj {
  background: #fff; }
  .xj_wallet_exchange .xj .my,
  .xj_wallet_exchange .xj .dui {
    padding: 10px 12px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -moz-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between; }
    .xj_wallet_exchange .xj .my em,
    .xj_wallet_exchange .xj .dui em {
      color: #666; }
    .xj_wallet_exchange .xj .my .red,
    .xj_wallet_exchange .xj .dui .red {
      color: #d61a00;
      font-weight: 700; }

.xj_wallet_exchange .huan {
  background: #fff;
  margin-top: 10px;
  padding: 12px; }
  .xj_wallet_exchange .huan .name {
    padding-bottom: 12px;
    font-weight: 700;
    color: #333; }
  .xj_wallet_exchange .huan .nub {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -moz-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    border-bottom: 1px solid #eee;
    padding-bottom: 12px; }
    .xj_wallet_exchange .huan .nub input {
      font-size: 14px;
      height: 34px;
      width: 40%;
      -webkit-box-flex: 1;
      -moz-box-flex: 1;
      -ms-flex: 1;
      flex: 1; }
    .xj_wallet_exchange .huan .nub b {
      padding: 0 15px; }
    .xj_wallet_exchange .huan .nub em {
      font-weight: 700; }
    .xj_wallet_exchange .huan .nub .red {
      color: #d61a00;
      font-size: 16px;
      text-align: right; }

.xj_wallet_exchange .notices {
  padding: 12px;
  background: #fff;
  margin-top: 10px; }
  .xj_wallet_exchange .notices .name {
    margin-bottom: 12px;
    font-weight: 700;
    color: #333; }
  .xj_wallet_exchange .notices .ct {
    text-align: justify;
    color: #666;
    font-size: 12px;
    line-height: 20px; }
    .xj_wallet_exchange .notices .ct p {
      margin: 4px 0;
      line-height: 20px; }

.stq_water_purifier .head {
  position: relative;
  overflow: hidden; }
  .stq_water_purifier .head .sb_box {
    padding: 4px;
    border: 2px solid #45c3a6;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    z-index: 1; }
    .stq_water_purifier .head .sb_box .pic_sb {
      width: 70px;
      height: 70px;
      border-radius: 50%;
      overflow: hidden; }
    .stq_water_purifier .head .sb_box .water_animate {
      position: absolute;
      width: 70px;
      height: 70px;
      left: 50%;
      top: 50%;
      z-index: 1;
      -webkit-transform: translate(-50%, -50%) rotate(0deg);
      transform: translate(-50%, -50%) rotate(0deg);
      border-radius: 50%;
      overflow: hidden; }
      .stq_water_purifier .head .sb_box .water_animate .mb {
        width: 70px;
        height: 70px;
        overflow: hidden;
        position: absolute;
        bottom: 0;
        left: 0;
        border-radius: 50%;
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg); }
        .stq_water_purifier .head .sb_box .water_animate .mb img {
          position: absolute;
          left: 0;
          bottom: 0; }
      .stq_water_purifier .head .sb_box .water_animate .txt {
        font-size: 11px;
        color: #fff;
        text-align: center;
        margin: 15px auto 0;
        text-shadow: 0 0 5px #00a72f; }
      .stq_water_purifier .head .sb_box .water_animate .wave-bot {
        width: 594px;
        height: 34px;
        opacity: 0.3;
        -webkit-animation: water1 15s linear infinite;
        animation: water1 15s linear infinite;
        z-index: 8; }
      .stq_water_purifier .head .sb_box .water_animate .wave-mid {
        width: 692px;
        height: 30px;
        opacity: 0.25;
        -webkit-animation: water2 11s linear infinite;
        animation: water2 11s linear infinite;
        z-index: 7; }
      .stq_water_purifier .head .sb_box .water_animate .wave-top {
        width: 1010px;
        height: 30px;
        opacity: 0.2;
        -webkit-animation: water3 8s linear infinite;
        animation: water3 8s linear infinite;
        z-index: 6; }

@-webkit-keyframes water1 {
  0% {
    margin-left: 0;
    height: 34px; }
  50% {
    margin-left: -287px;
    height: 20px; }
  100% {
    margin-left: 0;
    height: 34px; } }

@keyframes water1 {
  0% {
    margin-left: 0;
    height: 34px; }
  50% {
    margin-left: -287px;
    height: 20px; }
  100% {
    margin-left: 0;
    height: 34px; } }

@-webkit-keyframes water2 {
  0% {
    margin-left: 0;
    height: 30px; }
  50% {
    margin-left: -348px;
    height: 18px; }
  100% {
    margin-left: 0;
    height: 30px; } }

@keyframes water2 {
  0% {
    margin-left: 0;
    height: 30px; }
  50% {
    margin-left: -348px;
    height: 18px; }
  100% {
    margin-left: 0;
    height: 30px; } }

@-webkit-keyframes water3 {
  0% {
    margin-left: 0;
    height: 30px; }
  50% {
    margin-left: -505px;
    height: 18px; }
  100% {
    margin-left: 0;
    height: 30px; } }

@keyframes water3 {
  0% {
    margin-left: 0;
    height: 30px; }
  50% {
    margin-left: -505px;
    height: 18px; }
  100% {
    margin-left: 0;
    height: 30px; } }
  .stq_water_purifier .head .shuidi {
    width: 100%;
    height: 100%;
    z-index: 33;
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden; }
    .stq_water_purifier .head .shuidi li {
      -webkit-transition: 1s;
      transition: 1s;
      width: 40px;
      text-align: center;
      color: #fff;
      position: absolute;
      -webkit-animation-duration: 0.5s;
      animation-duration: 0.5s;
      -webkit-animation-iteration-count: infinite;
      animation-iteration-count: infinite;
      -webkit-animation-timing-function: ease-in-out;
      animation-timing-function: ease-in-out;
      -webkit-animation-direction: alternate;
      animation-direction: alternate; }
      .stq_water_purifier .head .shuidi li p {
        margin-top: 3px; }
    .stq_water_purifier .head .shuidi li.an_m {
      -webkit-animation-name: sd_01;
      animation-name: sd_01; }
    .stq_water_purifier .head .shuidi li.a_01 {
      -webkit-animation-delay: 0s;
      animation-delay: 0s;
      -webkit-transform: scale(1, 1);
      transform: scale(1, 1); }
    .stq_water_purifier .head .shuidi li.a_02 {
      -webkit-animation-delay: 0.1s;
      animation-delay: 0.1s;
      -webkit-transform: scale(1, 1);
      transform: scale(1, 1); }
    .stq_water_purifier .head .shuidi li.a_03 {
      -webkit-animation-delay: 0.2s;
      animation-delay: 0.2s;
      -webkit-transform: scale(1, 1);
      transform: scale(1, 1); }
    .stq_water_purifier .head .shuidi li.a_04 {
      -webkit-animation-delay: 0.3s;
      animation-delay: 0.3s;
      -webkit-transform: scale(1, 1);
      transform: scale(1, 1); }
    .stq_water_purifier .head .shuidi li.a_05 {
      -webkit-animation-delay: 0.5s;
      animation-delay: 0.5s;
      -webkit-transform: scale(1, 1);
      transform: scale(1, 1); }
    .stq_water_purifier .head .shuidi .ps_01 {
      left: 7.733333%;
      top: 11.11111%; }
    .stq_water_purifier .head .shuidi .ps_02 {
      left: 5.733333%;
      top: 42.06349%; }
    .stq_water_purifier .head .shuidi .ps_03 {
      left: 21.06666%;
      top: 19.047619%; }
    .stq_water_purifier .head .shuidi .ps_04 {
      left: 9.33333%;
      top: 76.2698%; }
    .stq_water_purifier .head .shuidi .ps_05 {
      left: 24%;
      top: 54.079365%; }
    .stq_water_purifier .head .shuidi .ps_06 {
      left: 45.3333%;
      top: 12.06349%; }
    .stq_water_purifier .head .shuidi .ps_07 {
      left: 44.266667%;
      top: 76.7936508%; }
    .stq_water_purifier .head .shuidi .ps_08 {
      left: 59.33333%;
      top: 65.8730159%; }
    .stq_water_purifier .head .shuidi .ps_09 {
      left: 67.73333%;
      top: 33.33333%; }
    .stq_water_purifier .head .shuidi .ps_10 {
      left: 77.86666%;
      top: 12.6984127%; }
    .stq_water_purifier .head .shuidi .ps_11 {
      left: 82.333%;
      top: 46.984127%; }
    .stq_water_purifier .head .shuidi .ps_12 {
      left: 80.3333%;
      top: 72.55555%; }
    .stq_water_purifier .head .shuidi li.shouHZ {
      top: 50%;
      left: 50%;
      margin: -30px 0 0 -20px;
      opacity: 0;
      -webkit-transform: scale(0, 0);
      transform: scale(0, 0); }

.stq_water_purifier .lab {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  text-align: center;
  position: relative; }
  .stq_water_purifier .lab dt {
    height: 50px;
    line-height: 50px;
    color: #fff;
    background: #2bbf9d;
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -ms-flex: 1;
    flex: 1; }
    .stq_water_purifier .lab dt em {
      font-size: 24px;
      vertical-align: -2px; }

.stq_water_purifier .lab:after {
  height: 100%;
  width: 1px;
  display: block;
  position: absolute;
  top: 0;
  left: 50%;
  content: '';
  background: #aae3d5; }

.stq_water_purifier .notice {
  background: #fff;
  height: 44px;
  line-height: 44px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -moz-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0 0 0 12px;
  font-size: 12px;
  color: #666;
  -webkit-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1); }
  .stq_water_purifier .notice dt .font {
    vertical-align: -2px;
    margin-right: 4px;
    color: #2bbf9d; }
  .stq_water_purifier .notice dt button {
    padding: 12px;
    font-size: 12px; }

.stq_water_purifier .canshu {
  padding: 12px; }
  .stq_water_purifier .canshu .module {
    background: #fff;
    border-radius: 5px;
    padding: 20px;
    margin-bottom: 12px;
    -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1); }
    .stq_water_purifier .canshu .module h3 {
      font-size: 18px;
      font-weight: 700;
      height: 44px;
      line-height: 44px; }
    .stq_water_purifier .canshu .module .lvxin {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: justify;
      -moz-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between; }
      .stq_water_purifier .canshu .module .lvxin .nub {
        height: 213px;
        width: 60px;
        color: #666; }
        .stq_water_purifier .canshu .module .lvxin .nub .h {
          height: 40px; }
      .stq_water_purifier .canshu .module .lvxin .data_box {
        -webkit-box-flex: 1;
        -moz-box-flex: 1;
        -ms-flex: 1;
        flex: 1;
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-pack: distribute;
        justify-content: space-around; }
        .stq_water_purifier .canshu .module .lvxin .data_box li {
          width: 44px; }
          .stq_water_purifier .canshu .module .lvxin .data_box li .d {
            height: 213px;
            background: #eee;
            -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
            -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.1);
            box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.1); }
            .stq_water_purifier .canshu .module .lvxin .data_box li .d .data {
              -webkit-transform: rotate(180deg);
              transform: rotate(180deg);
              text-align: center;
              -webkit-transition: 1s;
              transition: 1s;
              background: #2bbf9d;
              color: #fff;
              height: 0%;
              overflow: hidden; }
              .stq_water_purifier .canshu .module .lvxin .data_box li .d .data em {
                font-size: 12px;
                height: 22px;
                line-height: 22px; }
          .stq_water_purifier .canshu .module .lvxin .data_box li p {
            color: #666;
            text-align: center;
            margin-top: 3px; }
    .stq_water_purifier .canshu .module .tds {
      margin-bottom: 15px; }
      .stq_water_purifier .canshu .module .tds li .n {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
        -moz-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        -webkit-box-align: center;
        -moz-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        margin: 20px 0 5px; }
      .stq_water_purifier .canshu .module .tds li .d {
        height: 8px;
        border-radius: 5px;
        background: #eee;
        overflow: hidden;
        -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.1);
        box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.1); }
        .stq_water_purifier .canshu .module .tds li .d .data {
          width: 0px;
          overflow: hidden;
          background: #2bbf9d;
          height: 8px;
          -webkit-transition: 1s;
          transition: 1s;
          border-radius: 5px; }
    .stq_water_purifier .canshu .module .timer_yxq .t {
      font-weight: 700; }
      .stq_water_purifier .canshu .module .timer_yxq .t em {
        color: #2bbf9d;
        font-size: 18px;
        padding: 0 5px; }
    .stq_water_purifier .canshu .module .timer_yxq .bm {
      font-size: 12px;
      color: #666;
      margin: 5px 0; }
    .stq_water_purifier .canshu .module .timer_yxq .btn {
      text-align: center; }
      .stq_water_purifier .canshu .module .timer_yxq .btn button {
        padding: 10px 0;
        color: #2bbf9d;
        font-size: 16px;
        text-decoration: underline; }

.stq_water_purifier .xuyue_btn button {
  height: 44px;
  width: 100%;
  background: #2bbf9d;
  color: #fff;
  font-size: 16px; }

@-webkit-keyframes sd_01 {
  0% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0); }
  100% {
    -webkit-transform: translate(0, 3px);
    transform: translate(0, 3px); } }

@keyframes sd_01 {
  0% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0); }
  100% {
    -webkit-transform: translate(0, 3px);
    transform: translate(0, 3px); } }

.notePo {
  border-radius: 5px;
  position: fixed;
  width: 80%;
  background: #fff;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 222;
  overflow: hidden;
  text-align: center; }
  .notePo .pm2 {
    padding: 30px;
    font-size: 16px;
    line-height: 26px; }
  .notePo h4 {
    height: 40px;
    line-height: 40px;
    text-align: center;
    font-size: 14px;
    position: relative;
    border-bottom: 1px solid #eee; }
    .notePo h4 button {
      color: #999;
      width: 40px;
      height: 40px;
      font-size: 16px;
      float: right;
      position: absolute;
      right: 0;
      top: 0;
      z-index: 1; }
    .notePo h4 button:active {
      background: #eee; }
  .notePo p {
    padding: 15px;
    color: #666;
    line-height: 20px;
    font-size: 13px;
    letter-spacing: 0.5px; }
  .notePo .tj {
    text-align: justify; }
  .notePo dl {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center; }
    .notePo dl dt {
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      font-size: 12px;
      border-top: 1px solid #eee;
      height: 44px;
      line-height: 44px;
      color: #666;
      -webkit-box-flex: 1;
      -moz-box-flex: 1;
      -ms-flex: 1;
      flex: 1; }
    .notePo dl dt:nth-of-type(2) {
      border-left: 1px solid #eee; }
    .notePo dl dt:active {
      background: #eee; }
  .notePo .sure {
    color: #2bbf9d; }
  .notePo .btns button {
    background: #2bbf9d;
    width: 100%;
    height: 44px;
    color: #fff;
    font-size: 14px;
    display: block;
    position: relative; }
  .notePo .btns button:after {
    position: absolute;
    display: block;
    height: 1px;
    width: 100%;
    top: 0;
    left: 0;
    content: '';
    z-index: 5;
    background: #fff;
    -webkit-transform: scale(1, 0.5);
    transform: scale(1, 0.5); }

.address_list {
  width: calc(100% - 24px);
  margin: 12px auto 20px; }
  .address_list .list li {
    margin-bottom: 10px;
    background: #fff;
    padding: 12px 20px;
    border-radius: 5px;
    -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1); }
    .address_list .list li .down,
    .address_list .list li .up {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: flex; }
      .address_list .list li .down i.font,
      .address_list .list li .up i.font {
        color: #2bbf9d;
        font-size: 18px;
        margin-top: 5px;
        margin-right: 5px; }
      .address_list .list li .down .name,
      .address_list .list li .up .name {
        height: 30px;
        line-height: 30px;
        font-size: 16px;
        font-weight: 700; }
      .address_list .list li .down p,
      .address_list .list li .up p {
        font-size: 14px;
        color: #666; }
    .address_list .list li .moreSpan .taw {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: flex; }
    .address_list .list li .up {
      padding: 10px 0; }
    .address_list .list li .down {
      padding: 10px 0;
      -webkit-box-pack: justify;
      -moz-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      border-top: 1px solid #eee;
      -webkit-box-align: center;
      -moz-box-align: center;
      -ms-flex-align: center;
      align-items: center; }
      .address_list .list li .down button {
        padding: 3px 5px;
        color: #666; }
        .address_list .list li .down button span.font {
          font-size: 16px;
          vertical-align: middle; }
        .address_list .list li .down button em {
          font-size: 12px;
          vertical-align: middle; }
      .address_list .list li .down > button.sel span.font {
        color: #2bbf9d; }
      .address_list .list li .down .btns {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -moz-box-align: center;
        -ms-flex-align: center;
        align-items: center; }
        .address_list .list li .down .btns button {
          margin-left: 10px; }
  .address_list .addBtn {
    text-align: center; }
    .address_list .addBtn button {
      width: 100%;
      height: 50px;
      background: #fff;
      border-radius: 4px;
      -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.1); }
      .address_list .addBtn button:active {
        background: #f4f4f4; }

.address_add {
  width: calc(100% - 24px);
  margin: 12px auto 20px;
  border-radius: 5px;
  background: #fff;
  padding: 12px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2); }
  .address_add .list li {
    padding: 15px 0;
    border-bottom: 1px solid #eee;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    color: #000; }
    .address_add .list li input {
      -webkit-box-flex: 1;
      -moz-box-flex: 1;
      -ms-flex: 1;
      flex: 1;
      font-size: 14px;
      height: 30px;
      text-align: left; }
    .address_add .list li button {
      -webkit-box-flex: 1;
      -moz-box-flex: 1;
      -ms-flex: 1;
      flex: 1;
      font-size: 14px;
      height: 30px;
      text-align: left;
      width: calc(100% - 70px); }
    .address_add .list li button.sel {
      color: #757575; }
    .address_add .list li label {
      padding-right: 10px;
      width: 60px;
      color: #666; }
    .address_add .list li select {
      max-width: 80px;
      height: 30px;
      margin-right: 10px;
      padding-right: 10px; }
    .address_add .list li .font {
      color: #999; }
  .address_add .textarea {
    -webkit-box-align: start !important;
    -moz-box-align: start !important;
    -ms-flex-align: start !important;
    align-items: flex-start !important; }
    .address_add .textarea textarea {
      height: 50px;
      -webkit-box-flex: 1;
      -moz-box-flex: 1;
      -ms-flex: 1;
      flex: 1;
      width: calc(100% - 70px); }
  .address_add .btn {
    text-align: center;
    margin: 40px auto; }
    .address_add .btn button {
      width: 70%;
      border-radius: 50px;
      background: #2bbf9d;
      color: #fff;
      height: 40px; }

.selAddress {
  position: fixed;
  width: 100%;
  z-index: 110;
  background: #fff;
  left: 0;
  bottom: 0;
  -webkit-transform: translate(0, 100%, 0);
  transform: translate(0, 100%, 0);
  -webkit-transition: 0.5s;
  transition: 0.5s; }
  .selAddress h3 {
    height: 40px;
    border-bottom: 1px solid #eee;
    line-height: 40px;
    padding: 0 0 0 12px; }
    .selAddress h3 button {
      height: 40px;
      width: 40px;
      text-align: center;
      line-height: 40px;
      float: right; }
    .selAddress h3 button:active {
      background: #f2f2f2; }
  .selAddress .lab {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    border-bottom: 1px solid #eee; }
    .selAddress .lab button {
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 90px;
      height: 40px;
      font-size: 14px;
      position: relative;
      margin: 0 12px;
      overflow: hidden; }
    .selAddress .lab button.sel {
      color: #2bbf9d; }
    .selAddress .lab button.sel:after {
      width: 100%;
      height: 1px;
      background: #2bbf9d;
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 1;
      content: '';
      display: block; }
  .selAddress .list li {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    width: 100%;
    height: 70vh; }
    .selAddress .list li em {
      height: 40px;
      line-height: 40px;
      display: block;
      border-bottom: 1px solid #eee;
      padding: 0 12px; }
    .selAddress .list li em:active {
      background: #f2f2f2; }
    .selAddress .list li em.sel {
      color: #2bbf9d; }

.news {
  background: white;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  overflow: hidden;
  width: 100%;
  z-index: 5;
  margin-top: 10px;
  position: relative; }
  .news:active {
    background: #f4f4f4; }
  .news .txt {
    padding: 0 10px;
    color: #666; }
    .news .txt em.font {
      color: #2bbf9d;
      vertical-align: middle; }
    .news .txt .lot_ico {
      height: 26px;
      text-align: center;
      line-height: 26px;
      font-size: 24px;
      color: #2bbf9d;
      border-radius: 50%;
      width: 26px; }
  .news .go_r {
    position: absolute;
    top: 50%;
    right: 12px;
    color: #666;
    -webkit-transform: translate(0, -50%);
    transform: translate(0, -50%); }
  .news .info {
    height: 44px;
    overflow: hidden;
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    color: #666; }
    .news .info .newBox {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
      -webkit-transition: 1s;
      transition: 1s;
      padding-right: 50px;
      position: relative; }
      .news .info .newBox .list dt {
        display: block;
        height: 44px;
        line-height: 44px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        font-size: 12px;
        width: 100%;
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -moz-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: justify;
        -moz-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between; }

.rural_header {
  position: fixed;
  top: 0;
  left: 0;
  height: 44px;
  line-height: 44px;
  width: 100%;
  background: #fff;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0 12px;
  font-size: 18px;
  font-weight: 700;
  z-index: 77; }

.three_rural_index {
  padding: 0 0 10px 0; }
  .three_rural_index .news_list {
    position: relative; }
  .three_rural_index .qk_progress {
    padding: 10px 12px; }
    .three_rural_index .qk_progress .info {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -moz-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: justify;
      -moz-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      background: #f9f9f9;
      padding: 7px;
      border-radius: 4px; }
      .three_rural_index .qk_progress .info .oImg, .three_rural_index .qk_progress .trial_invite .info .ct .list li .user .font, .trial_invite .three_rural_index .qk_progress .info .ct .list li .user .font {
        border-radius: 4px;
        width: 15vw;
        height: 15vw;
        overflow: hidden;
        background: -webkit-gradient(linear, left top, left bottom, from(#2cbf9f), to(#27bdc7));
        background: linear-gradient(#2cbf9f, #27bdc7); }
      .three_rural_index .qk_progress .info .progress {
        -webkit-box-flex: 1;
        -moz-box-flex: 1;
        -ms-flex: 1;
        flex: 1;
        margin-left: 10px; }
        .three_rural_index .qk_progress .info .progress .name {
          position: relative;
          padding: 10px 0; }
          .three_rural_index .qk_progress .info .progress .name em {
            background: #fff;
            padding: 2px 5px;
            border-radius: 2px;
            color: #f23440;
            font-size: 12px;
            position: absolute;
            top: 50%;
            left: 50%;
            -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
            z-index: 1; }
          .three_rural_index .qk_progress .info .progress .name .font {
            position: absolute;
            top: 50%;
            right: 0;
            -webkit-transform: translate(0, -50%);
            transform: translate(0, -50%);
            z-index: 1;
            font-size: 12px;
            padding: 2px 5px;
            color: #2bbf9d;
            border-radius: 2px; }
            .three_rural_index .qk_progress .info .progress .name .font:active {
              background: #f4f4f4; }
        .three_rural_index .qk_progress .info .progress .jd {
          background: #fff;
          border-radius: 10px;
          padding: 2px 4px;
          margin: 8px 0; }
          .three_rural_index .qk_progress .info .progress .jd em {
            display: block;
            border-radius: 10px;
            background: #2bbf9d;
            height: 4px; }
        .three_rural_index .qk_progress .info .progress .num {
          display: -webkit-box;
          display: -moz-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -moz-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          -webkit-box-pack: justify;
          -moz-box-pack: justify;
          -ms-flex-pack: justify;
          justify-content: space-between;
          font-size: 12px;
          color: #666; }
          .three_rural_index .qk_progress .info .progress .num .sel {
            color: #2bbf9d; }
  .three_rural_index .gather {
    padding: 0 12px;
    overflow: hidden; }
    .three_rural_index .gather .tit_pic {
      margin: 10px 0; }
    .three_rural_index .gather .show_pro {
      overflow-y: hidden;
      overflow-x: scroll;
      padding-bottom: 10px;
      display: -webkit-box;
      white-space: nowrap;
      font-size: 0; }
      .three_rural_index .gather .show_pro li {
        width: calc(50vw - 17px);
        display: inline-block; }
        .three_rural_index .gather .show_pro li:not(:nth-last-of-type(1)) {
          margin-right: 10px; }
        .three_rural_index .gather .show_pro li .price {
          margin: 7px 0;
          overflow: hidden;
          width: 100%; }
          .three_rural_index .gather .show_pro li .price span, .three_rural_index .gather .show_pro li .price em {
            display: block;
            width: 50%;
            float: left;
            font-size: 11px;
            padding: 3px 5px;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box; }
          .three_rural_index .gather .show_pro li .price span {
            background: #000;
            color: #fff; }
          .three_rural_index .gather .show_pro li .price em {
            background: #f23440;
            color: #fff;
            text-align: right; }
  .three_rural_index .user_gather {
    overflow: hidden;
    position: relative;
    padding: 15px 0 0; }
    .three_rural_index .user_gather:before {
      height: 80%;
      width: 100%;
      position: absolute;
      content: "";
      display: block;
      background: #ffdd2b;
      z-index: -1;
      left: 0;
      top: 0; }
    .three_rural_index .user_gather h3 {
      font-size: 16px;
      font-weight: 700;
      padding: 0px 12px;
      position: relative; }
      .three_rural_index .user_gather h3 button {
        position: absolute;
        top: 50%;
        -webkit-transform: translate(0, -50%);
        transform: translate(0, -50%);
        border-radius: 2px;
        right: 7px;
        font-size: 12px;
        color: #666;
        padding: 3px 5px; }
        .three_rural_index .user_gather h3 button:active {
          background: #f4f4f4; }
    .three_rural_index .user_gather .go_ther {
      padding: 10px 12px;
      background: #ffdd2b; }
      .three_rural_index .user_gather .go_ther .show_pic {
        border-radius: 4px;
        overflow: hidden;
        position: relative; }
        .three_rural_index .user_gather .go_ther .show_pic em {
          background: #f23440;
          color: #fff;
          position: absolute;
          bottom: 0;
          left: 0;
          z-index: 1;
          padding: 3px 6px;
          font-size: 12px; }
      .three_rural_index .user_gather .go_ther .num {
        text-align: right;
        font-size: 12px;
        padding: 3px 0; }
        .three_rural_index .user_gather .go_ther .num span {
          color: #f23440; }
      .three_rural_index .user_gather .go_ther .name {
        font-size: 14px;
        font-weight: 700; }
      .three_rural_index .user_gather .go_ther .ptx {
        margin: 3px 0;
        font-size: 12px;
        color: #666; }
      .three_rural_index .user_gather .go_ther .price {
        font-size: 18px;
        color: #666;
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -moz-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: justify;
        -moz-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        padding: 5px 0; }
        .three_rural_index .user_gather .go_ther .price .l span {
          font-size: 16px;
          font-weight: 700;
          color: #f23440; }
        .three_rural_index .user_gather .go_ther .price .l s {
          font-size: 11px;
          margin-left: 5px; }
        .three_rural_index .user_gather .go_ther .price .r button {
          height: 30px;
          min-width: 60px;
          padding: 0 10px;
          border-radius: 4px;
          color: #fff;
          background: #f23440; }
          .three_rural_index .user_gather .go_ther .price .r button:active {
            background: #de2f3a; }
  .three_rural_index .go_will {
    overflow: hidden;
    margin-top: 10px; }
    .three_rural_index .go_will .list {
      display: -webkit-box;
      overflow-x: scroll;
      overflow-y: hidden;
      white-space: nowrap;
      -webkit-overflow-scrolling: touch;
      font-size: 0;
      padding: 10px 0; }
      .three_rural_index .go_will .list li {
        width: 36vw;
        display: inline-block;
        margin-left: 10px;
        -webkit-box-shadow: 0 0 3px rgba(0, 0, 0, 0.1);
        box-shadow: 0 0 3px rgba(0, 0, 0, 0.1); }
        .three_rural_index .go_will .list li:nth-last-of-type(1) {
          margin-right: 10px; }
        .three_rural_index .go_will .list li .info {
          padding: 5px; }
          .three_rural_index .go_will .list li .info .name {
            font-size: 14px;
            font-weight: 700;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis; }
          .three_rural_index .go_will .list li .info p {
            font-size: 14px;
            color: #f23440; }
            .three_rural_index .go_will .list li .info p s {
              font-size: 12px;
              color: #999; }
  .three_rural_index .pt_module {
    padding: 0 12px;
    margin-top: 12px; }
    .three_rural_index .pt_module h3 {
      font-size: 16px;
      font-weight: 700;
      position: relative;
      margin-bottom: 10px; }
      .three_rural_index .pt_module h3 button {
        position: absolute;
        top: 50%;
        -webkit-transform: translate(0, -50%);
        transform: translate(0, -50%);
        border-radius: 2px;
        right: 0;
        font-size: 12px;
        color: #666;
        padding: 3px 0; }
        .three_rural_index .pt_module h3 button:active {
          background: #f4f4f4; }
    .three_rural_index .pt_module .list {
      overflow: hidden;
      padding-bottom: 5px; }
      .three_rural_index .pt_module .list li {
        width: calc(50% - 5px);
        float: left;
        margin-top: 10px; }
        .three_rural_index .pt_module .list li:nth-of-type(1), .three_rural_index .pt_module .list li:nth-of-type(2) {
          margin-top: 0; }
        .three_rural_index .pt_module .list li:nth-of-type(2n) {
          float: right; }
        .three_rural_index .pt_module .list li .oImg, .three_rural_index .pt_module .trial_invite .info .ct .list li .user .font, .trial_invite .info .ct .three_rural_index .pt_module .list li .user .font {
          position: relative; }
          .three_rural_index .pt_module .list li .oImg em, .three_rural_index .pt_module .trial_invite .info .ct .list li .user .font em, .trial_invite .info .ct .three_rural_index .pt_module .list li .user .font em {
            position: absolute;
            bottom: 0;
            left: 0;
            z-index: 1;
            background: #f23440;
            color: #fff;
            padding: 3px 5px;
            font-size: 12px; }
        .three_rural_index .pt_module .list li .name {
          font-size: 14px;
          font-weight: 700;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          margin: 5px 0; }
        .three_rural_index .pt_module .list li .price {
          color: #f23440; }
          .three_rural_index .pt_module .list li .price s {
            color: #999;
            font-size: 12px; }
        .three_rural_index .pt_module .list li .b {
          padding: 5px 0; }
          .three_rural_index .pt_module .list li .b em {
            padding: 2px 5px;
            border: 1px solid #f23440;
            color: #f23440;
            font-size: 12px;
            background: #feeaec; }

.name_tit_ptx {
  padding: 15px 10px;
  background: #f5f5f5;
  border-radius: 4px;
  letter-spacing: 1px;
  font-size: 14px;
  line-height: 20px;
  text-align: justify;
  margin-bottom: 15px; }

.gather_pro_will {
  padding: 12px; }
  .gather_pro_will .list li {
    padding: 10px;
    background: #fff;
    -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    margin-bottom: 15px;
    border-radius: 8px; }
    .gather_pro_will .list li .price {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -moz-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: justify;
      -moz-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between; }
    .gather_pro_will .list li .name {
      font-size: 14px;
      font-weight: 700;
      margin: 5px 0; }
    .gather_pro_will .list li .ptx {
      font-size: 12px;
      letter-spacing: 0.5px;
      color: #666; }
    .gather_pro_will .list li .will_price {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -moz-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: justify;
      -moz-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      font-size: 12px; }
      .gather_pro_will .list li .will_price .l span {
        color: #f23440;
        font-size: 14px; }
      .gather_pro_will .list li .will_price .r em {
        color: #2bbf9d; }
    .gather_pro_will .list li .btn {
      font-size: 12px;
      margin: 10px 0 15px;
      text-align: center; }
      .gather_pro_will .list li .btn button {
        background: #666;
        height: 30px;
        width: 80px;
        color: #fff;
        border-radius: 50px;
        margin: 0 15px; }
        .gather_pro_will .list li .btn button:active {
          opacity: 0.8; }
        .gather_pro_will .list li .btn button.suppot {
          background: #f23440; }
          .gather_pro_will .list li .btn button.suppot:active {
            background: #da303b; }
        .gather_pro_will .list li .btn button.sel {
          background: #2bbf9d; }
          .gather_pro_will .list li .btn button.sel:active {
            background: #27af90; }
    .gather_pro_will .list li .sh span {
      font-weight: 700; }
    .gather_pro_will .list li .sh,
    .gather_pro_will .list li .ck {
      font-size: 12px;
      color: #666; }
      .gather_pro_will .list li .sh span,
      .gather_pro_will .list li .ck span {
        font-size: 13px;
        color: #f23440; }
    .gather_pro_will .list li .ll_zc {
      margin: 20px 0 4px;
      font-size: 13px;
      text-align: center; }
      .gather_pro_will .list li .ll_zc em {
        color: #2bbf9d; }

.user_group {
  padding: 0px 0;
  overflow: hidden;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee; }
  .user_group li {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -moz-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 9px 0; }
    .user_group li .l {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -moz-box-align: center;
      -ms-flex-align: center;
      align-items: center; }
      .user_group li .l .oImg, .user_group .trial_invite .info .ct .list li .l .user .font, .trial_invite .info .ct .list .user_group li .l .user .font, .user_group .trial_invite .info .ct .list li .user .l .font, .trial_invite .info .ct .list .user_group li .user .l .font {
        width: 34px;
        height: 34px;
        border-radius: 50%;
        overflow: hidden; }
      .user_group li .l em {
        font-size: 14px;
        margin-left: 10px; }
    .user_group li .r {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -moz-box-align: center;
      -ms-flex-align: center;
      align-items: center; }
      .user_group li .r .pTm {
        font-size: 12px;
        margin-right: 10px; }
      .user_group li .r button {
        height: 30px;
        width: 60px;
        background: #2bbf9d;
        color: #fff;
        border-radius: 2px; }
        .user_group li .r button:active {
          background: #27af90; }

.nch_details .head {
  padding: 30px 12px 15px;
  background: #fff; }
  .nch_details .head .info {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -moz-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between; }
    .nch_details .head .info .l {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -moz-box-align: center;
      -ms-flex-align: center;
      align-items: center; }
      .nch_details .head .info .l .oImg, .nch_details .head .trial_invite .info .l .ct .list li .user .font, .trial_invite .nch_details .head .info .l .ct .list li .user .font, .nch_details .head .trial_invite .info .ct .list li .user .l .font, .trial_invite .nch_details .head .info .ct .list li .user .l .font {
        width: 54px;
        height: 54px;
        margin-right: 10px;
        border-radius: 50%;
        overflow: hidden; }
      .nch_details .head .info .l .name_id {
        -webkit-box-flex: 1;
        -moz-box-flex: 1;
        -ms-flex: 1;
        flex: 1; }
        .nch_details .head .info .l .name_id .name {
          font-size: 17px; }
        .nch_details .head .info .l .name_id .id {
          font-size: 13px;
          margin-top: 3px;
          color: #666; }
    .nch_details .head .info .r {
      padding-left: 10px; }
      .nch_details .head .info .r button {
        font-size: 12px;
        background: #2bbf9d;
        color: #fff;
        padding: 5px 10px;
        border-radius: 30px;
        white-space: nowrap; }
        .nch_details .head .info .r button .font {
          font-size: 12px;
          vertical-align: -1px; }

.nch_details .tianqi {
  background: #fff;
  padding: 12px;
  position: relative; }
  .nch_details .tianqi img {
    border-radius: 4px; }
  .nch_details .tianqi .info {
    position: absolute;
    top: 50%;
    width: 80%;
    left: 50%;
    -webkit-transform: translate(-50%, -80%);
    transform: translate(-50%, -80%);
    color: #fff;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -moz-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center; }
    .nch_details .tianqi .info dt .m {
      font-size: 24px; }
    .nch_details .tianqi .info .f {
      border-radius: 50%;
      text-align: center;
      width: 60px;
      height: 60px;
      position: relative; }
      .nch_details .tianqi .info .f .f_in {
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        z-index: 44;
        white-space: nowrap; }
  .nch_details .tianqi .address {
    width: 80%;
    left: 50%;
    position: absolute;
    -webkit-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
    bottom: 40px;
    color: #fff; }

.nch_details .content {
  padding: 20px 12px;
  margin-top: 12px;
  background: #fff; }
  .nch_details .content .t {
    font-size: 16px;
    height: 44px; }

.nch_details .tit {
  font-size: 16px;
  font-weight: 700;
  padding: 0 12px; }

.nch_details .xg_product {
  background: #fff;
  margin-top: 12px;
  padding: 10px 0; }
  .nch_details .xg_product .list {
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch; }
    .nch_details .xg_product .list li {
      display: inline-block;
      width: 160px;
      margin: 10px 0 10px 10px;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
      z-index: 1;
      padding: 10px;
      border-radius: 4px; }
      .nch_details .xg_product .list li:nth-last-of-type(1) {
        margin-right: 10px; }
      .nch_details .xg_product .list li img {
        -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
        border-radius: 4px; }
      .nch_details .xg_product .list li .name {
        font-size: 14px;
        font-weight: 700;
        margin: 8px 0 2px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis; }
      .nch_details .xg_product .list li .price {
        color: #dc2600;
        font-size: 13px; }
        .nch_details .xg_product .list li .price s {
          font-size: 12px; }
      .nch_details .xg_product .list li .sh {
        color: #999;
        margin-top: 2px;
        font-size: 12px; }

.nch_details .xg_zhiz {
  background: #fff;
  padding: 10px 0; }
  .nch_details .xg_zhiz .list {
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
    padding: 10px 12px 10px 10px; }
    .nch_details .xg_zhiz .list li {
      height: 120px;
      display: inline-block;
      margin-right: 10px;
      overflow: hidden;
      border-radius: 4px;
      position: relative;
      -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
      border: 1px solid #eee; }
      .nch_details .xg_zhiz .list li img {
        height: 100%;
        width: auto; }

.my_btn_can {
  position: fixed;
  width: 100%;
  height: 50px;
  left: 0;
  bottom: 0;
  z-index: 99;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex; }
  .my_btn_can button {
    height: 50px;
    width: 100%;
    color: #fff; }
  .my_btn_can button.btn1 {
    background: #ffc444; }
  .my_btn_can button.btn2 {
    background: #2bbf9d; }

.witness_record li {
  background: #fff;
  margin-bottom: 10px;
  padding: 12px;
  position: relative; }

.witness_record .no {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center; }
  .witness_record .no .oImg, .witness_record .no .trial_invite .info .ct .list li .user .font, .trial_invite .info .ct .list li .user .witness_record .no .font {
    width: 44px;
    height: 44px;
    border-radius: 50%;
    overflow: hidden; }
  .witness_record .no .name {
    margin-left: 10px;
    font-size: 15px;
    font-weight: 700; }
  .witness_record .no .time {
    margin-left: 10px;
    font-size: 12px;
    color: #666; }

.witness_record .hover .user {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center; }
  .witness_record .hover .user .oImg, .witness_record .hover .trial_invite .info .ct .list li .user .font, .trial_invite .info .ct .list li .witness_record .hover .user .font {
    width: 44px;
    height: 44px;
    border-radius: 50%;
    overflow: hidden; }
  .witness_record .hover .user .info {
    margin-left: 10px; }
    .witness_record .hover .user .info .name {
      font-size: 15px;
      font-weight: 700; }
      .witness_record .hover .user .info .name em {
        display: inline-block;
        padding: 5px 10px;
        border-radius: 20px;
        background: #f2f2f2;
        font-size: 12px;
        font-weight: 400;
        vertical-align: 1px;
        margin-left: 5px; }
      .witness_record .hover .user .info .name em.sh {
        color: #2bbf9d; }
      .witness_record .hover .user .info .name em.wl {
        color: #ffd059; }
    .witness_record .hover .user .info .time {
      font-size: 12px;
      color: #666; }

.witness_record .hover .imgs {
  overflow: hidden; }
  .witness_record .hover .imgs dt {
    width: 80px;
    height: 110px;
    margin-right: 10px;
    overflow: hidden;
    border-radius: 4px;
    float: left; }
    .witness_record .hover .imgs dt img {
      height: 100%; }

.witness_record .hover .ct {
  margin: 5px 0;
  font-size: 14px;
  font-weight: 700; }

.witness_record .hover .j {
  color: #999;
  margin: 4px 0;
  font-size: 12px;
  padding-bottom: 10px; }

.witness_record .hover .nz {
  background: #f2f2f2;
  padding: 10px;
  border-radius: 4px;
  margin-top: 10px; }
  .witness_record .hover .nz p {
    color: #666; }
    .witness_record .hover .nz p em {
      font-size: 14px;
      font-weight: 700;
      color: #333; }
  .witness_record .hover .nz .tm {
    text-align: right;
    color: #999;
    margin-top: 10px; }

.monitoring .labs {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -moz-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  position: relative;
  background: #fff; }
  .monitoring .labs button {
    height: 50px;
    font-size: 14px;
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    color: #666; }
  .monitoring .labs .sel {
    color: #2bbf9d; }

.monitoring .labs:after {
  width: 1px;
  height: 40px;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 55;
  content: '';
  display: block;
  position: absolute;
  background: #eee; }

.monitoring .ct {
  background: #fff; }
  .monitoring .ct textarea {
    width: 100%;
    padding: 12px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    height: 80px; }

.monitoring .sel_jz {
  background: #fff;
  margin-bottom: 12px;
  padding: 12px; }
  .monitoring .sel_jz .mk {
    padding: 20px 10px;
    background: #f2f2f2;
    border-radius: 4px; }
    .monitoring .sel_jz .mk .t {
      font-size: 14px;
      color: #333;
      font-weight: 700; }
    .monitoring .sel_jz .mk .list li {
      font-size: 12px;
      color: #666;
      font-size: 14px; }
  .monitoring .sel_jz .btns {
    text-align: center; }
    .monitoring .sel_jz .btns button {
      width: 100px;
      border-radius: 50px;
      height: 34px;
      background: #ccc;
      margin: 10px;
      color: #fff; }
    .monitoring .sel_jz .btns .sel {
      background: #2bbf9d; }

.monitoring .picture {
  padding: 20px 12px;
  background: #fff;
  overflow: hidden; }
  .monitoring .picture .info {
    background: #f4f5f9;
    height: 100px;
    width: 100px;
    position: relative;
    overflow: hidden;
    float: left;
    margin-right: 10px; }
    .monitoring .picture .info .del {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 4;
      width: 20px;
      height: 20px; }
    .monitoring .picture .info .cm {
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      z-index: 4;
      text-align: center;
      color: #666; }
      .monitoring .picture .info .cm .font {
        font-size: 20px; }
    .monitoring .picture .info input {
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 44;
      position: absolute;
      opacity: 0; }

.community_index h3 {
  font-size: 16px;
  font-weight: 700;
  position: relative; }
  .community_index h3 button {
    position: absolute;
    top: 50%;
    -webkit-transform: translate(0, -50%);
    transform: translate(0, -50%);
    border-radius: 2px;
    right: 0;
    font-size: 12px;
    color: #666;
    padding: 3px 0; }
    .community_index h3 button:active {
      background: #f4f4f4; }

.community_index .icons_list {
  overflow: hidden;
  text-align: center;
  padding: 0 12px; }
  .community_index .icons_list li {
    width: 25%;
    float: left;
    padding: 8px 0; }
    .community_index .icons_list li:active {
      background: #f4f4f4;
      border-radius: 4px; }
    .community_index .icons_list li .oImg, .community_index .icons_list .trial_invite .info .ct .list li .user .font, .trial_invite .info .ct .list .community_index .icons_list li .user .font {
      width: 10vw;
      height: 10vw;
      margin: 0 auto;
      border-radius: 50%;
      overflow: hidden;
      min-width: 40px;
      min-height: 40px; }
    .community_index .icons_list li p {
      height: 28px;
      line-height: 28px;
      margin-top: 3px; }

.community_index .sq_list {
  padding: 12px; }
  .community_index .sq_list .list {
    padding: 20px 0;
    position: relative; }
    .community_index .sq_list .list li {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: justify;
      -moz-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      -webkit-box-align: center;
      -moz-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      margin-bottom: 15px; }
      .community_index .sq_list .list li .info {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
        -moz-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        -webkit-box-align: center;
        -moz-box-align: center;
        -ms-flex-align: center;
        align-items: center; }
        .community_index .sq_list .list li .info .oImg, .community_index .sq_list .trial_invite .info .ct .list li .info .user .font, .trial_invite .info .ct .community_index .sq_list .list li .info .user .font, .community_index .sq_list .trial_invite .info .ct .list li .user .info .font, .trial_invite .info .ct .community_index .sq_list .list li .user .info .font {
          width: 12vw;
          height: 12vw;
          overflow: hidden;
          border-radius: 50%; }
        .community_index .sq_list .list li .info .name_ptx {
          margin-left: 10px; }
          .community_index .sq_list .list li .info .name_ptx .name {
            font-size: 15px;
            font-weight: 700; }
          .community_index .sq_list .list li .info .name_ptx p {
            font-size: 13px;
            color: #666; }
      .community_index .sq_list .list li .r .qd button {
        background: #f23440;
        color: #fff;
        height: 28px;
        border-radius: 50px;
        padding: 0 10px; }
      .community_index .sq_list .list li .r .time {
        color: #666;
        margin-top: 5px;
        font-size: 12px;
        text-align: right; }
      .community_index .sq_list .list li .r .sq button {
        background: #2bbf9d;
        border-radius: 2px;
        color: #fff;
        height: 30px;
        padding: 0 10px; }

.sharing_makes, .chain_school_sucai {
  padding: 0 12px; }
  .sharing_makes .list li, .chain_school_sucai .list li {
    overflow: hidden;
    position: relative;
    padding: 15px 0; }
    .sharing_makes .list li .user_info, .chain_school_sucai .list li .user_info {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: justify;
      -moz-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      -webkit-box-align: center;
      -moz-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      margin-bottom: 10px; }
      .sharing_makes .list li .user_info .user, .chain_school_sucai .list li .user_info .user {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
        -moz-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        -webkit-box-align: center;
        -moz-box-align: center;
        -ms-flex-align: center;
        align-items: center; }
        .sharing_makes .list li .user_info .user .oImg, .chain_school_sucai .list li .user_info .user .oImg, .sharing_makes .trial_invite .info .ct .list li .user_info .user .font, .trial_invite .info .ct .sharing_makes .list li .user_info .user .font, .chain_school_sucai .trial_invite .info .ct .list li .user_info .user .font, .trial_invite .info .ct .chain_school_sucai .list li .user_info .user .font {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          overflow: hidden; }
        .sharing_makes .list li .user_info .user span, .chain_school_sucai .list li .user_info .user span {
          font-size: 14px;
          margin-left: 10px;
          font-weight: 700; }
      .sharing_makes .list li .user_info em, .chain_school_sucai .list li .user_info em {
        background: #f9eaed;
        padding: 3px 8px;
        border-radius: 15px;
        border: 1px solid #f23440;
        font-size: 11px;
        color: #f23440; }
    .sharing_makes .list li .ptx, .chain_school_sucai .list li .ptx {
      font-size: 14px;
      color: #333;
      letter-spacing: 1px;
      text-align: justify;
      margin: 10px 0; }
    .sharing_makes .list li .pics, .chain_school_sucai .list li .pics {
      overflow: hidden; }
      .sharing_makes .list li .pics dt, .chain_school_sucai .list li .pics dt {
        width: calc((100vw - 36px)/3);
        height: calc((100vw - 36px)/3);
        margin-right: 6px;
        float: left;
        margin-bottom: 6px;
        border-radius: 2px;
        overflow: hidden; }
        .sharing_makes .list li .pics dt:nth-of-type(3n), .chain_school_sucai .list li .pics dt:nth-of-type(3n) {
          margin-right: 0; }
    .sharing_makes .list li .btn, .chain_school_sucai .list li .btn {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: justify;
      -moz-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      -webkit-box-align: center;
      -moz-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      margin-top: 10px; }
      .sharing_makes .list li .btn .tishi, .chain_school_sucai .list li .btn .tishi {
        font-size: 12px;
        -webkit-box-flex: 1;
        -moz-box-flex: 1;
        -ms-flex: 1;
        flex: 1;
        margin-right: 20px;
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        color: #666; }
        .sharing_makes .list li .btn .tishi .font, .chain_school_sucai .list li .btn .tishi .font {
          vertical-align: -2px;
          margin-right: 10px;
          color: #2bbf9d; }
      .sharing_makes .list li .btn .fuzhi, .chain_school_sucai .list li .btn .fuzhi {
        background: #f4f4f4;
        color: #666;
        padding: 4px 10px;
        border-radius: 20px;
        font-size: 12px; }
        .sharing_makes .list li .btn .fuzhi:active, .chain_school_sucai .list li .btn .fuzhi:active {
          background: #eee; }
      .sharing_makes .list li .btn .share, .chain_school_sucai .list li .btn .share {
        height: 30px;
        padding: 0 10px;
        border-radius: 2px;
        background: #2bbf9d;
        color: #fff;
        font-size: 14px; }
        .sharing_makes .list li .btn .share:active, .chain_school_sucai .list li .btn .share:active {
          background: #27af90; }

.share_makes_p {
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 99;
  background: #fff;
  width: calc(100vw - 80px);
  padding: 0 10px 10px 10px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 4px; }
  .share_makes_p .pics {
    overflow: hidden; }
    .share_makes_p .pics dt {
      width: calc((100vw - 110px)/3);
      height: calc((100vw - 110px)/3);
      margin-right: 5px;
      margin-bottom: 5px;
      float: left; }
      .share_makes_p .pics dt:nth-of-type(3n) {
        margin-right: 0; }
  .share_makes_p .ptx {
    text-align: justify;
    margin: 8px 0; }
  .share_makes_p .btn {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -moz-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 10px 0; }
    .share_makes_p .btn button {
      height: 34px;
      width: 48%;
      border-radius: 2px; }
      .share_makes_p .btn button:active {
        background: #f4f4f4; }
    .share_makes_p .btn .l {
      color: #2bbf9d;
      border: 1px solid #2bbf9d; }
    .share_makes_p .btn .r {
      color: #f23440;
      border: 1px solid #f23440; }
  .share_makes_p h3 {
    text-align: right;
    margin: 5px 0; }
    .share_makes_p h3 button {
      width: 34px;
      height: 34px;
      color: #666; }
      .share_makes_p h3 button:active {
        background: #f4f4f4; }

.chain_school {
  padding: 12px; }
  .chain_school .list li {
    padding: 15px 0;
    position: relative;
    max-height: 250px;
    overflow: hidden; }
    .chain_school .list li .user_info {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -moz-box-align: center;
      -ms-flex-align: center;
      align-items: center; }
      .chain_school .list li .user_info .oImg, .chain_school .trial_invite .info .ct .list li .user_info .user .font, .trial_invite .info .ct .chain_school .list li .user_info .user .font, .chain_school .trial_invite .info .ct .list li .user .user_info .font, .trial_invite .info .ct .chain_school .list li .user .user_info .font {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        overflow: hidden; }
      .chain_school .list li .user_info .nick_name {
        margin-left: 10px; }
        .chain_school .list li .user_info .nick_name .name {
          font-weight: 700;
          margin-bottom: 5px; }
        .chain_school .list li .user_info .nick_name p {
          font-size: 12px;
          color: #999; }
    .chain_school .list li .ptx {
      text-align: justify;
      margin: 8px 0; }
    .chain_school .list li .video {
      width: 50vw;
      overflow: hidden;
      border-radius: 4px;
      position: relative; }
      .chain_school .list li .video button {
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        z-index: 4;
        color: #ccc;
        width: 56px;
        height: 56px;
        font-size: 48px;
        border-radius: 4px; }
        .chain_school .list li .video button:active {
          background: rgba(255, 255, 255, 0.2);
          color: #fff; }
      .chain_school .list li .video video {
        width: 100%;
        display: none; }

.chain_school_details {
  padding: 12px; }
  .chain_school_details .user_info {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center; }
    .chain_school_details .user_info .oImg, .chain_school_details .user_info .trial_invite .info .ct .list li .user .font, .trial_invite .info .ct .list li .user .chain_school_details .user_info .font {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      overflow: hidden; }
    .chain_school_details .user_info .nick_name {
      margin-left: 10px; }
      .chain_school_details .user_info .nick_name .name {
        font-weight: 700;
        margin-bottom: 5px; }
      .chain_school_details .user_info .nick_name p {
        font-size: 12px;
        color: #999; }
  .chain_school_details .content {
    margin-top: 15px; }

.chain_school_sucai .users_info {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center; }
  .chain_school_sucai .users_info .oImg, .chain_school_sucai .users_info .trial_invite .info .ct .list li .user .font, .trial_invite .info .ct .list li .user .chain_school_sucai .users_info .font {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden; }
  .chain_school_sucai .users_info .nick_name {
    margin-left: 10px; }
    .chain_school_sucai .users_info .nick_name .name {
      font-weight: 700;
      margin-bottom: 5px; }
    .chain_school_sucai .users_info .nick_name p {
      font-size: 12px;
      color: #999; }

.zhibo_list {
  overflow: hidden;
  padding: 12px; }
  .zhibo_list li {
    width: calc((100% - 12px)/2);
    float: left;
    margin-bottom: 10px;
    position: relative;
    border-radius: 2px;
    overflow: hidden;
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2); }
    .zhibo_list li .info {
      position: absolute;
      padding: 5px;
      width: 100%;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      left: 0;
      bottom: 0;
      z-index: 1;
      color: #fff; }
      .zhibo_list li .info .userInfo {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -moz-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        font-size: 12px; }
        .zhibo_list li .info .userInfo .oImg, .zhibo_list li .trial_invite .info .userInfo .ct .list li .user .font, .trial_invite .zhibo_list li .info .userInfo .ct .list li .user .font, .zhibo_list li .trial_invite .info .ct .list li .user .userInfo .font, .trial_invite .zhibo_list li .info .ct .list li .user .userInfo .font {
          width: 24px;
          height: 24px;
          border-radius: 50%;
          overflow: hidden;
          margin-right: 5px; }
        .zhibo_list li .info .userInfo .km {
          -webkit-box-flex: 1;
          -moz-box-flex: 1;
          -ms-flex: 1;
          flex: 1;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis; }
      .zhibo_list li .info .m {
        width: 100%;
        overflow: hidden;
        margin: 3px 0; }
        .zhibo_list li .info .m .btns {
          display: -webkit-box;
          display: -moz-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -moz-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          border-radius: 4px;
          padding: 5px 4px;
          font-size: 12px;
          float: left; }
          .zhibo_list li .info .m .btns .n {
            background: #666;
            padding: 2px 6px;
            border-radius: 50px;
            margin-right: 5px; }
          .zhibo_list li .info .m .btns .sel {
            background: #2bbf9d; }
      .zhibo_list li .info .name {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis; }
  .zhibo_list li:nth-of-type(2n) {
    float: right; }

.live_room {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background: #000; }
  .live_room .user_data_list {
    position: fixed;
    width: 80%;
    bottom: 60px;
    padding: 0 12px;
    left: 0;
    z-index: 44;
    font-size: 15px;
    max-height: 50vh;
    overflow-y: scroll;
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg); }
    .live_room .user_data_list li {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -moz-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      color: #fff;
      margin-bottom: 5px;
      -webkit-transform: rotate(180deg);
      transform: rotate(180deg);
      background: rgba(0, 0, 0, 0.2);
      border-radius: 50px;
      padding: 2px 5px; }
      .live_room .user_data_list li .txt {
        padding-top: 2px;
        font-size: 13px;
        -webkit-box-flex: 1;
        -moz-box-flex: 1;
        -ms-flex: 1;
        flex: 1;
        text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2); }
        .live_room .user_data_list li .txt span {
          color: #2bbf9d; }
        .live_room .user_data_list li .txt .ds {
          color: yellow; }
        .live_room .user_data_list li .txt .jy {
          color: red; }
      .live_room .user_data_list li img {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        margin-right: 10px; }
  .live_room .put_btn {
    padding: 0 15px;
    position: fixed;
    height: 40px;
    bottom: 10px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    width: 100%;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex; }
    .live_room .put_btn input, .live_room .put_btn button {
      height: 40px;
      width: 40px; }
    .live_room .put_btn .iconfont {
      font-size: 36px;
      color: rgba(255, 255, 255, 0.6); }
    .live_room .put_btn .iconfont:active {
      color: rgba(255, 255, 255, 0.8); }
    .live_room .put_btn input {
      -webkit-box-flex: 1;
      -moz-box-flex: 1;
      -ms-flex: 1;
      flex: 1;
      margin: 0 10px;
      background: rgba(255, 255, 255, 0.6);
      border-radius: 50px;
      padding: 0 15px;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      border: 1px solid rgba(34, 196, 159, 0); }
    .live_room .put_btn input:focus {
      border: 1px solid #22c49f; }
    .live_room .put_btn .send {
      background: rgba(255, 255, 255, 0.6);
      color: #022b21;
      border-radius: 50%;
      font-weight: 700; }
    .live_room .put_btn .send:active {
      background: rgba(255, 255, 255, 0.8); }
  .live_room .live_btns_list {
    position: fixed;
    z-index: 88;
    bottom: 0;
    width: 100%;
    left: 0;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -moz-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding: 10px 12px; }
    .live_room .live_btns_list dt {
      font-size: 0; }
      .live_room .live_btns_list dt button {
        height: 40px;
        width: 40px;
        font-size: 18px;
        background: rgba(255, 255, 255, 0.3);
        color: #fff;
        border-radius: 50%; }
      .live_room .live_btns_list dt .sel {
        background: #2bbf9d; }
    .live_room .live_btns_list .put {
      -webkit-box-flex: 1;
      -moz-box-flex: 1;
      -ms-flex: 1;
      flex: 1;
      margin: 0 10px; }
      .live_room .live_btns_list .put input {
        height: 40px;
        background: rgba(255, 255, 255, 0.3);
        width: 100%;
        border-radius: 25px;
        color: #fff;
        text-indent: 10px; }
    .live_room .live_btns_list .send button {
      height: 40px;
      background: #2bbf9d;
      color: #fff;
      padding: 0 20px;
      border-radius: 25px; }
      .live_room .live_btns_list .send button:active {
        background: #27af90; }
    .live_room .live_btns_list .r button {
      margin-left: 10px; }
      .live_room .live_btns_list .r button:active {
        background: rgba(255, 255, 255, 0.4); }
  .live_room .live_user {
    position: fixed;
    z-index: 88;
    background: rgba(0, 0, 0, 0.2);
    color: #fff;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding: 5px 15px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -moz-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 50px;
    top: 12px;
    left: 12px; }
    .live_room .live_user .user {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: justify;
      -moz-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      -webkit-box-align: center;
      -moz-box-align: center;
      -ms-flex-align: center;
      align-items: center; }
      .live_room .live_user .user .oImg, .live_room .live_user .trial_invite .info .ct .list li .user .font, .trial_invite .info .ct .list li .live_room .live_user .user .font {
        width: 34px;
        height: 34px;
        margin-right: 10px;
        border-radius: 50%;
        overflow: hidden; }
      .live_room .live_user .user .fm .name {
        max-width: 180px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap; }
      .live_room .live_user .user .fm p {
        font-size: 12px; }
    .live_room .live_user .close {
      padding: 10px;
      font-size: 24px; }

.live_pro {
  background: #fff;
  position: fixed;
  bottom: 0;
  width: calc(100% - 24px);
  left: 12px;
  z-index: 180;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  overflow: hidden; }
  .live_pro li {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -moz-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 12px;
    border-bottom: 1px solid #eee; }
    .live_pro li:active {
      background: #f4f4f4; }
    .live_pro li .info {
      -webkit-box-flex: 1;
      -moz-box-flex: 1;
      -ms-flex: 1;
      flex: 1;
      margin-left: 10px;
      width: calc(100% - 70px); }
      .live_pro li .info .name {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden; }
      .live_pro li .info .pri {
        color: #f23440;
        font-weight: 700;
        margin: 3px 0; }
      .live_pro li .info .hz {
        font-size: 12px;
        color: #666; }
        .live_pro li .info .hz span {
          font-size: 12px;
          color: #2bbf9d; }
    .live_pro li .oImg, .live_pro .trial_invite .info .ct .list li .user .font, .trial_invite .info .ct .list .live_pro li .user .font {
      width: 60px;
      height: 60px; }

.fantuxiu .list, .fantuxiu_details .list {
  padding: 12px; }
  .fantuxiu .list li, .fantuxiu_details .list li {
    padding: 10px 0;
    position: relative; }
    .fantuxiu .list li .users_info, .fantuxiu_details .list li .users_info {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -moz-box-align: center;
      -ms-flex-align: center;
      align-items: center; }
      .fantuxiu .list li .users_info .oImg, .fantuxiu_details .list li .users_info .oImg, .fantuxiu .trial_invite .info .ct .list li .users_info .user .font, .trial_invite .info .ct .fantuxiu .list li .users_info .user .font, .fantuxiu .trial_invite .info .ct .list li .user .users_info .font, .trial_invite .info .ct .fantuxiu .list li .user .users_info .font, .fantuxiu_details .trial_invite .info .ct .list li .users_info .user .font, .trial_invite .info .ct .fantuxiu_details .list li .users_info .user .font, .fantuxiu_details .trial_invite .info .ct .list li .user .users_info .font, .trial_invite .info .ct .fantuxiu_details .list li .user .users_info .font {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        overflow: hidden; }
      .fantuxiu .list li .users_info .nick_name, .fantuxiu_details .list li .users_info .nick_name {
        margin-left: 10px; }
        .fantuxiu .list li .users_info .nick_name .name, .fantuxiu_details .list li .users_info .nick_name .name {
          font-weight: 700;
          margin-bottom: 5px; }
        .fantuxiu .list li .users_info .nick_name p, .fantuxiu_details .list li .users_info .nick_name p {
          font-size: 12px;
          color: #999; }
    .fantuxiu .list li .ptx, .fantuxiu_details .list li .ptx {
      text-align: justify;
      margin: 10px 0; }
    .fantuxiu .list li .pics_more, .fantuxiu_details .list li .pics_more {
      overflow: hidden; }
      .fantuxiu .list li .pics_more dt, .fantuxiu_details .list li .pics_more dt {
        width: calc((100vw - 36px)/3);
        height: calc((100vw - 36px)/3);
        margin-right: 6px;
        float: left;
        margin-bottom: 6px;
        border-radius: 2px;
        overflow: hidden; }
        .fantuxiu .list li .pics_more dt:nth-of-type(3n), .fantuxiu_details .list li .pics_more dt:nth-of-type(3n) {
          margin-right: 0; }
    .fantuxiu .list li .pics dt, .fantuxiu_details .list li .pics dt {
      max-width: 70%;
      border-radius: 4px;
      overflow: hidden; }
    .fantuxiu .list li .btn, .fantuxiu_details .list li .btn {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -moz-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: justify;
      -moz-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      margin: 5px 0; }
      .fantuxiu .list li .btn button, .fantuxiu_details .list li .btn button {
        padding: 3px 5px;
        border-radius: 2px;
        color: #666; }
        .fantuxiu .list li .btn button:active, .fantuxiu_details .list li .btn button:active {
          background: #f4f4f4; }
        .fantuxiu .list li .btn button.sel, .fantuxiu_details .list li .btn button.sel {
          color: #2bbf9d; }

.fantuxiu_details .list {
  overflow: hidden;
  position: relative; }

.fantuxiu_details .user_p_list {
  padding: 15px 12px; }
  .fantuxiu_details .user_p_list li {
    padding: 10px 0;
    overflow: hidden;
    position: relative; }
    .fantuxiu_details .user_p_list li .user {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -moz-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: justify;
      -moz-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between; }
      .fantuxiu_details .user_p_list li .user .name {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -moz-box-align: center;
        -ms-flex-align: center;
        align-items: center; }
        .fantuxiu_details .user_p_list li .user .name .oImg, .fantuxiu_details .user_p_list .trial_invite .info .ct .list li .user .name .font, .trial_invite .info .ct .list .fantuxiu_details .user_p_list li .user .name .font {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          overflow: hidden; }
        .fantuxiu_details .user_p_list li .user .name em {
          font-size: 14px;
          font-weight: 700;
          margin-left: 10px; }
      .fantuxiu_details .user_p_list li .user .timer {
        font-size: 12px;
        color: #999; }
    .fantuxiu_details .user_p_list li > p {
      margin-top: 10px;
      text-align: justify; }

.fantuxiu_details .ft_put {
  position: fixed;
  bottom: 0;
  z-index: 88;
  left: 0;
  background: #fff;
  height: 50px;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -moz-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0 12px;
  width: 100%; }
  .fantuxiu_details .ft_put input {
    background: #f5f5f5;
    border-radius: 2px;
    height: 40px;
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    margin-right: 10px;
    text-indent: 15px; }
  .fantuxiu_details .ft_put button {
    background: #2bbf9d;
    color: #fff;
    height: 40px;
    padding: 0 15px;
    border-radius: 2px;
    min-width: 80px; }
    .fantuxiu_details .ft_put button:active {
      background: #27af90; }

.account_set .rule {
  margin-bottom: 10px; }
  .account_set .rule li {
    display: block;
    background: #fff;
    position: relative;
    padding: 0 12px;
    height: 50px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -moz-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center; }
    .account_set .rule li .font {
      color: #999; }
    .account_set .rule li:active {
      background: #f4f4f4; }
    .account_set .rule li .nl {
      text-align: right; }
    .account_set .rule li input[type="file"] {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      z-index: 4; }
    .account_set .rule li .headImg {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -moz-box-align: center;
      -ms-flex-align: center;
      align-items: center; }
      .account_set .rule li .headImg p {
        height: 34px;
        width: 34px;
        border-radius: 50%;
        overflow: hidden;
        margin-right: 10px; }

.sign {
  height: 100%;
  width: 1920px;
  white-space: nowrap;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  -webkit-transition: 0.3s;
  transition: 0.3s;
  font-size: 0; }
  .sign > li {
    display: inline-block;
    vertical-align: top;
    overflow: hidden; }
  .sign .go_sing {
    text-align: center;
    margin-top: 50px; }
    .sign .go_sing button {
      width: 80%;
      height: 44px;
      border: 1px solid #2bbf9d;
      margin: 0 auto 20px;
      font-size: 16px;
      color: #2bbf9d;
      border-radius: 50px;
      display: block; }
    .sign .go_sing button:active {
      background: #eee; }
  .sign .in_s,
  .sign .up_s {
    top: 0;
    left: 0;
    z-index: 4;
    width: 100%;
    background: #fff; }
    .sign .in_s .info,
    .sign .up_s .info {
      top: 50%;
      width: 100%;
      left: 0;
      z-index: 44;
      margin-top: 30px; }
      .sign .in_s .info .logo_s,
      .sign .up_s .info .logo_s {
        width: 140px;
        margin: 0px  auto 50px; }
      .sign .in_s .info .list,
      .sign .up_s .info .list {
        padding: 0 20px; }
        .sign .in_s .info .list li,
        .sign .up_s .info .list li {
          position: relative;
          margin-top: 15px;
          -webkit-box-align: center;
          -moz-box-align: center;
          -ms-flex-align: center;
          align-items: center; }
          .sign .in_s .info .list li em, .sign .in_s .info .list li input,
          .sign .up_s .info .list li em,
          .sign .up_s .info .list li input {
            height: 40px;
            line-height: 40px; }
          .sign .in_s .info .list li em,
          .sign .up_s .info .list li em {
            color: #666;
            width: 50px;
            margin-right: 10px;
            text-align: justify;
            font-size: 12px;
            position: absolute;
            left: 0;
            bottom: 0;
            z-index: 5; }
          .sign .in_s .info .list li em:after,
          .sign .up_s .info .list li em:after {
            content: '';
            display: inline-block;
            width: 100%; }
          .sign .in_s .info .list li input,
          .sign .up_s .info .list li input {
            font-size: 14px;
            -webkit-box-flex: 1;
            -moz-box-flex: 1;
            -ms-flex: 1;
            flex: 1;
            width: calc(100% - 60px);
            padding-left: 60px;
            border-bottom: 1px solid #eee;
            position: relative; }
          .sign .in_s .info .list li input:focus,
          .sign .up_s .info .list li input:focus {
            border-bottom: 1px solid #2bbf9d;
            font-weight: 700; }
          .sign .in_s .info .list li .verification, .sign .in_s .info .list li .code,
          .sign .up_s .info .list li .verification,
          .sign .up_s .info .list li .code {
            position: absolute;
            top: 50%;
            right: 0;
            -webkit-transform: translate(0, -50%);
            transform: translate(0, -50%);
            height: 30px; }
          .sign .in_s .info .list li .verification,
          .sign .up_s .info .list li .verification {
            padding: 0 15px;
            border-radius: 50px;
            background: #ccc;
            color: #fff; }
          .sign .in_s .info .list li .code,
          .sign .up_s .info .list li .code {
            max-width: 60px;
            overflow: hidden; }
            .sign .in_s .info .list li .code img,
            .sign .up_s .info .list li .code img {
              width: 100%; }
          .sign .in_s .info .list li .verification.sel,
          .sign .up_s .info .list li .verification.sel {
            background: #2bbf9d; }
      .sign .in_s .info .submit_btn,
      .sign .up_s .info .submit_btn {
        text-align: center;
        padding: 30px 15px 0; }
        .sign .in_s .info .submit_btn button,
        .sign .up_s .info .submit_btn button {
          width: 100%;
          height: 44px;
          border-radius: 50px;
          background: #2bbf9d;
          color: #fff;
          font-size: 16px; }
        .sign .in_s .info .submit_btn .labs,
        .sign .up_s .info .submit_btn .labs {
          display: -webkit-box;
          display: -moz-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -moz-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          -webkit-box-pack: justify;
          -moz-box-pack: justify;
          -ms-flex-pack: justify;
          justify-content: space-between;
          margin: 15px 0; }
          .sign .in_s .info .submit_btn .labs a,
          .sign .up_s .info .submit_btn .labs a {
            padding: 3px 0;
            font-size: 12px;
            color: #666; }
  .sign h3 button {
    width: 44px;
    height: 44px;
    text-align: center;
    font-size: 24px;
    color: #999; }
  .sign .in_t {
    text-align: right; }
  .sign .sign_go_logo {
    text-align: center;
    padding: 40px 0 5px;
    text-align: center;
    font-size: 12px; }
    .sign .sign_go_logo button {
      color: #2bbf9d;
      padding: 5px 0; }

.trial_list .priSc {
  margin-top: 5px; }

.trial_list .num {
  color: #2bbf9d; }

.trial_invite {
  position: relative;
  height: 100%;
  overflow: hidden; }
  .trial_invite .info {
    -webkit-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
    width: calc(100% - 60px);
    left: 50%;
    bottom: 0;
    z-index: 1;
    position: absolute; }
    .trial_invite .info .tit {
      font-weight: 700;
      text-align: center;
      color: #fff;
      font-size: 20px; }
    .trial_invite .info .btn {
      text-align: center;
      margin: 15px 0; }
      .trial_invite .info .btn button {
        background: #fff;
        color: #2bbf9d;
        height: 40px;
        width: 140px;
        border-radius: 50px; }
        .trial_invite .info .btn button:active {
          background: #f4f4f4; }
    .trial_invite .info .ct {
      background: #fff;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px; }
      .trial_invite .info .ct h3 {
        height: 40px;
        line-height: 40px;
        padding: 0 12px;
        position: relative;
        border-bottom: 1px solid #eee; }
      .trial_invite .info .ct .list {
        height: 68vh;
        overflow-y: scroll;
        overflow-x: hidden;
        -webkit-overflow-scrolling: touch; }
        .trial_invite .info .ct .list li {
          display: -webkit-box;
          display: -moz-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -moz-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          -webkit-box-pack: justify;
          -moz-box-pack: justify;
          -ms-flex-pack: justify;
          justify-content: space-between;
          padding: 10px 12px; }
          .trial_invite .info .ct .list li .user {
            display: -webkit-box;
            display: -moz-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -moz-box-align: center;
            -ms-flex-align: center;
            align-items: center; }
            .trial_invite .info .ct .list li .user .oImg, .trial_invite .info .ct .list li .user .font {
              width: 40px;
              height: 40px;
              line-height: 40px;
              text-align: center;
              color: #fff;
              font-size: 20px;
              border-radius: 50%;
              overflow: hidden; }
            .trial_invite .info .ct .list li .user .name_timer,
            .trial_invite .info .ct .list li .user b {
              margin-left: 10px; }
              .trial_invite .info .ct .list li .user .name_timer .name,
              .trial_invite .info .ct .list li .user b .name {
                font-size: 14px;
                font-weight: 700;
                margin-bottom: 5px; }
              .trial_invite .info .ct .list li .user .name_timer .timer,
              .trial_invite .info .ct .list li .user b .timer {
                font-size: 12px;
                color: #999; }
            .trial_invite .info .ct .list li .user b {
              color: #666; }
            .trial_invite .info .ct .list li .user .font {
              background: #999; }

.shequn .content {
  height: calc(100vh - 44px);
  overflow: hidden;
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg); }
  .shequn .content .userInfo {
    height: calc(100vh - 94px);
    overflow: auto;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding: 12px 12px;
    -webkit-overflow-scrolling: touch; }
    .shequn .content .userInfo .download {
      background: rgba(0, 0, 0, 0.3);
      position: absolute;
      bottom: 2px;
      right: 2px;
      z-index: 88;
      width: 20px;
      height: 20px;
      text-align: center;
      line-height: 20px;
      color: #fff;
      display: block;
      border-radius: 2px; }
    .shequn .content .userInfo li {
      overflow: hidden;
      -webkit-transform: rotate(180deg);
      transform: rotate(180deg); }
      .shequn .content .userInfo li .time {
        font-size: 12px;
        text-align: center;
        color: #666;
        padding: 10px 0; }
      .shequn .content .userInfo li .ct {
        max-width: calc(100vw - 148px); }
    .shequn .content .userInfo .Y .oImg, .shequn .content .userInfo .Y .trial_invite .info .ct .list li .user .font, .trial_invite .info .ct .list li .user .shequn .content .userInfo .Y .font {
      margin-right: 10px; }
    .shequn .content .userInfo .Y .oImg, .shequn .content .userInfo .Y .trial_invite .info .ct .list li .user .font, .trial_invite .info .ct .list li .user .shequn .content .userInfo .Y .font,
    .shequn .content .userInfo .Y .nick {
      float: left; }
    .shequn .content .userInfo .Y .ct {
      background: #fff; }
    .shequn .content .userInfo .Y .ct:after {
      background: url(../images/ql_ct_l_ico.png) no-repeat;
      background-size: 100% 100%;
      left: -8px; }
    .shequn .content .userInfo .M .oImg, .shequn .content .userInfo .M .trial_invite .info .ct .list li .user .font, .trial_invite .info .ct .list li .user .shequn .content .userInfo .M .font {
      margin-left: 10px; }
    .shequn .content .userInfo .M .oImg, .shequn .content .userInfo .M .trial_invite .info .ct .list li .user .font, .trial_invite .info .ct .list li .user .shequn .content .userInfo .M .font,
    .shequn .content .userInfo .M .nick {
      float: right; }
      .shequn .content .userInfo .M .oImg .name, .shequn .content .userInfo .M .trial_invite .info .ct .list li .user .font .name, .trial_invite .info .ct .list li .user .shequn .content .userInfo .M .font .name,
      .shequn .content .userInfo .M .nick .name {
        text-align: right; }
    .shequn .content .userInfo .M .ct {
      background: #2bbf9d;
      color: #fff; }
    .shequn .content .userInfo .M .ct:after {
      background: url(../images/ql_ct_r_ico.png) no-repeat;
      background-size: 100% 100%;
      right: -8px; }
    .shequn .content .userInfo .Y .ct, .shequn .content .userInfo .M .ct {
      padding: 12px;
      border-radius: 5px;
      position: relative;
      word-break: break-all; }
    .shequn .content .userInfo .Y .yY, .shequn .content .userInfo .M .yY {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -moz-box-align: center;
      -ms-flex-align: center;
      align-items: center; }
      .shequn .content .userInfo .Y .yY img, .shequn .content .userInfo .M .yY img {
        width: 18px;
        height: 18px;
        margin: 0 4px; }
    .shequn .content .userInfo .Y .video_box, .shequn .content .userInfo .M .video_box {
      width: 40vw;
      max-height: 40vw;
      position: relative;
      border-radius: 4px;
      overflow: hidden; }
      .shequn .content .userInfo .Y .video_box .t_mr, .shequn .content .userInfo .M .video_box .t_mr {
        position: absolute;
        right: 5px;
        bottom: 5px;
        font-size: 10px;
        color: #fff;
        z-index: 5; }
      .shequn .content .userInfo .Y .video_box .iconfont, .shequn .content .userInfo .M .video_box .iconfont {
        width: 50px;
        height: 50px;
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        z-index: 20;
        color: #fff;
        text-align: center;
        line-height: 50px;
        font-size: 40px; }
    .shequn .content .userInfo .Y .video_box:after, .shequn .content .userInfo .M .video_box:after {
      background: rgba(0, 0, 0, 0.4);
      z-index: 1;
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      content: "";
      display: block; }
    .shequn .content .userInfo .Y .img_pic, .shequn .content .userInfo .M .img_pic {
      width: 40vw;
      max-height: 40vw;
      border-radius: 4px;
      overflow: hidden;
      position: relative; }
    .shequn .content .userInfo .Y .ct:after, .shequn .content .userInfo .M .ct:after {
      display: block;
      height: 8px;
      width: 8px;
      position: absolute;
      top: 8px;
      z-index: 1;
      content: ""; }
    .shequn .content .userInfo .addus {
      text-align: center; }
      .shequn .content .userInfo .addus .inf {
        display: inline-block;
        background: #cccccc;
        font-size: 12px;
        color: #666;
        border-radius: 50px;
        padding: 3px 10px; }
        .shequn .content .userInfo .addus .inf span {
          color: #2bbf9d; }
    .shequn .content .userInfo .us .oImg, .shequn .content .userInfo .us .trial_invite .info .ct .list li .user .font, .trial_invite .info .ct .list li .user .shequn .content .userInfo .us .font {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      overflow: hidden; }
    .shequn .content .userInfo .us .nick .name {
      margin-bottom: 6px;
      color: #666; }
  .shequn .content .put_box {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
    background: #fff; }
    .shequn .content .put_box .put {
      min-height: 50px;
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: end;
      -moz-box-align: end;
      -ms-flex-align: end;
      align-items: flex-end;
      border-bottom: 1px solid #eee; }
      .shequn .content .put_box .put .yy, .shequn .content .put_box .put .add {
        width: 50px;
        height: 50px; }
        .shequn .content .put_box .put .yy button, .shequn .content .put_box .put .add button {
          height: 34px;
          width: 34px;
          margin: 8px; }
      .shequn .content .put_box .put .yy button {
        font-size: 30px; }
      .shequn .content .put_box .put .add .bq {
        background: #2bbf9d;
        color: #fff;
        border-radius: 50%; }
      .shequn .content .put_box .put .add .send {
        font-size: 12px;
        background: #2bbf9d;
        color: #fff;
        border-radius: 4px; }
      .shequn .content .put_box .put .op {
        -webkit-box-flex: 1;
        -moz-box-flex: 1;
        -ms-flex: 1;
        flex: 1;
        position: relative;
        -webkit-box-sizing: border-box;
        -moz-box-sizing: border-box;
        box-sizing: border-box;
        overflow: hidden;
        margin: 8px 0; }
        .shequn .content .put_box .put .op .yy_btn {
          width: 100%;
          height: 34px;
          display: none;
          background: #f2f2f2;
          border-radius: 4px;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none; }
        .shequn .content .put_box .put .op .yy_btn:active {
          background: #2bbf9d;
          color: #fff;
          z-index: 1; }
        .shequn .content .put_box .put .op .yy_btn:active + .yy_opo {
          display: block; }
        .shequn .content .put_box .put .op .textarea {
          min-height: 34px;
          max-height: 80px;
          overflow: auto;
          width: 100%;
          background: #f2f2f2;
          border-radius: 5px;
          -webkit-box-sizing: border-box;
          -moz-box-sizing: border-box;
          box-sizing: border-box;
          padding: 10px;
          -webkit-user-select: text;
          -moz-user-select: text;
          -ms-user-select: text;
          user-select: text;
          outline: none; }
        .shequn .content .put_box .put .op .ico {
          position: absolute;
          top: 0;
          right: 5px;
          height: 34px;
          width: 34px;
          z-index: 1;
          color: #666;
          font-size: 24px; }
    .shequn .content .put_box .btns {
      overflow: hidden;
      padding: 8px 0 4px 0; }
      .shequn .content .put_box .btns li {
        width: 20%;
        float: left; }
        .shequn .content .put_box .btns li .oImg, .shequn .content .put_box .btns .trial_invite .info .ct .list li .user .font, .trial_invite .info .ct .list .shequn .content .put_box .btns li .user .font {
          width: 22px;
          height: 22px;
          margin: 0 auto;
          border-radius: 2px;
          overflow: hidden;
          position: relative; }
          .shequn .content .put_box .btns li .oImg .file, .shequn .content .put_box .btns .trial_invite .info .ct .list li .user .font .file, .trial_invite .info .ct .list .shequn .content .put_box .btns li .user .font .file {
            width: 100%;
            height: 100%;
            position: absolute;
            z-index: 1;
            top: 0;
            left: 0;
            opacity: 0; }
        .shequn .content .put_box .btns li p {
          height: 22px;
          line-height: 22px;
          text-align: center;
          font-size: 10px;
          color: #666; }

.yy_opo {
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 88;
  background: #fff;
  border-radius: 8px;
  color: #fff;
  padding: 10px;
  display: none; }
  .yy_opo .mm {
    background: #2bbf9d;
    border-radius: 8px; }
    .yy_opo .mm p {
      height: 40px;
      line-height: 40px;
      text-align: center; }

.moveSignIn2 {
  position: fixed;
  z-index: 55;
  font-size: 50px;
  color: #2bbf9d;
  bottom: 120px;
  left: 50%;
  -webkit-transform: translate3d(-50%, 0, 0);
  transform: translate3d(-50%, 0, 0);
  text-shadow: 1px 0px 0px #FFFFFF, -1px 0px 0px #FFFFFF, 0px 1px 0px #FFFFFF, 0px -1px 0px #FFFFFF; }

.moveSignIn2.check_animate2 {
  animation: check_an 2s ease-in-out;
  -webkit-animation: check_an 2s ease-in-out;
  /* Safari 和 Chrome */ }

@-webkit-keyframes check_an {
  0% {
    -webkit-transform: translate3d(-50%, 0, 0);
    transform: translate3d(-50%, 0, 0); }
  50% {
    -webkit-transform: translate3d(-50%, -50px, 0) scale(1.5, 1.5);
    transform: translate3d(-50%, -50px, 0) scale(1.5, 1.5);
    opacity: 1; }
  100% {
    -webkit-transform: translate3d(-50%, -50px, 0) scale(1, 1);
    transform: translate3d(-50%, -50px, 0) scale(1, 1);
    opacity: 0; } }

.moveSignIn {
  position: absolute;
  z-index: 55;
  font-size: 50px;
  color: #fff;
  bottom: 20px;
  left: 50%;
  -webkit-transform: translate3d(-50%, 0, 0);
  transform: translate3d(-50%, 0, 0); }

.moveSignIn.check_animate {
  animation: check_an 2s ease-in-out;
  -webkit-animation: check_an 2s ease-in-out;
  /* Safari 和 Chrome */ }

@-webkit-keyframes check_an {
  0% {
    -webkit-transform: translate3d(-50%, 0, 0);
    transform: translate3d(-50%, 0, 0); }
  50% {
    -webkit-transform: translate3d(-50%, -50px, 0) scale(1.5, 1.5);
    transform: translate3d(-50%, -50px, 0) scale(1.5, 1.5);
    opacity: 1; }
  100% {
    -webkit-transform: translate3d(-50%, -50px, 0) scale(1, 1);
    transform: translate3d(-50%, -50px, 0) scale(1, 1);
    opacity: 0; } }

.head_23 {
  height: 44px;
  background: #fff;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -moz-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-bottom: 1px solid #eee;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 88;
  width: 100%; }
  .head_23 .linf {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-weight: 700; }
    .head_23 .linf button {
      height: 44px;
      width: 44px; }
  .head_23 .user {
    height: 44px;
    width: 44px;
    font-size: 20px; }

.shequn_user .users_box {
  background: #fff; }

.shequn_user .qi_li {
  margin-top: 10px;
  background: #fff; }
  .shequn_user .qi_li li {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 12px;
    -webkit-box-pack: justify;
    -moz-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    height: 44px;
    line-height: 44px;
    padding: 0 12px;
    border-bottom: 1px solid #eee; }
  .shequn_user .qi_li li:nth-last-of-type(1) {
    border-bottom: 0; }

.shequn_user .btns_q2 {
  margin-top: 10px;
  text-align: center;
  background: #fff; }
  .shequn_user .btns_q2 button {
    height: 44px;
    width: 100%;
    color: red; }

.shequn_user .users {
  overflow: hidden;
  padding: 12px 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  overflow: auto; }
  .shequn_user .users li {
    width: 20%;
    float: left;
    text-align: center; }
    .shequn_user .users li .oImg, .shequn_user .users .trial_invite .info .ct .list li .user .font, .trial_invite .info .ct .list .shequn_user .users li .user .font {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      margin: 0 auto;
      overflow: hidden; }
    .shequn_user .users li p {
      height: 30px;
      line-height: 30px;
      white-space: nowrap;
      font-size: 12px;
      color: #666;
      text-overflow: ellipsis;
      overflow: hidden; }

.shequn_user .more {
  text-align: center; }
  .shequn_user .more button {
    width: 100%;
    height: 50px;
    color: #666;
    font-size: 13px; }

.shequn_user .btns_q {
  height: 60px;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 10px 12px;
  z-index: 55; }
  .shequn_user .btns_q button {
    height: 40px;
    width: 100%;
    background: #2bbf9d;
    color: #fff;
    border-radius: 5px; }

.hy_sq {
  padding: 12px;
  background: #fff;
  overflow: hidden; }
  .hy_sq h3 {
    font-size: 16px;
    font-weight: 700;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -moz-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between; }
    .hy_sq h3 button {
      font-size: 12px;
      color: #666; }
  .hy_sq .shping {
    margin-top: 12px; }
    .hy_sq .shping li {
      width: calc((100vw - 36px)/2);
      float: left;
      border-radius: 5px;
      overflow: hidden;
      position: relative; }
      .hy_sq .shping li .bofang_ico {
        position: absolute;
        width: 50px;
        height: 50px;
        left: 50%;
        top: 50px;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        z-index: 1; }
      .hy_sq .shping li .yg {
        position: absolute;
        z-index: 1;
        right: 5px;
        top: 5px;
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -moz-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        color: #fff;
        font-size: 9px;
        background: rgba(0, 0, 0, 0.6);
        border-radius: 50px;
        padding: 3px 6px; }
        .hy_sq .shping li .yg span {
          width: 4px;
          height: 4px;
          border-radius: 50%;
          background: #2bbf9d;
          margin-right: 3px; }
      .hy_sq .shping li .tm {
        color: #fff;
        white-space: nowrap;
        font-size: 12px;
        position: absolute;
        z-index: 1;
        width: calc(100% - 10px);
        bottom: 2px;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        text-overflow: ellipsis;
        overflow: hidden; }
    .hy_sq .shping li:nth-of-type(2n) {
      float: right; }
  .hy_sq .qliao li {
    background: #f2f2f2;
    border-radius: 5px;
    padding: 12px;
    margin-bottom: 10px;
    position: relative; }
    .hy_sq .qliao li .fo {
      overflow: hidden;
      display: block; }
    .hy_sq .qliao li .oImg, .hy_sq .qliao .trial_invite .info .ct .list li .user .font, .trial_invite .info .ct .list .hy_sq .qliao li .user .font {
      width: 47px;
      height: 47px;
      border-radius: 50%;
      overflow: hidden;
      float: left; }
    .hy_sq .qliao li .inm {
      margin-left: 10px;
      width: calc(100% - 57px);
      float: left; }
      .hy_sq .qliao li .inm .name {
        font-weight: 700;
        font-size: 15px;
        margin-bottom: 5px;
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -moz-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: justify;
        -moz-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between; }
        .hy_sq .qliao li .inm .name em {
          color: #999;
          font-weight: 400;
          font-size: 12px;
          white-space: nowrap;
          margin-left: 15px; }
      .hy_sq .qliao li .inm .txt {
        font-size: 12px;
        color: #666;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 50vw; }
    .hy_sq .qliao li .shq_btn {
      position: absolute;
      top: 50%;
      right: 12px;
      -webkit-transform: translate(0, -50%);
      transform: translate(0, -50%);
      z-index: 1;
      background: #2bbf9d;
      color: #fff;
      height: 30px;
      padding: 0 15px;
      border-radius: 2px;
      font-size: 12px; }
  .hy_sq .qliao .shq {
    padding-right: 90px; }

.qliao_ct {
  background: #fff; }
  .qliao_ct .labs {
    overflow: hidden; }
    .qliao_ct .labs button {
      height: 50px;
      width: 50%;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      float: left; }
    .qliao_ct .labs button:nth-of-type(1) {
      border-right: 1px solid #eee; }
    .qliao_ct .labs .sel {
      color: #2bbf9d; }
  .qliao_ct .list li {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 10px 12px;
    border-top: 1px solid #eee; }
    .qliao_ct .list li .oImg, .qliao_ct .trial_invite .info .ct .list li .user .font, .trial_invite .info .ct .qliao_ct .list li .user .font {
      width: 34px;
      height: 34px;
      overflow: hidden;
      border-radius: 50%;
      margin-right: 10px; }

.boxClipHeadImg {
  position: fixed;
  height: 100vh;
  width: 100vw;
  overflow-y: hidden;
  top: 0;
  left: 0;
  z-index: 99;
  background: #fff; }
  .boxClipHeadImg .clipBtnBox {
    position: absolute;
    top: 0px;
    right: 0px;
    z-index: 5;
    background: #fff;
    width: 100%;
    height: 44px;
    text-align: right;
    color: #666; }
  .boxClipHeadImg .clipBtnBox button.back {
    height: 44px;
    width: 44px;
    font-size: 18px;
    line-height: 44px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1; }
  .boxClipHeadImg .clipBtnBox button.sure {
    width: 80px;
    height: 30px;
    color: #fff;
    background: #2bbf9d;
    margin: 7px 12px 0 0;
    border-radius: 2px; }
  .boxClipHeadImg .clipArea {
    height: 100vh; }
  .boxClipHeadImg .view {
    margin: 0 auto;
    width: 200px;
    height: 200px;
    background-color: #666; }

.modify {
  padding: 0 12px; }
  .modify input {
    height: 44px;
    border-bottom: 1px solid #eee;
    width: 100%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    font-size: 16px; }
  .modify p {
    font-size: 12px;
    color: #999;
    margin-top: 10px; }
  .modify .y_code {
    position: relative; }
    .modify .y_code .code {
      top: 50%;
      right: 12px;
      -webkit-transform: translate(0, -50%);
      transform: translate(0, -50%);
      z-index: 1;
      background: #eee;
      position: absolute;
      padding: 8px 10px;
      border-radius: 2px; }
      .modify .y_code .code:active {
        background: #ccc; }
      .modify .y_code .code.sel {
        background: #2bbf9d;
        color: #fff; }
        .modify .y_code .code.sel:active {
          background: #27af90; }
  .modify .sex_b {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-top: 10px; }
    .modify .sex_b dt {
      padding: 15px;
      font-size: 20px;
      border-radius: 2px; }
      .modify .sex_b dt.sel {
        color: #2bbf9d; }
      .modify .sex_b dt:active {
        background: #f4f4f4; }
      .modify .sex_b dt .font {
        margin-right: 5px;
        font-size: 22px;
        vertical-align: -2px; }

.my_coupon .list {
  padding: 0 12px; }
  .my_coupon .list li {
    position: relative;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -moz-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-top: 20px;
    border: 1px solid #eee; }
    .my_coupon .list li .oImg, .my_coupon .trial_invite .info .ct .list li .user .font, .trial_invite .info .ct .my_coupon .list li .user .font {
      width: 30vw;
      position: relative; }
      .my_coupon .list li .oImg .num, .my_coupon .trial_invite .info .ct .list li .user .font .num, .trial_invite .info .ct .my_coupon .list li .user .font .num {
        position: absolute;
        top: 50%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        left: 50%;
        color: #fff;
        text-align: center; }
        .my_coupon .list li .oImg .num .nu, .my_coupon .trial_invite .info .ct .list li .user .font .num .nu, .trial_invite .info .ct .my_coupon .list li .user .font .num .nu {
          margin-bottom: 5px; }
          .my_coupon .list li .oImg .num .nu span, .my_coupon .trial_invite .info .ct .list li .user .font .num .nu span, .trial_invite .info .ct .my_coupon .list li .user .font .num .nu span {
            font-size: 20px;
            font-weight: 700; }
        .my_coupon .list li .oImg .num .m, .my_coupon .trial_invite .info .ct .list li .user .font .num .m, .trial_invite .info .ct .my_coupon .list li .user .font .num .m {
          font-size: 12px;
          white-space: nowrap; }
    .my_coupon .list li .info {
      -webkit-box-flex: 1;
      -moz-box-flex: 1;
      -ms-flex: 1;
      flex: 1;
      margin-left: 10px; }
      .my_coupon .list li .info .name {
        font-weight: 700;
        margin-bottom: 5px;
        font-size: 15px; }
      .my_coupon .list li .info .timer {
        font-size: 12px;
        color: #999;
        margin-bottom: 5px; }
      .my_coupon .list li .info .btn button {
        background: #eaf9f6;
        color: #2bbf9d;
        border: 1px solid #2bbf9d;
        height: 30px;
        padding: 0 15px;
        border-radius: 30px; }
        .my_coupon .list li .info .btn button:active {
          background: #f4f4f4; }
    .my_coupon .list li .ico {
      width: 24vw;
      height: 24vw;
      position: absolute;
      top: 50%;
      -webkit-transform: translate(0, -50%);
      transform: translate(0, -50%);
      z-index: 1;
      right: 5px; }

.task_check_in .head {
  overflow: hidden;
  position: relative; }
  .task_check_in .head .timer {
    position: absolute;
    left: 12px;
    z-index: 4;
    top: 50%;
    -webkit-transform: translate(0, -50%);
    transform: translate(0, -50%);
    color: #fff;
    text-align: left; }
    .task_check_in .head .timer .day {
      font-size: 26px;
      white-space: nowrap; }
    .task_check_in .head .timer .week {
      margin: 8px 0; }
  .task_check_in .head .btn {
    position: absolute;
    bottom: 0;
    right: 12px;
    z-index: 4; }
    .task_check_in .head .btn .icon {
      background: rgba(255, 255, 255, 0.5);
      width: 70px;
      height: 70px;
      border-radius: 50%;
      overflow: hidden;
      float: right; }
      .task_check_in .head .btn .icon em {
        width: 60px;
        height: 60px;
        text-align: center;
        display: block;
        margin: 5px;
        background: #fff;
        border-radius: 50%; }
        .task_check_in .head .btn .icon em svg {
          width: 34px;
          height: 34px;
          margin-top: 13px; }
    .task_check_in .head .btn .icon:active {
      opacity: 0.8; }
    .task_check_in .head .btn .z_p_txt {
      white-space: nowrap;
      padding-top: 10px;
      text-align: center;
      clear: both; }

.task_check_in .list {
  width: calc(100% - 40px);
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  margin: 30px auto 50px;
  -webkit-box-pack: justify;
  -moz-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  background: url(../images/qiandaoBj.jpg) center 15px no-repeat;
  background-size: calc(100% - 50px) 1px; }
  .task_check_in .list li i {
    background: #dddddd;
    width: 30px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    font-size: 18px;
    color: #fff;
    border-radius: 50%;
    display: block;
    margin: 0 auto; }
  .task_check_in .list li p {
    margin-top: 8px;
    color: #666;
    font-size: 12px; }
  .task_check_in .list li.sel i {
    background: #2bbf9d; }

.task_check_in .notice {
  background: #fff;
  padding: 20px 12px; }
  .task_check_in .notice h3 {
    font-size: 18px;
    font-weight: 700; }
  .task_check_in .notice ul {
    margin-top: 15px;
    color: #666; }
    .task_check_in .notice ul li {
      padding: 2px 0;
      font-size: 13px;
      line-height: 18px; }

.forget_pwd {
  padding: 0 15px; }
  .forget_pwd .logo {
    width: 43%;
    margin: 40px auto; }
  .forget_pwd li {
    position: relative;
    margin-bottom: 10px; }
    .forget_pwd li em {
      width: 80px;
      height: 40px;
      line-height: 40px;
      position: absolute;
      top: 0;
      left: 0;
      font-size: 12px;
      color: #666; }
    .forget_pwd li input {
      width: 100%;
      text-indent: 80px;
      height: 40px;
      font-size: 15px;
      border-bottom: 1px solid #e5e5e5; }
    .forget_pwd li input:focus {
      border-bottom: 1px solid #2bbf9d;
      font-weight: 700; }
    .forget_pwd li .verCode,
    .forget_pwd li .verification {
      position: absolute;
      top: 50%;
      right: 0;
      -webkit-transform: translate(0, -50%);
      transform: translate(0, -50%);
      color: #fff;
      height: 34px;
      border-radius: 50px; }
    .forget_pwd li .verCode.sel {
      background: #2bbf9d; }
    .forget_pwd li .verCode {
      background: #999;
      padding: 0 20px; }
    .forget_pwd li .verification img {
      height: 100%;
      width: auto; }
  .forget_pwd .haveUserAccount {
    margin-top: 110px;
    text-align: center; }
    .forget_pwd .haveUserAccount button {
      border-bottom: 1px solid #2bbf9d;
      padding: 5px 0;
      color: #666; }
  .forget_pwd .forgetPwd {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -moz-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-top: 5px; }
    .forget_pwd .forgetPwd a {
      border-bottom: 1px solid #2bbf9d;
      padding: 5px 0;
      color: #666; }
  .forget_pwd .submitBtnBox {
    padding: 20px 0 0; }
    .forget_pwd .submitBtnBox button {
      height: 44px;
      width: 100%;
      border-radius: 40px;
      background: #2bbf9d;
      color: #fff;
      font-size: 16px; }

.user_hob_more_list {
  overflow: hidden; }
  .user_hob_more_list .module {
    background: #fff;
    background: #fff;
    margin-top: 10px; }
    .user_hob_more_list .module li {
      height: 44px;
      line-height: 44px;
      padding: 0 12px; }
      .user_hob_more_list .module li em {
        height: 44px;
        line-height: 44px;
        display: block; }
    .user_hob_more_list .module li:not(:nth-last-of-type(1)) em {
      border-bottom: 1px solid #eee; }

.trial_ranking {
  height: 100vh;
  position: fixed;
  width: 100%;
  left: 0;
  z-index: 1;
  top: 0;
  overflow: hidden; }
  .trial_ranking .head {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: end;
    -moz-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    padding: 15px 0;
    color: #666;
    text-align: center; }
    .trial_ranking .head .n {
      font-size: 28px;
      margin-bottom: 5px;
      font-weight: 700;
      color: #2bbf9d; }
    .trial_ranking .head .z .oImg, .trial_ranking .head .z .trial_invite .info .ct .list li .user .font, .trial_invite .info .ct .list li .user .trial_ranking .head .z .font {
      width: 18vw;
      height: 18vw;
      border-radius: 50%;
      margin-bottom: 10px;
      overflow: hidden; }
  .trial_ranking .more_rank {
    width: calc(100vw - 40px);
    background: #fff;
    -webkit-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
    bottom: 0;
    height: 79vh;
    overflow-y: scroll;
    left: 50%;
    z-index: 55;
    position: absolute;
    -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1); }
    .trial_ranking .more_rank table {
      width: 100%;
      text-align: center; }
      .trial_ranking .more_rank table td {
        padding: 10px 0;
        color: #666; }
      .trial_ranking .more_rank table thead td {
        border-bottom: 1px solid #eee;
        color: #333; }
      .trial_ranking .more_rank table svg {
        height: 26px;
        width: 26px;
        margin: 0 auto; }

.notice_news .notice li {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -moz-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 15px 12px;
  position: relative; }
  .notice_news .notice li.sel em {
    background: #2bbf9d; }
  .notice_news .notice li:active {
    background: #f4f4f4; }
  .notice_news .notice li p {
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    margin: 0 30px 0 10px;
    color: #666;
    font-size: 13px;
    text-align: justify; }

.notice_news em {
  height: 8px;
  width: 8px;
  background: #ccc;
  border-radius: 50%;
  display: block; }

.notice_news .font {
  color: #999; }

.notice_news .news_user li {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -moz-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 15px 12px;
  position: relative; }
  .notice_news .news_user li:active {
    background: #f4f4f4; }
  .notice_news .news_user li.sel .ct em {
    background: #2bbf9d; }
  .notice_news .news_user li .info .time {
    font-size: 12px;
    color: #999; }
  .notice_news .news_user li .info .name {
    margin: 5px 0;
    font-weight: 700; }
  .notice_news .news_user li .info .ct {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -moz-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    color: #666; }
    .notice_news .news_user li .info .ct p {
      margin: 0 30px 0 10px;
      color: #666;
      font-size: 13px;
      text-align: justify;
      -webkit-box-flex: 1;
      -moz-box-flex: 1;
      -ms-flex: 1;
      flex: 1; }
  .notice_news .news_user li.no_m .ct p {
    margin: 0 10px 0 10px; }

.notice_news_details {
  padding: 12px; }
  .notice_news_details .name {
    font-weight: 700; }
  .notice_news_details .time {
    margin: 10px 0;
    font-size: 12px;
    color: #999; }
  .notice_news_details .ct p {
    text-align: justify;
    color: #666; }

.ruleBox2 {
  background: url(../images/rulebg.png) no-repeat;
  background-size: 100% auto;
  overflow: hidden;
  text-align: center;
  margin-top: 44px;
  padding: 0 0 20px; }
  .ruleBox2 .backBtn {
    height: 44px;
    width: 44px;
    line-height: 44px;
    text-align: center;
    font-size: 18px;
    z-index: 5;
    position: absolute;
    top: 0;
    left: 0;
    color: #fff;
    display: block; }
  .ruleBox2 .name {
    color: #fff;
    background: #2bbf9d;
    padding: 44px 12px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px; }
    .ruleBox2 .name h4 {
      font-size: 22px; }
    .ruleBox2 .name p {
      margin: 10px auto;
      font-size: 12px; }
    .ruleBox2 .name .notice2 {
      text-align: justify;
      font-size: 13px; }
      .ruleBox2 .name .notice2 button {
        color: #00ffff; }
  .ruleBox2 .info {
    padding: 0 12px; }
    .ruleBox2 .info > li {
      background: #fff;
      -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
      margin-top: 15px;
      padding: 20px 12px;
      border-radius: 6px; }
      .ruleBox2 .info > li .mTit {
        width: 60%;
        margin: 0 auto; }
      .ruleBox2 .info > li .oInfoImg {
        width: 80%;
        margin: 20px auto; }
      .ruleBox2 .info > li > p {
        color: #666;
        text-align: center;
        margin: 5px auto;
        font-size: 12px; }
      .ruleBox2 .info > li .core {
        margin-top: 20px; }
        .ruleBox2 .info > li .core li {
          display: -webkit-box;
          display: -moz-box;
          display: -ms-flexbox;
          display: flex;
          margin-bottom: 20px; }
          .ruleBox2 .info > li .core li i {
            font-size: 24px;
            color: #2bbf9d;
            margin-right: 10px;
            width: 30px; }
          .ruleBox2 .info > li .core li .txt {
            text-align: justify;
            -webkit-box-flex: 1;
            -moz-box-flex: 1;
            -ms-flex: 1;
            flex: 1; }
            .ruleBox2 .info > li .core li .txt h4 {
              font-size: 14px;
              color: #2bbf9d;
              height: 28px;
              line-height: 28px; }
            .ruleBox2 .info > li .core li .txt p {
              color: #333;
              font-size: 12px;
              line-height: 20px;
              text-align: justify; }
      .ruleBox2 .info > li .icons {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-pack: distribute;
        justify-content: space-around;
        -webkit-box-align: center;
        -moz-box-align: center;
        -ms-flex-align: center;
        align-items: center; }
        .ruleBox2 .info > li .icons dt i {
          width: 50px;
          height: 50px;
          margin: 0 auto; }
        .ruleBox2 .info > li .icons dt i, .ruleBox2 .info > li .icons dt em {
          display: block; }
        .ruleBox2 .info > li .icons dt i {
          font-size: 34px;
          color: #2bbf9d;
          margin: 10px auto;
          text-shadow: 3px 3px 2px rgba(0, 0, 0, 0.2); }
        .ruleBox2 .info > li .icons dt em span {
          color: #2bbf9d;
          padding-right: 4px; }

.hz_shuoming li {
  padding: 10px 12px 0;
  background: #fff; }
  .hz_shuoming li .t {
    padding: 10px 0;
    font-size: 14px;
    color: #2bbf9d; }
  .hz_shuoming li .ptx {
    font-size: 13px;
    color: #666;
    margin-bottom: 7px; }
  .hz_shuoming li .content {
    color: #666;
    font-size: 13px; }
    .hz_shuoming li .content p {
      padding: 5px 0;
      text-align: justify; }

.use_about_us {
  height: 100%;
  position: relative;
  overflow: hidden; }
  .use_about_us .company {
    position: absolute;
    bottom: 20px;
    -webkit-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
    z-index: 1;
    left: 50%;
    text-align: center;
    white-space: nowrap; }
    .use_about_us .company .name {
      font-size: 11px;
      color: #666;
      margin-top: 4px; }
  .use_about_us .logo_icon {
    text-align: center;
    margin-top: 100px; }
    .use_about_us .logo_icon .oImg, .use_about_us .logo_icon .trial_invite .info .ct .list li .user .font, .trial_invite .info .ct .list li .user .use_about_us .logo_icon .font {
      width: 70px;
      height: 70px;
      overflow: hidden;
      border-radius: 5px;
      margin: 0 auto; }
    .use_about_us .logo_icon .txt {
      font-size: 15px;
      margin-top: 20px;
      color: #666; }
    .use_about_us .logo_icon .vs {
      font-size: 13px;
      color: #999;
      margin-top: 5px; }
    .use_about_us .logo_icon button {
      background: #2bbf9d;
      color: #fff;
      padding: 5px 10px;
      min-width: 90px;
      border-radius: 40px;
      margin-top: 20px; }

.jinduTime {
  width: calc(100% - 24px);
  margin: 0 auto;
  border-radius: 5px;
  background: #fff;
  overflow: hidden; }
  .jinduTime .name {
    padding: 20px 12px;
    background: #d3f7f3;
    width: calc(100% - 50px);
    margin: 10px auto 0;
    text-align: center;
    border-radius: 5px; }
    .jinduTime .name h3 {
      color: #2bbf9d;
      font-size: 18px; }
    .jinduTime .name em {
      color: #666;
      display: block;
      margin-top: 5px; }
  .jinduTime ul {
    background: #fff;
    width: calc(100% - 40px);
    margin: 0 12px 0 28px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    border-left: 1px solid #2bbf9d;
    color: #666;
    font-size: 12px;
    padding: 20px 0; }
    .jinduTime ul li {
      padding: 0px 0 0 30px;
      margin-top: 30px;
      position: relative; }
      .jinduTime ul li p {
        margin-top: 5px; }
        .jinduTime ul li p a {
          color: #2bbf9d;
          margin-left: 4px; }
      .jinduTime ul li em {
        color: #2bbf9d; }
    .jinduTime ul li:after {
      content: '';
      display: block;
      width: 8px;
      height: 8px;
      border: 1px solid #2bbf9d;
      border-radius: 50%;
      left: -6px;
      position: absolute;
      background: #fff;
      top: 0; }
    .jinduTime ul li.sel:after {
      background: #2bbf9d; }

.pro_pinglun {
  overflow: hidden;
  padding: 12px;
  background: #fff;
  margin-bottom: 10px; }
  .pro_pinglun .oImg, .pro_pinglun .trial_invite .info .ct .list li .user .font, .trial_invite .info .ct .list li .user .pro_pinglun .font {
    width: 70px;
    height: 70px;
    float: left; }
  .pro_pinglun .info {
    width: calc(100% - 80px);
    float: right; }
    .pro_pinglun .info .wuxing {
      color: #fedb1d;
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -moz-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      margin-top: 10px; }
      .pro_pinglun .info .wuxing li {
        height: 34px;
        width: 34px;
        line-height: 34px;
        text-align: center;
        margin-right: 5px;
        font-size: 20px; }

.monitoring .labs {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -moz-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  position: relative;
  background: #fff; }
  .monitoring .labs button {
    height: 50px;
    font-size: 14px;
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    color: #666; }
  .monitoring .labs .sel {
    color: #2bbf9d; }

.monitoring .labs:after {
  width: 1px;
  height: 40px;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 55;
  content: '';
  display: block;
  position: absolute;
  background: #eee; }

.monitoring .ct {
  background: #fff; }
  .monitoring .ct textarea {
    width: 100%;
    padding: 12px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    height: 80px; }

.monitoring .sel_jz {
  background: #fff;
  margin-bottom: 12px;
  padding: 12px; }
  .monitoring .sel_jz .mk {
    padding: 20px 10px;
    background: #f2f2f2;
    border-radius: 4px; }
    .monitoring .sel_jz .mk .t {
      font-size: 14px;
      color: #333;
      font-weight: 700; }
    .monitoring .sel_jz .mk .list li {
      font-size: 12px;
      color: #666;
      font-size: 14px; }
  .monitoring .sel_jz .btns {
    text-align: center; }
    .monitoring .sel_jz .btns button {
      width: 100px;
      border-radius: 50px;
      height: 34px;
      background: #ccc;
      margin: 10px;
      color: #fff; }
    .monitoring .sel_jz .btns .sel {
      background: #2bbf9d; }

.monitoring .picture {
  padding: 20px 12px;
  background: #fff;
  overflow: hidden; }
  .monitoring .picture .info {
    background: #f4f5f9;
    height: 100px;
    width: 100px;
    position: relative;
    overflow: hidden;
    float: left;
    margin-right: 10px; }
    .monitoring .picture .info .del {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 4;
      width: 20px;
      height: 20px; }
    .monitoring .picture .info .cm {
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      z-index: 4;
      text-align: center;
      color: #666; }
      .monitoring .picture .info .cm .iconfont {
        font-size: 20px; }
    .monitoring .picture .info input {
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 44;
      position: absolute;
      opacity: 0; }

.hongbao {
  width: 90px;
  height: 90px;
  z-index: 88;
  position: fixed;
  top: 20%;
  right: 12px; }

.hongbao_box {
  width: 80vw;
  z-index: 89;
  padding: 15px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%); }

.icon_jb {
  position: fixed;
  z-index: 99;
  background: #fff;
  height: 50px;
  width: 50px;
  right: 20px;
  bottom: 60px;
  border-radius: 50%;
  overflow: hidden;
  -webkit-box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3); }
  .icon_jb .lrCircle {
    height: 44px;
    width: 44px;
    margin: 3px 0 0 3px;
    border-radius: 50%;
    position: relative;
    background: #fdf2f0;
    -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1);
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.1); }
    .icon_jb .lrCircle .icon {
      width: 36px;
      height: 36px;
      background: #fff;
      position: absolute;
      left: 4px;
      top: 4px;
      overflow: hidden;
      border-radius: 50%;
      -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.1); }
      .icon_jb .lrCircle .icon img {
        width: 28px;
        height: 28px;
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        z-index: 1; }
      .icon_jb .lrCircle .icon .nub_b {
        position: absolute;
        width: 36px;
        height: 36px;
        top: 0;
        left: 0;
        color: #2bbf9d;
        z-index: 1;
        display: block;
        line-height: 36px;
        text-align: center;
        font-size: 18px;
        font-weight: 700;
        -webkit-transform: scale(0, 0);
        transform: scale(0, 0); }
    .icon_jb .lrCircle .l, .icon_jb .lrCircle .r {
      width: 22px;
      height: 44px;
      float: left;
      position: relative;
      overflow: hidden; }
      .icon_jb .lrCircle .l .lc,
      .icon_jb .lrCircle .l .rc, .icon_jb .lrCircle .r .lc,
      .icon_jb .lrCircle .r .rc {
        height: 38px;
        width: 38px;
        position: absolute;
        top: 0;
        border-radius: 50%;
        overflow: hidden;
        border: 3px solid transparent; }
      .icon_jb .lrCircle .l .lc, .icon_jb .lrCircle .r .lc {
        left: 0;
        border-top: 3px solid #2bbf9d;
        border-left: 3px solid #2bbf9d;
        -webkit-transform: rotate(135deg);
        transform: rotate(135deg); }
      .icon_jb .lrCircle .l .rc, .icon_jb .lrCircle .r .rc {
        right: 0;
        border-top: 3px solid #2bbf9d;
        border-right: 3px solid #2bbf9d;
        -webkit-transform: rotate(225deg);
        transform: rotate(225deg); }

.trial_dianzan_ico {
  position: fixed;
  z-index: 77;
  bottom: 70px;
  right: 20px;
  width: 40px;
  height: 40px;
  background: rgba(255, 255, 255, 0.7);
  font-size: 26px;
  border-radius: 50%;
  -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2); }
  .trial_dianzan_ico:active {
    -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.2), inset 0 0 5px rgba(0, 0, 0, 0.2);
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2), inset 0 0 5px rgba(0, 0, 0, 0.2); }
  .trial_dianzan_ico.sel {
    color: #2bbf9d; }

.tixian_accunt li {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -moz-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  padding: 10px 12px; }
  .tixian_accunt li:active {
    background: #f4f4f4; }
  .tixian_accunt li svg {
    width: 40px;
    height: 40px;
    margin-right: 10px; }
  .tixian_accunt li .l {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -moz-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center; }
  .tixian_accunt li .r {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -moz-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center; }
    .tixian_accunt li .r .name {
      font-size: 12px;
      color: #999; }
    .tixian_accunt li .r .font {
      color: #999; }

.group_inviate {
  padding: 12px; }
  .group_inviate .head {
    border-radius: 4px;
    padding: 30px 0;
    text-align: center;
    margin-bottom: 10px;
    position: relative; }
    .group_inviate .head .faile_ico {
      width: 20vw;
      height: 20vw;
      position: absolute;
      top: 3px;
      right: 5px;
      z-index: 22; }
    .group_inviate .head.start {
      color: #fff;
      background: -webkit-gradient(linear, right top, left top, from(#2cbf9f), to(#27bdc7));
      background: linear-gradient(to left, #2cbf9f, #27bdc7); }
    .group_inviate .head.failed {
      background: #f8f8f8; }
    .group_inviate .head.success {
      color: #fff;
      background: -webkit-gradient(linear, right top, left top, from(#f33340), to(#e94021));
      background: linear-gradient(to left, #f33340, #e94021); }
    .group_inviate .head .users {
      text-align: center; }
      .group_inviate .head .users li {
        width: 50px;
        height: 50px;
        text-align: center;
        line-height: 50px;
        font-weight: 700;
        margin: 0 10px;
        border-radius: 50%;
        overflow: hidden;
        border: 1px solid #fff;
        background: #999;
        display: inline-block;
        vertical-align: middle; }
        .group_inviate .head .users li .font {
          color: #fff;
          font-size: 20px; }
  .group_inviate h3 {
    font-weight: 700;
    margin: 10px 0; }
  .group_inviate .time b {
    background: #fff;
    border-radius: 2px;
    padding: 2px 3px;
    color: #2bbf9d;
    font-size: 12px; }
  .group_inviate .info {
    margin: 10px 0; }
    .group_inviate .info li {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: justify;
      -moz-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      -webkit-box-align: center;
      -moz-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      padding: 15px 12px;
      position: relative; }
      .group_inviate .info li:active {
        background: #f4f4f4; }
      .group_inviate .info li p {
        color: #666; }
        .group_inviate .info li p .font {
          color: #999; }
  .group_inviate .juan {
    width: 70%;
    margin: 30px auto 0; }
  .group_inviate .pop_btn_pt {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 77;
    overflow: hidden;
    width: 100%;
    color: #fff; }
    .group_inviate .pop_btn_pt button {
      width: 50%;
      height: 50px;
      float: left; }
      .group_inviate .pop_btn_pt button.l {
        background: #2bbf9d; }
        .group_inviate .pop_btn_pt button.l:active {
          background: #27af90; }
      .group_inviate .pop_btn_pt button.r {
        background: #f23440; }
        .group_inviate .pop_btn_pt button.r:active {
          background: #e0313c; }
      .group_inviate .pop_btn_pt button.wa {
        width: 100%; }

.share_site {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 99; }

.loading {
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 99;
  color: #fff; }

.search_page h3 {
  font-size: 16px;
  font-weight: 700;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -moz-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  height: 44px;
  line-height: 44px; }
  .search_page h3 .font {
    height: 44px;
    width: 44px;
    color: #666; }

.search_page .sh_his {
  background: #fff;
  padding: 0 12px 12px 12px;
  overflow: hidden;
  border-top: 1px solid #eee; }
  .search_page .sh_his .list li {
    padding: 8px 16px;
    color: #666;
    background: #f5f5f5;
    border-radius: 50px;
    float: left;
    margin-right: 10px;
    font-size: 12px;
    margin-bottom: 10px; }

.search_page .sh_his2 {
  background: #fff;
  padding: 0 12px 12px 12px;
  overflow: hidden;
  margin-top: 10px; }
  .search_page .sh_his2 .list li {
    padding: 8px 16px;
    color: #666;
    border-radius: 50px;
    float: left;
    margin-right: 10px;
    font-size: 12px;
    border: 1px solid #eee;
    margin-bottom: 10px; }
  .search_page .sh_his2 .list .sel {
    border: 1px solid #2bbf9d;
    color: #2bbf9d; }

.search_head {
  height: 50px;
  background: #fff;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 88; }
  .search_head .back {
    height: 50px;
    width: 50px;
    float: left; }
  .search_head .btn {
    width: 60px;
    height: 50px;
    float: right;
    color: #2bbf9d; }
  .search_head .put {
    width: calc(100vw - 120px);
    float: left;
    height: 34px;
    padding-left: 10px;
    background: #eee;
    margin-top: 8px;
    border-radius: 50px;
    overflow: hidden;
    position: relative; }
    .search_head .put .font {
      position: absolute;
      top: 50%;
      right: 10px;
      -webkit-transform: translate(0, -50%);
      transform: translate(0, -50%);
      z-index: 1;
      font-size: 14px;
      color: #666; }
    .search_head .put input {
      width: 100%;
      height: 100%; }

.my_invitation_list table {
  width: 100%; }
  .my_invitation_list table td {
    padding: 10px 0;
    text-align: center;
    color: #666; }
  .my_invitation_list table thead td {
    font-weight: 700;
    color: #333; }

.login_pop {
  position: fixed;
  top: 0;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  z-index: 99;
  background: #fff;
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 15px;
  overflow: hidden; }
  .login_pop h3 {
    text-align: right; }
  .login_pop p {
    border-bottom: 1px solid #eee;
    position: relative;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -moz-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between; }
    .login_pop p em {
      font-size: 12px; }
    .login_pop p .yzm {
      height: 30px;
      padding: 0 12px;
      border-radius: 2px;
      background: #666;
      color: #fff;
      position: absolute;
      z-index: 1;
      top: 50%;
      right: 0;
      -webkit-transform: translate(0, -50%);
      transform: translate(0, -50%);
      font-size: 12px; }
    .login_pop p .sel {
      background: #2bbf9d; }
  .login_pop input {
    height: 44px;
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    margin-left: 10px; }
  .login_pop .btn {
    padding: 40px 0 10px; }
    .login_pop .btn button {
      height: 40px;
      background: #2bbf9d;
      color: #fff;
      border-radius: 4px;
      width: 100%; }
      .login_pop .btn button:active {
        background: #27af90; }
  .login_pop .log {
    color: #2bbf9d;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -moz-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between; }
    .login_pop .log button {
      padding: 3px 5px;
      border-radius: 2px; }
      .login_pop .log button:active {
        background: #f4f4f4; }
  .login_pop .reg {
    text-align: right;
    color: #2bbf9d; }
    .login_pop .reg button {
      padding: 3px 5px;
      border-radius: 2px; }
      .login_pop .reg button:active {
        background: #f4f4f4; }

.sann_share_m .swiper-slide {
  overflow: hidden;
  width: 60%;
  border-radius: 8px;
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); }

.sann_share_m .swiper-slide-active {
  border: 2px solid #2bbf9d; }

.sann_share_m .swiper-container {
  padding-bottom: 30px; }

.sann_share_m .share_firend {
  text-align: center;
  padding: 20px 0; }
  .sann_share_m .share_firend button {
    height: 34px;
    padding: 0 15px;
    border-radius: 50px;
    background: #2bbf9d;
    color: #fff; }

.sann_share_m .head {
  background: #fff;
  overflow: hidden;
  padding: 20px 0;
  margin-bottom: 10px; }

.sann_share_m .pro_m {
  background: #fff;
  margin-bottom: 10px;
  padding: 12px; }
  .sann_share_m .pro_m .info {
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    overflow: hidden; }
    .sann_share_m .pro_m .info .down {
      padding: 10px; }
      .sann_share_m .pro_m .info .down .name {
        font-size: 14px;
        font-weight: 700;
        margin-bottom: 6px; }
      .sann_share_m .pro_m .info .down .txt {
        font-size: 12px;
        text-align: justify;
        color: #666; }

.oCanvas,
.bj_code {
  height: 0;
  width: 0;
  overflow: hidden; }

.rule_share {
  background: #fff;
  padding: 12px; }
  .rule_share .info {
    padding: 10px;
    background: #f2f2f2; }
    .rule_share .info .t {
      font-size: 15px;
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: justify;
      -moz-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      -webkit-box-align: center;
      -moz-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      font-weight: 700; }
      .rule_share .info .t button {
        font-size: 12px;
        color: #666; }
    .rule_share .info .list {
      margin-top: 10px; }
      .rule_share .info .list dt {
        font-size: 12px;
        color: #666;
        padding: 2px 0; }

.share_img_p {
  position: fixed;
  z-index: 110;
  background: #000;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0; }
  .share_img_p img {
    position: absolute;
    top: 50%;
    left: 0;
    -webkit-transform: translate(0, -50%);
    transform: translate(0, -50%);
    z-index: 1; }

.affrim_rst {
  text-align: center;
  padding: 40px 0; }
  .affrim_rst .success_box i,
  .affrim_rst .failure_box i {
    font-size: 60px; }
  .affrim_rst .success_box p,
  .affrim_rst .failure_box p {
    padding: 10px 0;
    color: #666; }
  .affrim_rst .success_box .btn,
  .affrim_rst .failure_box .btn {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-top: 30px; }
    .affrim_rst .success_box .btn button,
    .affrim_rst .failure_box .btn button {
      width: 100px;
      height: 30px;
      border-radius: 50px;
      color: #fff;
      font-size: 12px; }
    .affrim_rst .success_box .btn .see_eq,
    .affrim_rst .failure_box .btn .see_eq {
      background: #2bbf9d; }
    .affrim_rst .success_box .btn .see_od,
    .affrim_rst .failure_box .btn .see_od {
      background: #fecb89; }
  .affrim_rst .success_box i {
    color: #2bbf9d; }
  .affrim_rst .failure_box i {
    color: #fd1313; }

.liangpiao_order_list .head {
  position: relative; }
  .liangpiao_order_list .head .info {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    z-index: 1;
    color: #fff;
    text-align: center; }
    .liangpiao_order_list .head .info .num {
      font-size: 16px;
      margin-top: 10px; }

.liangpiao_order_list .search_lp {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 10px 12px;
  background: #fff; }
  .liangpiao_order_list .search_lp p {
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    position: relative;
    margin-right: 10px; }
    .liangpiao_order_list .search_lp p .font {
      position: absolute;
      top: 50%;
      left: 10px;
      -webkit-transform: translate(0, -50%);
      transform: translate(0, -50%);
      color: #999; }
    .liangpiao_order_list .search_lp p input {
      background: #f8f8f8;
      height: 40px;
      width: 100%;
      text-indent: 40px;
      border-radius: 2px; }
  .liangpiao_order_list .search_lp button {
    height: 40px;
    color: #2bbf9d;
    padding: 0 15px; }
    .liangpiao_order_list .search_lp button:active {
      background: #f4f4f4; }

.liangpiao_order_list .list {
  width: calc(100% - 24px);
  margin: 10px auto 10px; }
  .liangpiao_order_list .list li {
    border-radius: 5px;
    margin-top: 12px;
    background: #fff;
    padding: 0 12px;
    -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    text-align: center; }
    .liangpiao_order_list .list li .txt {
      width: 100%;
      border-bottom: 1px solid #eee;
      overflow: hidden;
      padding: 15px 0;
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -moz-box-align: center;
      -ms-flex-align: center;
      align-items: center; }
      .liangpiao_order_list .list li .txt dt {
        -webkit-box-flex: 1;
        -moz-box-flex: 1;
        -ms-flex: 1;
        flex: 1; }
        .liangpiao_order_list .list li .txt dt h4 {
          font-size: 13px;
          margin-bottom: 5px;
          color: #2e2e2e; }
        .liangpiao_order_list .list li .txt dt p {
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          color: #959595;
          font-size: 11px;
          word-break: break-all; }
      .liangpiao_order_list .list li .txt dt:nth-of-type(1) {
        float: left; }
      .liangpiao_order_list .list li .txt dt:nth-of-type(2) {
        float: right; }
    .liangpiao_order_list .list li table {
      width: 100%;
      border-top: 1px solid #eee;
      border-bottom: 1px solid #eee;
      font-size: 12px; }
      .liangpiao_order_list .list li table td {
        padding: 5px 0; }
      .liangpiao_order_list .list li table tbody td {
        color: #999; }
    .liangpiao_order_list .list li .name {
      text-align: left;
      padding: 10px 0;
      font-size: 12px; }

.show_img_suyuan {
  position: fixed;
  width: 100%;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  z-index: 99; }

.quk_pop_list {
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  z-index: 99;
  background: #fff; }
  .quk_pop_list li {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -moz-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 15px 12px;
    position: relative; }
    .quk_pop_list li p {
      color: #999; }
    .quk_pop_list li:active {
      background: #f4f4f4; }

.rule li {
  margin-bottom: 10px;
  padding: 10px 12px;
  background: #fff; }
  .rule li h5 {
    font-size: 14px;
    font-weight: 700;
    color: #2bbf9d; }
  .rule li p {
    margin-top: 3px;
    color: #666;
    text-align: justify;
    font-size: 13px; }

.gzh_wx .list {
  padding: 0 12px; }
  .gzh_wx .list li {
    background: #fff;
    border-radius: 8px;
    -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    margin-bottom: 15px;
    overflow: hidden;
    position: relative;
    z-index: 1;
    text-align: center; }
    .gzh_wx .list li .pic {
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      padding: 15px; }
    .gzh_wx .list li .txt {
      padding: 0px 0 15px; }
    .gzh_wx .list li .btn {
      padding: 0px 0 15px; }
      .gzh_wx .list li .btn button {
        background: #2bbf9d;
        height: 40px;
        color: #fff;
        padding: 0 20px;
        border-radius: 40px; }
        .gzh_wx .list li .btn button:active {
          background: #27af90; }
  .gzh_wx .list li:nth-of-type(1) {
    margin-top: -50px; }

.labs_record {
  text-align: center;
  border-top: 1px solid #eee;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  background: #fff;
  margin-bottom: 10px; }
  .labs_record button {
    height: 44px;
    width: 50%;
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    font-size: 16px; }
    .labs_record button:active {
      background: #f4f4f4; }
  .labs_record .sel {
    color: #2bbf9d;
    font-weight: 700; }

.sq__tixian {
  background: #fff; }
  .sq__tixian .list {
    padding: 12px; }
    .sq__tixian .list li {
      background: #fff;
      -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      padding: 20px;
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -moz-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      position: relative;
      margin-bottom: 12px;
      border-radius: 4px; }
      .sq__tixian .list li .del {
        position: absolute;
        z-index: 1;
        top: 3px;
        right: 5px;
        color: #999;
        padding: 2px 5px;
        border-radius: 2px; }
        .sq__tixian .list li .del:active {
          background: #f4f4f4; }
      .sq__tixian .list li.ac:active {
        background: #f4f4f4; }
      .sq__tixian .list li svg {
        height: 50px;
        width: 50px; }
      .sq__tixian .list li .info {
        margin-left: 10px; }
        .sq__tixian .list li .info .name {
          font-size: 14px;
          font-weight: 700;
          margin-bottom: 3px; }
        .sq__tixian .list li .info p {
          font-size: 14px;
          color: #666; }
      .sq__tixian .list li span.font {
        position: absolute;
        z-index: 1;
        top: 50%;
        right: 12px;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        color: #999; }
        .sq__tixian .list li span.font.sel {
          color: #2bbf9d; }

.stq_create_order {
  padding: 12px; }
  .stq_create_order .module {
    background: #fff;
    border-radius: 5px;
    margin-bottom: 12px;
    -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1); }
  .stq_create_order .add_new_style {
    text-align: center;
    padding: 15px 0; }
    .stq_create_order .add_new_style:active {
      background: #f4f4f4; }
  .stq_create_order .address_sel {
    padding: 20px 12px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -moz-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between; }
    .stq_create_order .address_sel .info {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: justify;
      -moz-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between; }
      .stq_create_order .address_sel .info i.font {
        color: #2bbf9d; }
      .stq_create_order .address_sel .info .data {
        padding: 0 10px; }
        .stq_create_order .address_sel .info .data h3 {
          font-size: 16px;
          margin-bottom: 5px;
          color: #333; }
        .stq_create_order .address_sel .info .data p {
          font-size: 12px;
          color: #666; }
    .stq_create_order .address_sel b {
      color: #999; }
    .stq_create_order .address_sel .add {
      text-align: center;
      width: 100%; }
  .stq_create_order .address_sel:active {
    background: #eee; }
  .stq_create_order .product_info {
    padding: 20px 12px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -moz-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between; }
    .stq_create_order .product_info .oImg, .stq_create_order .product_info .trial_invite .info .ct .list li .user .font, .trial_invite .info .ct .list li .user .stq_create_order .product_info .font {
      width: 29%;
      margin-right: 10px; }
    .stq_create_order .product_info .info {
      -webkit-box-flex: 1;
      -moz-box-flex: 1;
      -ms-flex: 1;
      flex: 1; }
      .stq_create_order .product_info .info h3 {
        font-size: 14px;
        font-weight: 700; }
      .stq_create_order .product_info .info .timer {
        font-size: 12px;
        margin: 5px 0; }
      .stq_create_order .product_info .info .data {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
        -moz-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        -webkit-box-align: center;
        -moz-box-align: center;
        -ms-flex-align: center;
        align-items: center; }
        .stq_create_order .product_info .info .data .nub .price {
          color: #e61a23; }
        .stq_create_order .product_info .info .data .nub .hz {
          color: #2bbf9d;
          font-size: 12px; }
          .stq_create_order .product_info .info .data .nub .hz .font {
            font-size: 12px; }
        .stq_create_order .product_info .info .data .btn {
          display: -webkit-box;
          display: -moz-box;
          display: -ms-flexbox;
          display: flex; }
          .stq_create_order .product_info .info .data .btn button, .stq_create_order .product_info .info .data .btn em {
            -webkit-box-pack: justify;
            -moz-box-pack: justify;
            -ms-flex-pack: justify;
            justify-content: space-between;
            -webkit-box-align: center;
            -moz-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            padding: 7px 11px;
            font-size: 14px;
            -webkit-box-flex: 1;
            -moz-box-flex: 1;
            -ms-flex: 1;
            flex: 1; }
          .stq_create_order .product_info .info .data .btn button {
            border: 1px solid #eee; }
          .stq_create_order .product_info .info .data .btn em {
            padding: 7px 15px;
            border-top: 1px solid #eee;
            border-bottom: 1px solid #eee; }
  .stq_create_order .fu_x dt {
    padding: 15px 12px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -moz-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center; }
    .stq_create_order .fu_x dt .font {
      color: #666;
      font-size: 20px;
      vertical-align: middle; }
    .stq_create_order .fu_x dt .sel {
      color: #2bbf9d; }
  .stq_create_order .fu_x dt.active:active {
    background: #eee; }
  .stq_create_order .fu_x .no_hz {
    border-top: 1px solid #eee; }
  .stq_create_order .play_wx {
    text-align: center;
    padding-bottom: 20px; }
    .stq_create_order .play_wx h3 {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: justify;
      -moz-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      -webkit-box-align: center;
      -moz-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      height: 44px;
      line-height: 44px;
      padding: 0 12px;
      border-bottom: 1px solid #eee; }
      .stq_create_order .play_wx h3 .font {
        color: #2bbf9d;
        font-size: 22px;
        vertical-align: middle; }
    .stq_create_order .play_wx .price {
      color: #e31b1b;
      font-size: 20px;
      font-weight: 700;
      margin: 30px 0 0; }
    .stq_create_order .play_wx .btn {
      padding: 10px 0; }
      .stq_create_order .play_wx .btn button {
        min-width: 50%;
        background: #2bbf9d;
        color: #fff;
        border-radius: 50px;
        height: 40px; }

.stq_pdt .nav_pop_btn {
  color: #fff;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 66;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex; }
  .stq_pdt .nav_pop_btn button {
    height: 44px;
    -webkit-box-flex: 1;
    -moz-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    width: 50%; }
    .stq_pdt .nav_pop_btn button.wa {
      width: 100%; }
  .stq_pdt .nav_pop_btn .l {
    background: #fecb89; }
  .stq_pdt .nav_pop_btn .r {
    background: #2bbf9d; }

.stq_pdt .info {
  padding: 12px; }
  .stq_pdt .info .name {
    font-weight: 700; }
  .stq_pdt .info .no_t {
    font-size: 12px;
    color: #666;
    margin: 3px 0; }
  .stq_pdt .info .timer {
    font-size: 12px; }
  .stq_pdt .info .price_info {
    padding-top: 10px; }
    .stq_pdt .info .price_info dt {
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: justify;
      -moz-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      font-size: 12px; }
      .stq_pdt .info .price_info dt .price {
        font-size: 15px;
        color: #e31b1b; }
      .stq_pdt .info .price_info dt .hz {
        color: #2bbf9d;
        font-size: 12px; }
      .stq_pdt .info .price_info dt .cost .c1 {
        color: #2bbf9d; }
      .stq_pdt .info .price_info dt .cost .c2 {
        color: #fad493; }
      .stq_pdt .info .price_info dt .cost b {
        font-size: 12px;
        color: #666; }

.down_load {
  position: relative;
  text-align: center;
  height: 100vh;
  overflow: hidden; }
  .down_load .timer_logo {
    position: absolute;
    top: 100px;
    width: 100%;
    text-align: center; }
    .down_load .timer_logo img {
      width: 90px;
      height: 90px;
      border-radius: 5px;
      margin: 0 auto; }
    .down_load .timer_logo p {
      font-size: 18px;
      color: #fff;
      margin-top: 10px; }
    .down_load .timer_logo .timer_djs_txt {
      font-size: 16px;
      white-space: nowrap;
      color: #fff;
      margin-top: 15px; }
  .down_load .btn_box {
    position: absolute;
    bottom: 10%;
    left: 50%;
    -webkit-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
    z-index: 1;
    text-align: center;
    color: #fff; }
    .down_load .btn_box .btn {
      width: 180px;
      margin: 0 auto 20px; }
      .down_load .btn_box .btn button {
        width: 100%; }
    .down_load .btn_box .txt {
      margin-top: 10px;
      white-space: nowrap;
      padding: 5px 15px; }
  .down_load .timer {
    position: absolute;
    width: 100%;
    left: 50%;
    bottom: 80px;
    z-index: 101;
    -webkit-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
    color: #fff; }
    .down_load .timer .name {
      white-space: nowrap;
      font-size: 18px;
      margin-bottom: 10px; }
    .down_load .timer .txt {
      font-size: 20px;
      margin-bottom: 10px;
      white-space: nowrap; }
    .down_load .timer .time {
      font-size: 24px;
      height: 90px;
      overflow: hidden; }
      .down_load .timer .time .m {
        -webkit-transform: translate(0, 0);
        transform: translate(0, 0);
        -webkit-transition: 1s;
        transition: 1s; }
        .down_load .timer .time .m li {
          height: 30px;
          line-height: 30px;
          -webkit-transition: 1s;
          transition: 1s; }
        .down_load .timer .time .m li.o_01 {
          font-size: 14px;
          opacity: 0.2; }
        .down_load .timer .time .m li.o_02 {
          font-size: 18px;
          opacity: 0.7; }
        .down_load .timer .time .m li.o_04 {
          font-size: 18px;
          opacity: 0.5; }

.down_load_pop {
  position: fixed;
  top: 0;
  width: 100%;
  left: 0;
  z-index: 101; }

.iso_shuoming {
  text-decoration: underline;
  padding: 10px; }

.index_three_modify {
  overflow: hidden;
  background: url(../images/index_three_modify.png) no-repeat;
  background-size: 100% auto; }
  .index_three_modify .header {
    height: 50px;
    position: fixed;
    top: 0;
    width: 100%;
    left: 0;
    z-index: 88;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding: 7px 12px;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background: none;
    -webkit-transition: 0.5s;
    transition: 0.5s;
    background: rgba(255, 255, 255, 0); }
    .index_three_modify .header h3, .index_three_modify .header .search, .index_three_modify .header .buy_car {
      line-height: 36px; }
    .index_three_modify .header h3 {
      font-size: 18px;
      font-weight: 700; }
    .index_three_modify .header .search {
      height: 36px;
      -webkit-box-flex: 1;
      -moz-box-flex: 1;
      -ms-flex: 1;
      flex: 1;
      background: #f4f4f4;
      border-radius: 4px;
      margin: 0 10px;
      position: relative; }
      .index_three_modify .header .search input {
        width: 100%;
        height: 100%;
        text-indent: 32px;
        padding-top: 1px; }
      .index_three_modify .header .search em {
        color: #999;
        margin: 0 5px;
        position: absolute;
        top: 50%;
        left: 5px;
        -webkit-transform: translate(0, -50%);
        transform: translate(0, -50%);
        z-index: 1; }
    .index_three_modify .header .buy_car {
      width: 34px;
      height: 34px;
      background: -webkit-gradient(linear, left top, left bottom, from(#2ebfa0), to(#28bcc8));
      background: linear-gradient(#2ebfa0, #28bcc8);
      border-radius: 50%;
      position: relative;
      color: #fff;
      font-size: 18px; }
      .index_three_modify .header .buy_car:active {
        background: #27af90; }
      .index_three_modify .header .buy_car em {
        position: absolute;
        top: -5px;
        right: -8px;
        z-index: 1;
        font-size: 10px;
        display: block;
        background: #e5383e;
        height: 14px;
        line-height: 14px;
        padding: 0 5px;
        border-radius: 10px; }
  .index_three_modify .swiper_banner {
    margin-top: 50px; }
  .index_three_modify .content {
    padding: 0 12px;
    overflow: hidden; }
    .index_three_modify .content .name_tit {
      -webkit-box-pack: justify;
      -moz-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -moz-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      padding: 10px 12px;
      border-bottom: 1px solid #ededeb; }
      .index_three_modify .content .name_tit .l {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -moz-box-align: center;
        -ms-flex-align: center;
        align-items: center; }
        .index_three_modify .content .name_tit .l h3 {
          font-size: 16px;
          font-weight: 700; }
        .index_three_modify .content .name_tit .l em {
          font-size: 13px;
          color: #999;
          margin-left: 5px; }
      .index_three_modify .content .name_tit .r {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -moz-box-align: center;
        -ms-flex-align: center;
        align-items: center; }
        .index_three_modify .content .name_tit .r p {
          background: #e3e2e2;
          height: 16px;
          width: 90px;
          border-radius: 10px;
          overflow: hidden;
          position: relative;
          padding: 1px; }
          .index_three_modify .content .name_tit .r p b {
            position: absolute;
            left: 5px;
            top: 50%;
            -webkit-transform: translate(0, -50%);
            transform: translate(0, -50%);
            z-index: 1;
            font-size: 11px;
            color: #fff; }
          .index_three_modify .content .name_tit .r p em {
            display: block;
            background: #2bbf9d;
            height: 100%;
            border-radius: 10px;
            -webkit-transition: 8s;
            transition: 8s;
            position: relative;
            overflow: hidden; }
            .index_three_modify .content .name_tit .r p em .fill {
              width: 20px;
              height: 16px;
              display: block;
              -webkit-filter: blur(5px);
              filter: blur(5px);
              background: #fff;
              position: absolute;
              opacity: 0.6;
              top: 0px;
              left: 0;
              -webkit-animation: mymove 1s ease infinite;
              animation: mymove 1s ease infinite; }

@-webkit-keyframes mymove {
  from {
    left: 0px;
    background: #fff; }
  to {
    left: 50%;
    background: #e5ff0a; }
  to {
    left: 100%;
    background: #83ff87; } }

@keyframes mymove {
  from {
    left: 0px;
    background: #fff; }
  to {
    left: 50%;
    background: #e5ff0a; }
  to {
    left: 100%;
    background: #83ff87; } }
        .index_three_modify .content .name_tit .r button.font {
          color: #2bbf9d;
          margin-left: 10px; }
    .index_three_modify .content .titN {
      -webkit-box-pack: justify;
      -moz-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      display: -webkit-box;
      display: -moz-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -moz-box-align: center;
      -ms-flex-align: center;
      align-items: center; }
      .index_three_modify .content .titN h3 {
        font-size: 16px;
        font-weight: 700; }
      .index_three_modify .content .titN button {
        font-size: 12px; }
    .index_three_modify .content .zlzq {
      background: #f9f9f9;
      border-radius: 8px;
      margin-top: 10px; }
      .index_three_modify .content .zlzq .list:after,
      .index_three_modify .content .zlzq .list:before {
        content: ""; }
      .index_three_modify .content .zlzq .list {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -moz-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: justify;
        -moz-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        padding: 12px 0; }
        .index_three_modify .content .zlzq .list li {
          width: calc((100% - 40px)/3);
          text-align: center;
          color: #f82329; }
          .index_three_modify .content .zlzq .list li .oImg, .index_three_modify .content .zlzq .trial_invite .info .ct .list li .user .font, .trial_invite .info .ct .index_three_modify .content .zlzq .list li .user .font {
            border-radius: 5px;
            overflow: hidden; }
          .index_three_modify .content .zlzq .list li .price {
            font-weight: 700;
            margin: 6px 0;
            font-size: 15px; }
          .index_three_modify .content .zlzq .list li .ico em {
            display: inline-block;
            background: #f82329;
            color: #fff;
            border-radius: 20px;
            font-size: 12px;
            padding: 2px 8px; }
          .index_three_modify .content .zlzq .list li .ico.sel em {
            background: #2bbf9d; }
    .index_three_modify .content .adv {
      overflow: hidden;
      border-radius: 5px;
      margin: 12px 0; }
    .index_three_modify .content .hot_pro {
      overflow: hidden; }
      .index_three_modify .content .hot_pro .list {
        overflow-x: scroll;
        overflow-y: hidden;
        white-space: nowrap;
        margin-top: 10px;
        -webkit-overflow-scrolling: touch; }
        .index_three_modify .content .hot_pro .list li {
          display: inline-block;
          margin-right: 10px;
          width: 27vw; }
          .index_three_modify .content .hot_pro .list li em {
            display: block;
            width: 100%;
            height: 27vw;
            overflow: hidden;
            border-radius: 4px; }
          .index_three_modify .content .hot_pro .list li h3 {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            font-size: 14px;
            font-weight: 700; }
          .index_three_modify .content .hot_pro .list li p {
            color: #999;
            font-size: 12px; }
    .index_three_modify .content .three_ys {
      background: #f9f9f9;
      margin-top: 15px;
      border-radius: 8px; }
      .index_three_modify .content .three_ys .titN {
        padding: 10px 10px 0 10px;
        color: #2bbf9d; }
        .index_three_modify .content .three_ys .titN em {
          color: #999;
          font-size: 14px;
          font-weight: 700; }
      .index_three_modify .content .three_ys .list {
        overflow: hidden;
        padding: 10px;
        -webkit-overflow-scrolling: touch; }
        .index_three_modify .content .three_ys .list li {
          float: left;
          width: calc(50% - 5px);
          margin-right: 10px;
          -webkit-box-sizing: border-box;
          -moz-box-sizing: border-box;
          box-sizing: border-box;
          background: #fff;
          border-radius: 5px;
          padding: 10px;
          text-align: right;
          position: relative;
          margin-bottom: 10px; }
          .index_three_modify .content .three_ys .list li .name,
          .index_three_modify .content .three_ys .list li .cd,
          .index_three_modify .content .three_ys .list li .price {
            position: absolute;
            left: 10px;
            width: calc(100% - 90px);
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            text-align: left; }
          .index_three_modify .content .three_ys .list li .name {
            top: 10px;
            font-weight: 700; }
          .index_three_modify .content .three_ys .list li .cd {
            top: 50%;
            -webkit-transform: translate(0, -50%);
            transform: translate(0, -50%);
            font-size: 11px;
            color: #999;
            font-weight: 700; }
          .index_three_modify .content .three_ys .list li .price {
            bottom: 10px;
            color: #f82329;
            font-weight: 700; }
          .index_three_modify .content .three_ys .list li em {
            display: inline-block;
            width: 60px;
            height: 60px;
            border-radius: 5px;
            overflow: hidden; }
          .index_three_modify .content .three_ys .list li:nth-of-type(2n) {
            margin-right: 0; }
    .index_three_modify .content .rqtj {
      background: #f9f9f9;
      margin-top: 15px;
      border-radius: 8px;
      padding: 10px; }
      .index_three_modify .content .rqtj .ico b {
        border: 1px solid #f82329;
        color: #f82329;
        display: inline-block;
        font-size: 12px;
        border-radius: 50px;
        padding: 2px 10px;
        background: #fff; }
      .index_three_modify .content .rqtj .zt {
        background: #fff;
        border-radius: 5px;
        padding: 10px;
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        margin: 10px 0; }
        .index_three_modify .content .rqtj .zt .info {
          -webkit-box-flex: 1;
          -moz-box-flex: 1;
          -ms-flex: 1;
          flex: 1;
          margin-left: 10px;
          width: calc(100% - 32vw - 10px); }
          .index_three_modify .content .rqtj .zt .info .name {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-weight: 700; }
          .index_three_modify .content .rqtj .zt .info p {
            font-size: 12px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            color: #999;
            margin: 8px 0; }
          .index_three_modify .content .rqtj .zt .info .price {
            margin-top: 8px;
            font-size: 15px;
            color: #f82329;
            font-weight: 700; }
            .index_three_modify .content .rqtj .zt .info .price s {
              color: #999;
              font-weight: 400;
              margin-left: 10px;
              font-size: 12px; }
        .index_three_modify .content .rqtj .zt em {
          width: 32vw;
          height: 32vw;
          overflow: hidden;
          border-radius: 4px;
          display: block; }
      .index_three_modify .content .rqtj .list {
        overflow-x: scroll;
        overflow-y: hidden;
        white-space: nowrap;
        margin-top: 10px;
        -webkit-overflow-scrolling: touch; }
        .index_three_modify .content .rqtj .list li {
          display: inline-block;
          margin-right: 10px;
          width: 32vw; }
          .index_three_modify .content .rqtj .list li > em {
            display: block;
            position: relative;
            height: 32vw;
            border-radius: 5px;
            overflow: hidden; }
            .index_three_modify .content .rqtj .list li > em .ico {
              position: absolute;
              left: 50%;
              bottom: 10px;
              -webkit-transform: translate(-50%, 0);
              transform: translate(-50%, 0);
              z-index: 1; }
          .index_three_modify .content .rqtj .list li p {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            margin: 5px 0;
            color: #999; }
          .index_three_modify .content .rqtj .list li .price em {
            color: #f82329;
            font-weight: 700; }
          .index_three_modify .content .rqtj .list li .price s {
            color: #999;
            margin-left: 5px;
            font-size: 12px; }
    .index_three_modify .content .csxh {
      margin-top: 15px; }
      .index_three_modify .content .csxh .list {
        margin-top: 10px; }
        .index_three_modify .content .csxh .list li {
          width: calc(50vw - 18px);
          float: left;
          margin-right: 12px;
          margin-bottom: 12px; }
          .index_three_modify .content .csxh .list li h3 {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-weight: 700;
            font-size: 14px;
            margin: 5px 0 0; }
          .index_three_modify .content .csxh .list li:nth-of-type(2n) {
            margin-right: 0;
            margin-bottom: 10px; }
          .index_three_modify .content .csxh .list li em {
            border-radius: 5px;
            display: block;
            height: calc(50vw - 18px);
            position: relative;
            -webkit-box-sizing: border-box;
            -moz-box-sizing: border-box;
            box-sizing: border-box;
            padding: 2px; }
            .index_three_modify .content .csxh .list li em b {
              overflow: hidden;
              display: block;
              width: 100%;
              border-radius: 5px;
              height: calc(50vw - 22px); }
            .index_three_modify .content .csxh .list li em:after {
              width: calc((50vw - 18px)*2);
              height: calc((50vw - 18px)*2);
              border-radius: 10px;
              content: "";
              -webkit-box-sizing: border-box;
              -moz-box-sizing: border-box;
              box-sizing: border-box;
              border: 1px solid #eee;
              position: absolute;
              top: 0;
              left: 0;
              z-index: 1;
              display: block;
              -webkit-transform: scale(0.5, 0.5) translate(-50%, -50%);
              transform: scale(0.5, 0.5) translate(-50%, -50%); }
            .index_three_modify .content .csxh .list li em:active:after {
              border: 1px solid #2bbf9d; }
          .index_three_modify .content .csxh .list li .price b {
            color: #f82329;
            font-weight: 700; }
          .index_three_modify .content .csxh .list li .price s {
            color: #999;
            margin-left: 5px;
            font-size: 12px; }
